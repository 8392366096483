import { useEffect, useRef, useCallback, useMemo, memo, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { Box, makeStyles, CircularProgress } from "@material-ui/core";
import { debounce } from "throttle-debounce";
import { deepEqual } from "fast-equals";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useSetAtom, useAtomValue } from "jotai";
import { selectAtom } from "jotai/utils";
import { FormProvider, useForm } from "react-hook-form";
// NOTE 工具
import useSearchParams from "../../hooks/useSearchParams";
import { competitionsStatusAtom } from "../../jotai/Competitions";
// NOTE 組件
import withJotai from "../../jotai/withJotai";
import { useAlert } from "../../component/Alert";
import Contestants from "./Contestants/index";
import Spectator from "./Spectator/index";
// NOTE zustand
import useCompetitionsStore from "../../zustand/useCompetitionsStore";
// css
import "./index.css";

// SECTION apollo
// NOTE 確認對戰房密碼
/** - 確認對戰房密碼 */
const CHECK_BATTLE_ROOM_PASSWORD = gql`
  mutation checkBattleRoomPassword($id: Int!, $password: String!) {
    checkBattleRoomPassword(id: $id, password: $password) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;
// NOTE 對戰房
/** - 對戰房 */
const GET_BATTLE_ROOM = gql`
  query battleRoom($id: Int!) {
    battleRoom(id: $id) {
      # "ID"
      id
      # "參賽者A"
      participantA {
        # ID
        id
        # "大頭貼"
        profilePicture {
          # "位置"
          location
        }
        # "姓名"
        fullName
        # "手機號碼"
        mobile
      }
      # "參賽者B"
      participantB {
        # ID
        id
        # "大頭貼"
        profilePicture {
          # "位置"
          location
        }
        # "姓名"
        fullName
        # "手機號碼"
        mobile
      }
      # "參賽者A卡牌1"
      cardA1
      # "參賽者A卡牌2"
      cardA2
      # "參賽者B卡牌1"
      cardB1
      # "參賽者B卡牌2"
      cardB2
      # "結果"
      result
      # "結果影片網址"
      resultVideoUrl
    }
  }
`;
// NOTE 對戰素材
/** - 對戰素材 */
const GET_BATTLE_ASSETS = gql`
  query battleAssets($types: [BattleAssetType!]) {
    battleAssets(types: $types) {
      # "ID"
      id
      # "類型"
      type
      # "卡片類型"
      cardType
      # "網址"
      url
    }
  }
`;
// !SECTION

/** - 對戰系統頁面 */
export default function CompetitionsPage() {
  return (
    <>
      <Helmet>
        <title>對戰系統｜路遙圓創</title>
      </Helmet>
      <CompetitionsLayout />
    </>
  );
}

/** - 對戰系統外框 */
function CompetitionsLayout() {
  const useStyles = makeStyles({
    outside: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
    },
    bg: {
      height: "100vh",
      width: "100%",
      backgroundColor: "rgba(0,0,0)",
      overflowY: "hidden",
    },
  });
  const classes = useStyles();
  const { searchParamsObject, createQueryString } = useSearchParams();
  const searchParamsObjectRef = useRef();
  const writePassword = useCompetitionsStore(
    useCallback((state) => state.writePassword, [])
  );
  useEffect(() => {
    if (!deepEqual(searchParamsObjectRef.current, searchParamsObject)) {
      searchParamsObjectRef.current = searchParamsObject;
      if (searchParamsObject.role && searchParamsObject.password) {
        writePassword(searchParamsObject.password);
        createQueryString({ role: searchParamsObject.role, password: "" });
      }
    }
  }, [searchParamsObject, writePassword, createQueryString]);
  return (
    <Box className={`${classes.outside} game-area`}>
      <Box className={classes.bg}>
        <CompetitionsContent />
      </Box>
    </Box>
  );
}

/** - */
const CompetitionsContent = memo(
  withJotai(function CompetitionsContent() {
    const { roomId } = useParams();
    const { searchParamsObject } = useSearchParams();
    const { role } = searchParamsObject;
    const { notice } = useAlert();
    const password = useCompetitionsStore(
      useCallback((state) => state.password, [])
    );
    const { step } = useAtomValue(
      selectAtom(
        competitionsStatusAtom,
        useCallback(
          (state) => ({
            step: state.step,
          }),
          []
        ),
        deepEqual
      )
    );
    const setCompetitionsStatus = useSetAtom(competitionsStatusAtom);
    const [allowedStart, setAllowedStart] = useState(false);
    const battleAssetsRef = useRef();
    const cacheRef = useRef({
      notice: () => {},
    });
    const form = useForm({
      defaultValues: {
        roomId: undefined,
        role: "",
        participantA: undefined,
        participantB: undefined,
        cardA1: undefined,
        cardA2: undefined,
        cardB1: undefined,
        cardB2: undefined,
        result: "",
        resultVideoUrl: "",
      },
    });
    const { reset, setValue } = form;
    useEffect(() => {
      reset({
        roomId,
        role,
        participantA: undefined,
        participantB: undefined,
        cardA1: undefined,
        cardA2: undefined,
        cardB1: undefined,
        cardB2: undefined,
        result: "",
        resultVideoUrl: "",
      });
    }, [roomId, role, reset]);

    useEffect(() => {
      cacheRef.current.notice = notice;
    }, [notice]);

    const [getBattleRoom, { startPolling, stopPolling }] = useLazyQuery(
      GET_BATTLE_ROOM,
      {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted({ battleRoom }) {
          if (battleRoom) {
            switch (role) {
              case "A":
                if (!battleRoom.participantA) {
                  setCompetitionsStatus((v) => {
                    v.step = undefined;
                    v.locked = false;
                    reset();
                  });
                }
                return;
              case "B":
                if (!battleRoom.participantB) {
                  setCompetitionsStatus((v) => {
                    v.step = undefined;
                    v.locked = false;
                    reset();
                  });
                }
                return;
              case "SPECTATOR":
                if (battleRoom.participantA || battleRoom.participantB) {
                  setValue("participantA", battleRoom.participantA);
                  setValue("participantB", battleRoom.participantB);
                  setCompetitionsStatus((v) => {
                    v.homeScreenStep = "START_BATTLE";
                    if (!battleRoom.participantA || !battleRoom.participantB) {
                      v.allowVideoPlay = false;
                      setValue("result", "");
                      setValue("resultVideoUrl", "");
                    }
                  });
                  if (
                    battleRoom.cardA1 &&
                    battleRoom.cardA2 &&
                    battleRoom.cardB1 &&
                    battleRoom.cardB2 &&
                    battleRoom.result &&
                    battleRoom.resultVideoUrl
                  ) {
                    setCompetitionsStatus((v) => {
                      const cardA1 = v.battleAssets.find(
                        (item) => item.cardType === battleRoom.cardA1
                      );
                      const cardA2 = v.battleAssets.find(
                        (item) => item.cardType === battleRoom.cardA2
                      );
                      const cardB1 = v.battleAssets.find(
                        (item) => item.cardType === battleRoom.cardB1
                      );
                      const cardB2 = v.battleAssets.find(
                        (item) => item.cardType === battleRoom.cardB2
                      );
                      setValue("cardA1", cardA1);
                      setValue("cardA2", cardA2);
                      setValue("cardB1", cardB1);
                      setValue("cardB2", cardB2);
                      setValue("result", battleRoom.result);
                      setValue("resultVideoUrl", battleRoom.resultVideoUrl);
                    });
                  }
                  setTimeout(() => {
                    setAllowedStart(true);
                  }, 0);
                  return;
                }
                reset();
                setCompetitionsStatus((v) => {
                  v.homeScreenStep = undefined;
                  v.allowVideoPlay = false;
                });
                setTimeout(() => {
                  setAllowedStart(true);
                }, 0);
                return;
              default:
                return;
            }
          }
        },
        onError(error) {
          cacheRef.current.notice(error.message.replace("GraphQL error: ", ""));
          return null;
        },
      }
    );
    useEffect(() => {
      if (step === "START_BATTLE") {
        getBattleRoom({ variables: { id: Number(roomId) } });
        startPolling(1000);
      }
      return () => {
        stopPolling();
      };
    }, [roomId, step, getBattleRoom, startPolling, stopPolling]);
    useEffect(() => {
      return () => {
        stopPolling();
      };
    }, [stopPolling]);

    const [
      getBattleAssets,
      { data: battleAssetsData, loading: battleAssetsLoading },
    ] = useLazyQuery(GET_BATTLE_ASSETS, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ battleAssets }) {
        if (battleAssets) {
          if (role === "SPECTATOR") {
            getBattleRoom({ variables: { id: Number(roomId) } });
            startPolling(1000);
            return;
          }
          setTimeout(() => {
            setAllowedStart(true);
          }, 0);
        }
      },
      onError(error) {
        cacheRef.current.notice(error.message.replace("GraphQL error: ", ""));
        return null;
      },
    });

    const [
      checkBattleRoomPasswordFn,
      { loading: checkBattleRoomPasswordLoading },
    ] = useMutation(CHECK_BATTLE_ROOM_PASSWORD, {
      onCompleted({ checkBattleRoomPassword: { success, message } }) {
        if (success) {
          getBattleAssets({
            variables: {
              types: [
                "OPENING_ANIMATION",
                "CARDBACK",
                "CARDFRONT",
                "BACKGROUND",
              ],
            },
          });
        } else if (message) {
          cacheRef.current.notice(message);
        }
      },
      onError(error) {
        cacheRef.current.notice(error.message.replace("GraphQL error: ", ""));
        return null;
      },
    });
    /** - 延遲Query */
    const debounceOnChange = useMemo(
      () =>
        debounce(
          1000,
          async ({ roomId: currentRoomId, password: currentPassword }) => {
            if (!currentPassword) {
              cacheRef.current.notice("密碼錯誤，無法進入對戰房。");
              return;
            }
            checkBattleRoomPasswordFn({
              variables: {
                id: Number(currentRoomId),
                password: currentPassword,
              },
            });
          }
        ),
      [checkBattleRoomPasswordFn]
    );
    useEffect(() => {
      debounceOnChange({ roomId, password });
    }, [roomId, password, debounceOnChange]);

    const battleAssets = useMemo(
      () => battleAssetsData?.battleAssets,
      [battleAssetsData]
    );
    useEffect(() => {
      if (
        Array.isArray(battleAssets) &&
        !deepEqual(battleAssetsRef.current, battleAssets)
      ) {
        setCompetitionsStatus((v) => {
          v.battleAssets = battleAssets;
        });
      }
    }, [setCompetitionsStatus, battleAssets]);
    const _renderGame = useMemo(() => {
      if (
        roomId &&
        role &&
        Array.isArray(battleAssets) &&
        battleAssets.length === 11 &&
        allowedStart
      ) {
        switch (role) {
          case "A":
          case "B":
            return <Contestants />;
          case "SPECTATOR":
            return <Spectator />;
          default:
            return null;
        }
      }
      return null;
    }, [roomId, role, battleAssets, allowedStart]);

    if (checkBattleRoomPasswordLoading || battleAssetsLoading) {
      return (
        <Box
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="secondary" />
        </Box>
      );
    }
    return <FormProvider {...form}>{_renderGame}</FormProvider>;
  })
);
