import React, { useCallback, useMemo } from "react";
import { Box } from "@material-ui/core";
import { useAtomValue } from "jotai";
import { selectAtom } from "jotai/utils";
import { deepEqual } from "fast-equals";
// NOTE 工具
import { competitionsStatusAtom } from "../../../jotai/Competitions";
// NOTE 組件
import GamePreparation from "./GamePreparation";
import StartBattle from "./StartBattle";

/** - 觀戰者 */
export default function Spectator() {
  const { homeScreenStep } = useAtomValue(
    selectAtom(
      competitionsStatusAtom,
      useCallback(
        (state) => ({
          homeScreenStep: state.homeScreenStep,
        }),
        []
      ),
      deepEqual
    )
  );
  const _renderStep = useMemo(() => {
    switch (homeScreenStep) {
      case "START_BATTLE":
        return <StartBattle />;
      default:
        return <GamePreparation />;
    }
  }, [homeScreenStep]);
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          maxWidth: "100%",
          aspectRatio: 2436 / 4096,
        }}
      >
        {_renderStep}
      </Box>
    </Box>
  );
}
