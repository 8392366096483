import React, { memo, useState, Suspense } from "react";
import {
  Grid,
  Tabs,
  Tab,
  FormHelperText,
  Box,
  Divider,
  FormControl,
} from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import ReactPlayer from "react-player";
// NOTE 工具
import {
  battleCardEnum,
  firstBattleCardList,
  secondBattleCardList,
} from "../../../utils/options/battleCard";
import { battleResultList } from "../../../utils/options/battleResult";
// NOTE 組件
import { Card, CardContent } from "../../../component/Card";
import { InputTitle, AutocompleteSelect } from "../../../component/Form";
import AdaptiveImage from "../../../component/AdaptiveImage";
import CustomUploadFiles from "../../../component/CustomUploadFiles";

const arr1 = ["COURAGE", "PERSEVERANCE", "LEARNING"]; // 剪刀 -> COURAGE, 石頭 -> PERSEVERANCE, 布 -> LEARNING
const arr2 = ["APTITUDE", "BACKGROUND", "LUCK"]; // 剪刀 -> APTITUDE, 石頭 -> BACKGROUND, 布 -> LUCK

const categorizedPairs = arr1.reduce((acc, item1) => {
  acc[item1] = arr2.reduce((subAcc, item2) => {
    subAcc[item2] = arr1.flatMap((item3) =>
      arr2.map((item4) => ({ item1, item2, item3, item4 }))
    );
    return subAcc;
  }, {});
  return acc;
}, {});

const TabPanel = ({ children, value, index, style = {} }) => {
  return value === index ? (
    <div style={style}>
      <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
    </div>
  ) : null;
};

/** - 結果設定 */
export default memo(function ResultSetting() {
  const [select, setSelect] = useState(0);
  return (
    <>
      <Grid container item>
        <Tabs value={select} onChange={(e, n) => setSelect(n)}>
          {firstBattleCardList.map((item, index) => (
            <Tab key={"firstBattleCardList" + index} label={item.label} />
          ))}
        </Tabs>
      </Grid>
      <Grid container item style={{ marginTop: 16 }}>
        <TabPanel value={select} index={0} style={{ width: "100%" }}>
          <Card style={{ width: "100%" }}>
            <CategorizedPair
              data={categorizedPairs[firstBattleCardList[0].value]}
              select={select}
            />
          </Card>
        </TabPanel>
        <TabPanel value={select} index={1} style={{ width: "100%" }}>
          <Card style={{ width: "100%" }}>
            <CategorizedPair
              data={categorizedPairs[firstBattleCardList[1].value]}
              select={select}
            />
          </Card>
        </TabPanel>
        <TabPanel value={select} index={2} style={{ width: "100%" }}>
          <Card style={{ width: "100%" }}>
            <CategorizedPair
              data={categorizedPairs[firstBattleCardList[2].value]}
              select={select}
            />
          </Card>
        </TabPanel>
      </Grid>
    </>
  );
});

/** - 第二層 */
const CategorizedPair = memo(function CategorizedPair({ data, select }) {
  const [secondSelect, setSecondSelect] = useState(0);

  return (
    <CardContent>
      <Grid container item>
        <Tabs value={secondSelect} onChange={(e, n) => setSecondSelect(n)}>
          {secondBattleCardList.map((item2, index2) => (
            <Tab key={"secondBattleCardList" + index2} label={item2.label} />
          ))}
        </Tabs>
      </Grid>
      <Grid container item style={{ marginTop: 16 }}>
        <TabPanel value={secondSelect} index={0} style={{ width: "100%" }}>
          <Grid container item spacing={3} style={{ marginTop: 16 }}>
            {data[secondBattleCardList[0].value].map((item3, index3) => (
              <CategorizedPairsContent
                key={"item3" + index3}
                select={select}
                secondSelect={secondSelect}
                item3={item3}
                index3={index3}
              />
            ))}
          </Grid>
        </TabPanel>
        <TabPanel value={secondSelect} index={1} style={{ width: "100%" }}>
          <Grid container item spacing={3} style={{ marginTop: 16 }}>
            {data[secondBattleCardList[1].value].map((item3, index3) => (
              <CategorizedPairsContent
                key={"item3" + index3}
                select={select}
                secondSelect={secondSelect}
                item3={item3}
                index3={index3}
              />
            ))}
          </Grid>
        </TabPanel>
        <TabPanel value={secondSelect} index={2} style={{ width: "100%" }}>
          <Grid container item spacing={3} style={{ marginTop: 16 }}>
            {data[secondBattleCardList[2].value].map((item3, index3) => (
              <CategorizedPairsContent
                key={"item3" + index3}
                select={select}
                secondSelect={secondSelect}
                item3={item3}
                index3={index3}
              />
            ))}
          </Grid>
        </TabPanel>
      </Grid>
    </CardContent>
  );
});

/** - 第三層 */
const CategorizedPairsContent = memo(function CategorizedPairsContent({
  select = 0,
  secondSelect = 0,
  item3,
  index3,
}) {
  const { setValue } = useFormContext();
  const ratio = `${(4 / 3).toFixed(2) * 100}%`;
  const formIndex = 11 + select * 27 + secondSelect * 9 + index3;
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: 16,
        }}
      >
        <InputTitle
          label={`${battleCardEnum[item3.item1]} ${
            battleCardEnum[item3.item2]
          } ${battleCardEnum[item3.item3]} ${battleCardEnum[item3.item4]}`}
        />
        <Controller
          key={`battleAssetInputs.${formIndex}.url`}
          name={`battleAssetInputs.${formIndex}.url`}
          rules={{
            required: "必填欄位 !",
          }}
          render={({ field, fieldState: { error } }) => (
            <div>
              <InputTitle label="影片" required />
              <AdaptiveImage ratio={ratio}>
                <CustomUploadFiles
                  name={`battleAssetInputs.${formIndex}.url`}
                  multiple={false}
                  accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                  type="video"
                  render={() => (
                    <ReactPlayer
                      url={field?.value}
                      width={"100%"}
                      height="100%"
                      controls
                    />
                  )}
                  uploadIconStyle={{
                    border: `1px solid ${Boolean(error) ? "red" : "#9c9c9c"}`,
                    borderRadius: "5px",
                  }}
                  {...field}
                  onChange={(e) => {
                    setValue(`battleAssetInputs.${formIndex}.type`, "RESULT");
                    setValue(
                      `battleAssetInputs.${formIndex}.cardA1`,
                      item3.item1
                    );
                    setValue(
                      `battleAssetInputs.${formIndex}.cardA2`,
                      item3.item2
                    );
                    setValue(
                      `battleAssetInputs.${formIndex}.cardB1`,
                      item3.item3
                    );
                    setValue(
                      `battleAssetInputs.${formIndex}.cardB2`,
                      item3.item4
                    );
                    field.onChange(e.location);
                  }}
                />
              </AdaptiveImage>
              <FormControl name={`battleAssetInputs.${formIndex}.url`}>
                {error?.message && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
              </FormControl>
            </div>
          )}
        />
        <Controller
          key={`battleAssetInputs.${formIndex}.result`}
          name={`battleAssetInputs.${formIndex}.result`}
          rules={{
            required: "必填欄位 !",
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div>
              <InputTitle label="結果" required />
              <AutocompleteSelect
                items={battleResultList}
                value={battleResultList.find((item) => item.value === value)}
                onChange={(e, value) => onChange(value?.value)}
                disableClearable
                fullWidth
              />
              <FormControl name={`battleAssetInputs.${formIndex}.result`}>
                {error?.message && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
              </FormControl>
            </div>
          )}
        />
        <Box style={{ paddingTop: "16px", paddingBottom: "8px" }}>
          <Divider />
        </Box>
      </Box>
    </Grid>
  );
});
