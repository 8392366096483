import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  ButtonBase,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useSetAtom, useAtomValue } from "jotai";
import { selectAtom } from "jotai/utils";
import { deepEqual } from "fast-equals";
import { useFormContext, useWatch } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
// NOTE 工具
import { useAlert } from "../../../component/Alert";
import { competitionsStatusAtom } from "../../../jotai/Competitions";
// NOTE 組件
import { FieldCache } from "../../../component/Form";
import VideoPlayer from "../VideoPlayer";

// NOTE 清除對戰房
/** - 清除對戰房 */
const CLEAR_BATTLE_ROOM = gql`
  mutation clearBattleRoom($id: Int!) {
    clearBattleRoom(id: $id) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "50%",
  },
  topBlockLayout: {
    padding: "16px",
    gap: "32px",
  },
  cardLayout: {
    gap: "8px",
    paddingLeft: "32px",
    paddingRight: "32px",
  },
  cardImg: {
    maxWidth: "100%",
    height: "100%",
    aspectRatio: 621 / 875,
  },
}));

/** - 開始戰鬥 */
export default function GamePreparation() {
  const { alert } = useAlert();
  const resultVideoUrl = useWatch({ name: "resultVideoUrl" });
  const { getValues } = useFormContext();
  const { allowVideoPlay } = useAtomValue(
    selectAtom(
      competitionsStatusAtom,
      useCallback(
        (state) => ({
          allowVideoPlay: state.allowVideoPlay,
        }),
        []
      ),
      deepEqual
    )
  );
  const setCompetitionsStatus = useSetAtom(competitionsStatusAtom);
  const [panelDisplay, setPanelDisplay] = useState(false);
  const [mutateLoading, setMutateLoading] = useState(false);
  const countdownBeginsRef = useRef();
  const cacheRef = useRef({
    alert: () => {},
    setCompetitionsStatus: () => {},
  });
  useEffect(() => {
    cacheRef.current.alert = alert;
    cacheRef.current.setCompetitionsStatus = setCompetitionsStatus;
  }, [alert, setCompetitionsStatus]);

  useEffect(() => {
    if (resultVideoUrl) {
      countdownBeginsRef.current = setTimeout(() => {
        cacheRef.current.setCompetitionsStatus((v) => {
          v.allowVideoPlay = true;
        });
      }, 2000);
    }
    return () => {
      if (countdownBeginsRef.current) {
        clearTimeout(countdownBeginsRef.current);
      }
    };
  }, [resultVideoUrl]);

  const [clearBattleRoomFn] = useMutation(CLEAR_BATTLE_ROOM, {
    onCompleted({ clearBattleRoom: { success, message } }) {
      if (success) {
      } else if (message) {
        setMutateLoading(false);
        cacheRef.current.notice(message);
      }
    },
    onError(error) {
      setMutateLoading(false);
      cacheRef.current.notice(error.message.replace("GraphQL error: ", ""));
      return null;
    },
  });

  const _handleEnded = useCallback(() => {
    setPanelDisplay(true);
  }, []);

  const _handleGoBack = useCallback(() => {
    const roomId = getValues("roomId");
    cacheRef.current.alert("", "確定要返回首頁？", [
      { text: "取消", type: "cancel" },
      {
        text: "確定",
        onPress: () => {
          setMutateLoading(true);
          clearBattleRoomFn({
            variables: { id: Number(roomId) },
          });
        },
        type: "ok",
      },
    ]);
  }, [getValues, clearBattleRoomFn]);

  if (allowVideoPlay) {
    return (
      <>
        {panelDisplay && (
          <Box
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              zIndex: 4,
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          >
            {mutateLoading ? (
              <CircularProgress style={{ color: "#fff" }} />
            ) : (
              <ButtonBase
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                onClick={_handleGoBack}
                disableRipple
                disableTouchRipple
                disabled={mutateLoading}
              >
                <FieldCache
                  name={["result", "participantA", "participantB"]}
                  render={(watchedQuery) => {
                    const [result, participantA, participantB] = watchedQuery;
                    const winner =
                      result === "A_WIN"
                        ? participantA?.fullName
                        : result === "B_WIN"
                        ? participantB?.fullName
                        : "";
                    return (
                      <>
                        <Typography
                          variant="h4"
                          style={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontSize: "56px",
                          }}
                        >
                          {result === "DRAW" ? "平手" : "勝利方"}
                        </Typography>
                        <Typography
                          variant="h4"
                          style={{
                            color: "#fff",
                            fontWeight: 500,
                            fontSize: "56px",
                          }}
                        >
                          {result === "DRAW" ? "DRAW" : "VICTORY"}
                        </Typography>
                        {result !== "DRAW" && (
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#fff",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              paddingTop: "4px",
                              paddingBottom: "4px",
                              marginTop: "8px",
                              height: "42px",
                              width: "160px",
                              wordBreak: "break-all",
                              borderRadius: "20px",
                            }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                WebkitLineClamp: 1,
                                textOverflow: "ellipsis",
                                fontWeight: 500,
                                letterSpacing: "4px",
                              }}
                            >
                              {replaceMiddleWithO(winner)}
                            </Typography>
                          </Box>
                        )}
                      </>
                    );
                  }}
                />
              </ButtonBase>
            )}
          </Box>
        )}
        <VideoPlayer url={resultVideoUrl} onEnded={_handleEnded} />
      </>
    );
  }
  return <WaitingResults />;
}

/** - 等待結果 */
const WaitingResults = memo(function WaitingResults() {
  const classes = useStyles();
  const { battleAssets } = useAtomValue(
    selectAtom(
      competitionsStatusAtom,
      useCallback(
        (state) => ({
          battleAssets: state.battleAssets,
        }),
        []
      ),
      deepEqual
    )
  );
  return (
    <>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        flex={1}
        style={{
          backgroundImage: `url("${battleAssets[1]?.url}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box style={{ width: "100%", aspectRatio: 1182 / 1890, zIndex: 1 }}>
          <img
            src={battleAssets[2]?.url}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            alt="islands"
          />
        </Box>
        <Box
          position="absolute"
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          style={{ gap: "32px" }}
          zIndex={2}
        >
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            className={classes.topBlockLayout}
          >
            <Box display="flex" justifyContent="center" width="100%">
              <FieldCache
                name="participantA"
                render={(participantA) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    height="88px"
                    width="223px"
                    style={{
                      backgroundColor: "#fff",
                      gap: "24px",
                      padding: "24px",
                    }}
                    borderRadius="100px"
                  >
                    <Box
                      height="60px"
                      sx={{ aspectRatio: 1, borderRadius: "50%" }}
                    >
                      <img
                        src={
                          participantA?.profilePicture
                            ? participantA?.profilePicture?.location
                            : "/img/Competitions/default_profilePicture.png"
                        }
                        className={classes.image}
                        alt="profilePicture"
                      />
                    </Box>
                    <Typography
                      variant="h5"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        WebkitLineClamp: 1,
                        textOverflow: "ellipsis",
                        letterSpacing: "8px",
                        fontWeight: 500,
                      }}
                    >
                      {replaceMiddleWithO(participantA?.fullName)}
                    </Typography>
                  </Box>
                )}
              />
            </Box>
            <CardsBlockA />
          </Box>
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            justifyContent="flex-end"
            className={classes.topBlockLayout}
          >
            <CardsBlockB />
            <Box display="flex" justifyContent="center" width="100%">
              <FieldCache
                name="participantB"
                render={(participantB) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    height="88px"
                    width="223px"
                    style={{
                      backgroundColor: "#fff",
                      gap: "24px",
                      padding: "24px",
                    }}
                    borderRadius="100px"
                  >
                    <Box
                      height="60px"
                      sx={{ aspectRatio: 1, borderRadius: "50%" }}
                    >
                      <img
                        src={
                          participantB?.profilePicture
                            ? participantB?.profilePicture?.location
                            : "/img/Competitions/default_profilePicture.png"
                        }
                        className={classes.image}
                        alt="profilePicture"
                      />
                    </Box>
                    <Typography
                      variant="h5"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        WebkitLineClamp: 1,
                        textOverflow: "ellipsis",
                        letterSpacing: "8px",
                        fontWeight: 500,
                      }}
                    >
                      {replaceMiddleWithO(participantB?.fullName)}
                    </Typography>
                  </Box>
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
});

/** - 卡片區域A */
const CardsBlockA = memo(function CardsBlockA() {
  const classes = useStyles();
  const { battleAssets } = useAtomValue(
    selectAtom(
      competitionsStatusAtom,
      useCallback(
        (state) => ({
          battleAssets: state.battleAssets,
        }),
        []
      ),
      deepEqual
    )
  );
  const setCompetitionsStatus = useSetAtom(competitionsStatusAtom);
  const [maxHeight, setMaxHeight] = useState(0);
  const [renderFirst, setRenderFirst] = useState(false);
  const cacheRef = useRef({
    setCompetitionsStatus: () => {},
  });
  useEffect(() => {
    cacheRef.current.setCompetitionsStatus = setCompetitionsStatus;
  }, [setCompetitionsStatus]);
  useEffect(() => {
    setRenderFirst(true);
  }, []);
  const handleResize = useCallback(() => {
    cacheRef.current.setCompetitionsStatus((v) => {
      v.maxHeight = 0;
    });
    setMaxHeight(0);
    const playerElement = document.getElementById("cardLayout");
    const playerHeight = playerElement.clientHeight;
    setMaxHeight(playerHeight);
    cacheRef.current.setCompetitionsStatus((v) => {
      v.maxHeight = playerHeight;
    });
  }, []);
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [renderFirst, handleResize]);
  return (
    <Box id="cardLayout" display="flex" flex={1} height="100%">
      {maxHeight > 0 && (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            maxHeight,
          }}
        >
          <Box className={classes.cardImg}>
            <img
              src={battleAssets[3]?.url}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              alt={"cardB1"}
            />
          </Box>
          <Box className={classes.cardImg}>
            <img
              src={battleAssets[4]?.url}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              alt={"cardB2"}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
});

/** - 卡片區域B */
const CardsBlockB = memo(function CardsBlockB() {
  const classes = useStyles();
  const { battleAssets, maxHeight } = useAtomValue(
    selectAtom(
      competitionsStatusAtom,
      useCallback(
        (state) => ({
          battleAssets: state.battleAssets,
          maxHeight: state.maxHeight,
        }),
        []
      ),
      deepEqual
    )
  );
  return (
    <Box display="flex">
      {maxHeight > 0 && (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            maxHeight,
          }}
        >
          <Box className={classes.cardImg}>
            <img
              src={battleAssets[3]?.url}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              alt={"cardA1"}
            />
          </Box>
          <Box className={classes.cardImg}>
            <img
              src={battleAssets[4]?.url}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
              alt={"cardA2"}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
});

/** - 名稱中間改成`O` */
function replaceMiddleWithO(fullName = "") {
  // 確認名字的長度
  const length = fullName.length;

  // 確認要替換的字元數
  const middleCount = Math.max(0, length - 2);

  // 產生要替換的 "O" 字串
  const middleO = "O".repeat(middleCount);

  // 將名字的第二個字元開始的部分替換成 "O"
  const replacedName =
    fullName.substring(0, 1) + middleO + fullName.substring(length - 1);

  return replacedName;
}
