import React, { createContext, useContext, useReducer } from "react";

const initFunction = {
  setMemberToken,
  setMemberId,
  setConsoleMemberToken,
  getMemberToken,
  getMemberId,
  clearMemberId,
  getConsoleMemberToken,
  clearMemberToken,
  clearConsoleMemberToken,
  verifiedMember,
  consoleMemberVerified,
  setCartId,
  getCartId,
  clearCartId,
  verifiedCartId,
  setSearchProductPageSize,
  getSearchProductPageSize,
  clearSearchProductPageSize,
  setSearchProductPage,
  getSearchProductPage,
  clearSearchProductPage,
  setSearchMemberId,
  getSearchMemberId,
  clearSearchMemberId,
  setSearchMemberStartTime,
  getSearchMemberStartTime,
  clearSearchMemberStartTime,
  setSearchMemberEndTime,
  getSearchMemberEndTime,
  clearSearchMemberEndTime,
  setSearchMemberSearchTerm,
  getSearchMemberSearchTerm,
  clearSearchMemberSearchTerm,
  setSearchMemberCountryCallingCode,
  getSearchMemberCountryCallingCode,
  clearSearchMemberCountryCallingCode,
  setSearchMemberTier,
  getSearchMemberTier,
  clearSearchMemberTier,
  setSearchMemberHidden,
  getSearchMemberHidden,
  clearSearchMemberHidden,
  setSearchMemberLatest,
  getSearchMemberLatest,
  clearSearchMemberLatest,
  setSearchMemberPageSize,
  getSearchMemberPageSize,
  clearSearchMemberPageSize,
  setSearchMemberPage,
  getSearchMemberPage,
  clearSearchMemberPage,
  setSearchMemberProductIds,
  getSearchMemberProductIds,
  clearSearchMemberProductIds,
  setSearchOrderStatus,
  getSearchOrderStatus,
  clearSearchOrderStatus,
  setSearchOrderProductId,
  getSearchOrderProductId,
  clearSearchOrderProductId,
  setSearchOrderInternational,
  getSearchOrderInternational,
  clearSearchOrderInternational,
  setSearchOrderSearchTerm,
  getSearchOrderSearchTerm,
  clearSearchOrderSearchTerm,
  setSearchOrderType,
  getSearchOrderType,
  clearSearchOrderType,
  setSearchOrderLatest,
  getSearchOrderLatest,
  clearSearchOrderLatest,
  setSearchOrderPageSize,
  getSearchOrderPageSize,
  clearSearchOrderPageSize,
  setSearchOrderPage,
  getSearchOrderPage,
  clearSearchOrderPage,
  setSearchOrderEventIds,
  getSearchOrderEventIds,
  clearSearchOrderEventIds,
  setSearchShipmentStatus,
  getSearchShipmentStatus,
  clearSearchShipmentStatus,
  setSearchShipmentProductId,
  getSearchShipmentProductId,
  clearSearchShipmentProductId,
  setSearchShipmentShipmentMethod,
  getSearchShipmentShipmentMethod,
  clearSearchShipmentShipmentMethod,
  setSearchShipmentSearchTerm,
  getSearchShipmentSearchTerm,
  clearSearchShipmentSearchTerm,
  setSearchShipmentCombinedDelivery,
  getSearchShipmentCombinedDelivery,
  clearSearchShipmentCombinedDelivery,
  setSearchShipmentStartTime,
  getSearchShipmentStartTime,
  clearSearchShipmentStartTime,
  setSearchShipmentEndTime,
  getSearchShipmentEndTime,
  clearSearchShipmentEndTime,
  setSearchShipmentLatest,
  getSearchShipmentLatest,
  clearSearchShipmentLatest,
  setSearchShipmentPageSize,
  getSearchShipmentPageSize,
  clearSearchShipmentPageSize,
  setSearchShipmentPage,
  getSearchShipmentPage,
  clearSearchShipmentPage,
};

const LocalStorageContext = createContext(initFunction);

export function useLocalStorage() {
  const { forceUpdate } = useContext(LocalStorageContext);
  return { forceUpdate, ...initFunction };
}

export function LocalStorageContextProvider({ children }) {
  const [ignored, forceUpdate] = useReducer((e) => !e, false);
  return (
    <LocalStorageContext.Provider value={{ ignored, forceUpdate }}>
      {children}
    </LocalStorageContext.Provider>
  );
}

// NOTE Member
function setMemberToken(token) {
  return localStorage.setItem("@luyaoToken", token);
}
function setMemberId(id) {
  return localStorage.setItem("@luyaoId", id);
}
function setConsoleMemberToken(token) {
  return localStorage.setItem("@luyaoConsoleToken", token);
}
function getMemberToken() {
  return localStorage.getItem("@luyaoToken");
}
function getMemberId() {
  return localStorage.getItem("@luyaoId");
}
function getConsoleMemberToken() {
  return localStorage.getItem("@luyaoConsoleToken");
}
function clearMemberToken() {
  return localStorage.removeItem("@luyaoToken");
}
function clearMemberId() {
  return localStorage.removeItem("@luyaoId");
}
function clearConsoleMemberToken() {
  return localStorage.removeItem("@luyaoConsoleToken");
}
function verifiedMember() {
  return Boolean(localStorage.getItem("@luyaoToken"));
}
function consoleMemberVerified() {
  return Boolean(localStorage.getItem("@luyaoConsoleToken"));
}

// NOTE Cart
function setCartId(id) {
  return localStorage.setItem("@luyaoCartId", id);
}
function getCartId() {
  return localStorage.getItem("@luyaoCartId");
}
function clearCartId() {
  return localStorage.removeItem("@luyaoCartId");
}
function verifiedCartId() {
  return Boolean(localStorage.getItem("@luyaoCartId"));
}

// NOTE consoleSearch

// SECTION Product

// NOTE PageSize
function setSearchProductPageSize(pageSize) {
  return localStorage.setItem("@luyaoSearchProductPageSize", pageSize);
}
function getSearchProductPageSize() {
  return localStorage.getItem("@luyaoSearchProductPageSize");
}
function clearSearchProductPageSize() {
  return localStorage.removeItem("@luyaoSearchProductPageSize");
}
// NOTE Page
function setSearchProductPage(page) {
  return localStorage.setItem("@luyaoSearchProductPage", page);
}
function getSearchProductPage() {
  return localStorage.getItem("@luyaoSearchProductPage");
}
function clearSearchProductPage() {
  return localStorage.removeItem("@luyaoSearchProductPage");
}
// !SECTION

// SECTION Member

// NOTE id
function setSearchMemberId(id) {
  return localStorage.setItem("@luyaoSearchMemberId", id);
}
function getSearchMemberId() {
  return localStorage.getItem("@luyaoSearchMemberId");
}
function clearSearchMemberId() {
  return localStorage.removeItem("@luyaoSearchMemberId");
}
// NOTE startTime
function setSearchMemberStartTime(startTime) {
  return localStorage.setItem("@luyaoSearchMemberStartTime", startTime);
}
function getSearchMemberStartTime() {
  return localStorage.getItem("@luyaoSearchMemberStartTime");
}
function clearSearchMemberStartTime() {
  return localStorage.removeItem("@luyaoSearchMemberStartTime");
}
// NOTE endTime
function setSearchMemberEndTime(endTime) {
  return localStorage.setItem("@luyaoSearchMemberEndTime", endTime);
}
function getSearchMemberEndTime() {
  return localStorage.getItem("@luyaoSearchMemberEndTime");
}
function clearSearchMemberEndTime() {
  return localStorage.removeItem("@luyaoSearchMemberEndTime");
}
// NOTE SearchTerm
function setSearchMemberSearchTerm(searchTerm) {
  return localStorage.setItem("@luyaoSearchMemberSearchTerm", searchTerm);
}
function getSearchMemberSearchTerm() {
  return localStorage.getItem("@luyaoSearchMemberSearchTerm");
}
function clearSearchMemberSearchTerm() {
  return localStorage.removeItem("@luyaoSearchMemberSearchTerm");
}
// NOTE CountryCallingCode
function setSearchMemberCountryCallingCode(countryCallingCode) {
  return localStorage.setItem(
    "@luyaoSearchMemberCountryCallingCode",
    countryCallingCode
  );
}
function getSearchMemberCountryCallingCode() {
  return localStorage.getItem("@luyaoSearchMemberCountryCallingCode");
}
function clearSearchMemberCountryCallingCode() {
  return localStorage.removeItem("@luyaoSearchMemberCountryCallingCode");
}
// NOTE Tier
function setSearchMemberTier(tier) {
  return localStorage.setItem("@luyaoSearchMemberTier", tier);
}
function getSearchMemberTier() {
  return localStorage.getItem("@luyaoSearchMemberTier");
}
function clearSearchMemberTier() {
  return localStorage.removeItem("@luyaoSearchMemberTier");
}
// NOTE Hidden
function setSearchMemberHidden(hidden) {
  return localStorage.setItem("@luyaoSearchMemberHidden", hidden);
}
function getSearchMemberHidden() {
  return localStorage.getItem("@luyaoSearchMemberHidden");
}
function clearSearchMemberHidden() {
  return localStorage.removeItem("@luyaoSearchMemberHidden");
}
// NOTE Latest
function setSearchMemberLatest(latest) {
  return localStorage.setItem("@luyaoSearchMemberLatest", latest);
}
function getSearchMemberLatest() {
  return localStorage.getItem("@luyaoSearchMemberLatest");
}
function clearSearchMemberLatest() {
  return localStorage.removeItem("@luyaoSearchMemberLatest");
}
// NOTE PageSize
function setSearchMemberPageSize(pageSize) {
  return localStorage.setItem("@luyaoSearchMemberPageSize", pageSize);
}
function getSearchMemberPageSize() {
  return localStorage.getItem("@luyaoSearchMemberPageSize");
}
function clearSearchMemberPageSize() {
  return localStorage.removeItem("@luyaoSearchMemberPageSize");
}
// NOTE Page
function setSearchMemberPage(page) {
  return localStorage.setItem("@luyaoSearchMemberPage", page);
}
function getSearchMemberPage() {
  return localStorage.getItem("@luyaoSearchMemberPage");
}
function clearSearchMemberPage() {
  return localStorage.removeItem("@luyaoSearchMemberPage");
}
// NOTE ProductIds
function setSearchMemberProductIds(productIds) {
  return localStorage.setItem("@luyaoSearchMemberProductIds", productIds);
}
function getSearchMemberProductIds() {
  return localStorage.getItem("@luyaoSearchMemberProductIds");
}
function clearSearchMemberProductIds() {
  return localStorage.removeItem("@luyaoSearchMemberProductIds");
}
// !SECTION

// SECTION Order

// NOTE Status
function setSearchOrderStatus(status) {
  return localStorage.setItem("@luyaoSearchOrderStatus", status);
}
function getSearchOrderStatus() {
  return localStorage.getItem("@luyaoSearchOrderStatus");
}
function clearSearchOrderStatus() {
  return localStorage.removeItem("@luyaoSearchOrderStatus");
}
// NOTE ProductId
function setSearchOrderProductId(productId) {
  return localStorage.setItem("@luyaoSearchOrderProductId", productId);
}
function getSearchOrderProductId() {
  return localStorage.getItem("@luyaoSearchOrderProductId");
}
function clearSearchOrderProductId() {
  return localStorage.removeItem("@luyaoSearchOrderProductId");
}
// NOTE International
function setSearchOrderInternational(international) {
  return localStorage.setItem("@luyaoSearchOrderInternational", international);
}
function getSearchOrderInternational() {
  return localStorage.getItem("@luyaoSearchOrderInternational");
}
function clearSearchOrderInternational() {
  return localStorage.removeItem("@luyaoSearchOrderInternational");
}
// NOTE SearchTerm
function setSearchOrderSearchTerm(searchTerm) {
  return localStorage.setItem("@luyaoSearchOrderSearchTerm", searchTerm);
}
function getSearchOrderSearchTerm() {
  return localStorage.getItem("@luyaoSearchOrderSearchTerm");
}
function clearSearchOrderSearchTerm() {
  return localStorage.removeItem("@luyaoSearchOrderSearchTerm");
}
// NOTE Type
function setSearchOrderType(type) {
  return localStorage.setItem("@luyaoSearchOrderType", type);
}
function getSearchOrderType() {
  return localStorage.getItem("@luyaoSearchOrderType");
}
function clearSearchOrderType() {
  return localStorage.removeItem("@luyaoSearchOrderType");
}
// NOTE Latest
function setSearchOrderLatest(latest) {
  return localStorage.setItem("@luyaoSearchOrderLatest", latest);
}
function getSearchOrderLatest() {
  return localStorage.getItem("@luyaoSearchOrderLatest");
}
function clearSearchOrderLatest() {
  return localStorage.removeItem("@luyaoSearchOrderLatest");
}
// NOTE PageSize
function setSearchOrderPageSize(pageSize) {
  return localStorage.setItem("@luyaoSearchOrderPageSize", pageSize);
}
function getSearchOrderPageSize() {
  return localStorage.getItem("@luyaoSearchOrderPageSize");
}
function clearSearchOrderPageSize() {
  return localStorage.removeItem("@luyaoSearchOrderPageSize");
}
// NOTE Page
function setSearchOrderPage(page) {
  return localStorage.setItem("@luyaoSearchOrderPage", page);
}
function getSearchOrderPage() {
  return localStorage.getItem("@luyaoSearchOrderPage");
}
function clearSearchOrderPage() {
  return localStorage.removeItem("@luyaoSearchOrderPage");
}
// NOTE EventIds
function setSearchOrderEventIds(eventIds) {
  return localStorage.setItem("@luyaoSearchOrderEventIds", eventIds);
}
function getSearchOrderEventIds() {
  return localStorage.getItem("@luyaoSearchOrderEventIds");
}
function clearSearchOrderEventIds() {
  return localStorage.removeItem("@luyaoSearchOrderEventIds");
}
// !SECTION

// SECTION shipment

// NOTE Status
function setSearchShipmentStatus(status) {
  return localStorage.setItem("@luyaoSearchShipmentStatus", status);
}
function getSearchShipmentStatus() {
  return localStorage.getItem("@luyaoSearchShipmentStatus");
}
function clearSearchShipmentStatus() {
  return localStorage.removeItem("@luyaoSearchShipmentStatus");
}
// NOTE ProductId
function setSearchShipmentProductId(productId) {
  return localStorage.setItem("@luyaoSearchShipmentProductId", productId);
}
function getSearchShipmentProductId() {
  return localStorage.getItem("@luyaoSearchShipmentProductId");
}
function clearSearchShipmentProductId() {
  return localStorage.removeItem("@luyaoSearchShipmentProductId");
}
// NOTE shipmentMethod
function setSearchShipmentShipmentMethod(ShipmentMethod) {
  return localStorage.setItem(
    "@luyaoSearchShipmentShipmentMethod",
    ShipmentMethod
  );
}
function getSearchShipmentShipmentMethod() {
  return localStorage.getItem("@luyaoSearchShipmentShipmentMethod");
}
function clearSearchShipmentShipmentMethod() {
  return localStorage.removeItem("@luyaoSearchShipmentShipmentMethod");
}
// NOTE SearchTerm
function setSearchShipmentSearchTerm(searchTerm) {
  return localStorage.setItem("@luyaoSearchShipmentSearchTerm", searchTerm);
}
function getSearchShipmentSearchTerm() {
  return localStorage.getItem("@luyaoSearchShipmentSearchTerm");
}
function clearSearchShipmentSearchTerm() {
  return localStorage.removeItem("@luyaoSearchShipmentSearchTerm");
}
// NOTE CombinedDelivery
function setSearchShipmentCombinedDelivery(CombinedDelivery) {
  return localStorage.setItem(
    "@luyaoSearchShipmentCombinedDelivery",
    CombinedDelivery
  );
}
function getSearchShipmentCombinedDelivery() {
  return localStorage.getItem("@luyaoSearchShipmentCombinedDelivery");
}
function clearSearchShipmentCombinedDelivery() {
  return localStorage.removeItem("@luyaoSearchShipmentCombinedDelivery");
}
// NOTE startTime
function setSearchShipmentStartTime(startTime) {
  return localStorage.setItem("@luyaoSearchShipmentStartTime", startTime);
}
function getSearchShipmentStartTime() {
  return localStorage.getItem("@luyaoSearchShipmentStartTime");
}
function clearSearchShipmentStartTime() {
  return localStorage.removeItem("@luyaoSearchShipmentStartTime");
}
// NOTE endTime
function setSearchShipmentEndTime(endTime) {
  return localStorage.setItem("@luyaoSearchShipmentEndTime", endTime);
}
function getSearchShipmentEndTime() {
  return localStorage.getItem("@luyaoSearchShipmentEndTime");
}
function clearSearchShipmentEndTime() {
  return localStorage.removeItem("@luyaoSearchShipmentEndTime");
}
// NOTE Latest
function setSearchShipmentLatest(latest) {
  return localStorage.setItem("@luyaoSearchShipmentLatest", latest);
}
function getSearchShipmentLatest() {
  return localStorage.getItem("@luyaoSearchShipmentLatest");
}
function clearSearchShipmentLatest() {
  return localStorage.removeItem("@luyaoSearchShipmentLatest");
}
// NOTE PageSize
function setSearchShipmentPageSize(pageSize) {
  return localStorage.setItem("@luyaoSearchShipmentPageSize", pageSize);
}
function getSearchShipmentPageSize() {
  return localStorage.getItem("@luyaoSearchShipmentPageSize");
}
function clearSearchShipmentPageSize() {
  return localStorage.removeItem("@luyaoSearchShipmentPageSize");
}
// NOTE Page
function setSearchShipmentPage(page) {
  return localStorage.setItem("@luyaoSearchShipmentPage", page);
}
function getSearchShipmentPage() {
  return localStorage.getItem("@luyaoSearchShipmentPage");
}
function clearSearchShipmentPage() {
  return localStorage.removeItem("@luyaoSearchShipmentPage");
}
// !SECTION
