import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useSetAtom, useAtomValue } from "jotai";
import { selectAtom } from "jotai/utils";
import { deepEqual } from "fast-equals";
import { useFormContext } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
// NOTE 工具
import { competitionsStatusAtom } from "../../../jotai/Competitions";
// NOTE 組件
import { useAlert } from "../../../component/Alert";
import { FieldCache } from "../../../component/Form";

// SECTION apollo
// NOTE 出牌
/** - 出牌 */
const SUBMIT_CARDS = gql`
  mutation submitCards($roomId: Int!, $role: BattleRole!, $cards: [Card!]!) {
    submitCards(roomId: $roomId, role: $role, cards: $cards) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;
// !SECTION

const useStyles = makeStyles((theme) => ({
  topBlockLayout: {
    padding: "32px",
  },
  bottomBlockLayout: {
    paddingTop: "16px",
    paddingBottom: "16px",
    gap: "16px",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "32px",
      paddingRight: "32px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "48px",
      paddingRight: "48px",
    },
  },
  cardLayout: {
    gap: "8px",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  cardImg: {
    maxWidth: "100%",
    height: "100%",
    aspectRatio: 621 / 875,
  },
  battleButton: {
    height: "100%",
    maxHeight: "72px",
    borderColor: "#000",
    paddingLeft: "48px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      opacity: 0.75,
    },
  },
  battleButtonText: {
    fontSize: "40px",
    fontWeight: "bold",
    [theme.breakpoints.up("xs")]: {
      letterSpacing: "16px",
    },
    [theme.breakpoints.up("sm")]: {
      letterSpacing: "24px",
    },
  },
}));

/** - 開始戰鬥 */
export default function StartBattle() {
  const classes = useStyles();
  const { alert, notice } = useAlert();
  const { battleAssets, maxHeight, locked } = useAtomValue(
    selectAtom(
      competitionsStatusAtom,
      useCallback(
        (state) => ({
          battleAssets: state.battleAssets,
          maxHeight: state.maxHeight,
          locked: state.locked,
        }),
        []
      ),
      deepEqual
    )
  );
  const setCompetitionsStatus = useSetAtom(competitionsStatusAtom);
  const { handleSubmit, reset } = useFormContext();
  const cacheRef = useRef({
    alert: () => {},
    notice: () => {},
    setCompetitionsStatus: () => {},
  });
  useEffect(() => {
    cacheRef.current.alert = alert;
    cacheRef.current.notice = notice;
    cacheRef.current.setCompetitionsStatus = setCompetitionsStatus;
  }, [alert, notice, setCompetitionsStatus]);

  const [submitCardsFn, { loading: submitCardsLoading }] = useMutation(
    SUBMIT_CARDS,
    {
      onCompleted({ submitCards: { success, message } }) {
        if (success) {
          cacheRef.current.setCompetitionsStatus((v) => {
            v.locked = true;
          });
        } else if (message) {
          cacheRef.current.notice(message);
        }
      },
      onError(error) {
        cacheRef.current.notice(error.message.replace("GraphQL error: ", ""));
        return null;
      },
    }
  );
  const _submitCards = useCallback(
    ({ roomId, role, cardA1, cardA2, cardB1, cardB2 }) => {
      if (locked) {
        cacheRef.current.alert("", "確定要返回首頁？", [
          { text: "取消", onPress: () => {}, type: "cancel" },
          {
            text: "確定",
            onPress: () => {
              reset();
              cacheRef.current.setCompetitionsStatus((v) => {
                v.step = undefined;
                v.locked = false;
              });
            },
            type: "ok",
          },
        ]);
        return;
      }
      if (
        (cardA1?.cardType && cardA2?.cardType) ||
        (cardB1?.cardType && cardB2?.cardType)
      ) {
        let cards = [];
        switch (role) {
          case "A":
            cards = [cardA1.cardType, cardA2.cardType];
            break;
          case "B":
            cards = [cardB1.cardType, cardB2.cardType];
            break;
          default:
            break;
        }
        cacheRef.current.alert("", "確定使用此組牌卡？", [
          { text: "取消", type: "cancel" },
          {
            text: "確定",
            onPress: () => {
              submitCardsFn({
                variables: {
                  roomId: Number(roomId),
                  role,
                  cards,
                },
              });
            },
            type: "ok",
          },
        ]);
      }
    },
    [locked, reset, submitCardsFn]
  );

  return (
    <>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        flex={1}
        style={{
          backgroundImage: `url("${battleAssets[1]?.url}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box style={{ width: "100%", aspectRatio: 1182 / 1890, zIndex: 1 }}>
          <img
            src={battleAssets[2]?.url}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            alt="islands"
          />
        </Box>
        <Box
          position="absolute"
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          zIndex={2}
        >
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="space-evenly"
            className={classes.topBlockLayout}
          >
            <Box
              style={{
                height: "100%",
                maxHeight: (maxHeight - 8) / 2,
              }}
            >
              <Box className={classes.cardImg}>
                <FieldCache
                  name={["role", "cardA1", "cardB1"]}
                  render={(watchedQuery) => {
                    const [role, cardA1, cardB1] = watchedQuery;
                    const card1 =
                      role === "A" ? cardA1 : role === "B" ? cardB1 : undefined;
                    if (card1?.url) {
                      return (
                        <img
                          src={card1.url}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          alt="selected_card1"
                        />
                      );
                    }
                    return (
                      <Box
                        height="100%"
                        width="100%"
                        style={{ backgroundColor: "#000" }}
                      />
                    );
                  }}
                />
              </Box>
            </Box>
            <Box
              style={{
                height: "100%",
                maxHeight: (maxHeight - 8) / 2,
              }}
            >
              <Box className={classes.cardImg}>
                <FieldCache
                  name={["role", "cardA2", "cardB2"]}
                  render={(watchedQuery) => {
                    const [role, cardA2, cardB2] = watchedQuery;
                    const card2 =
                      role === "A" ? cardA2 : role === "B" ? cardB2 : undefined;
                    if (card2?.url) {
                      return (
                        <img
                          src={card2.url}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          alt="selected_card2"
                        />
                      );
                    }
                    return (
                      <Box
                        height="100%"
                        width="100%"
                        style={{ backgroundColor: "#000" }}
                      />
                    );
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" flex={2}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              flex={1}
              className={classes.bottomBlockLayout}
            >
              <CardsBlock />
              <Box className={classes.battleButtonLayout}>
                <Button
                  className={classes.battleButton}
                  size="large"
                  variant="outlined"
                  onClick={handleSubmit(_submitCards)}
                  disabled={submitCardsLoading}
                  fullWidth
                >
                  <Typography className={classes.battleButtonText}>
                    {locked ? "返回首頁" : "確定"}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

/** - 卡片區域 */
const CardsBlock = memo(function CardsBlock() {
  const classes = useStyles();
  const { battleAssets, locked } = useAtomValue(
    selectAtom(
      competitionsStatusAtom,
      useCallback(
        (state) => ({
          battleAssets: state.battleAssets,
          locked: state.locked,
        }),
        []
      ),
      deepEqual
    )
  );
  const setCompetitionsStatus = useSetAtom(competitionsStatusAtom);
  const { getValues, setValue } = useFormContext();
  const [maxHeight, setMaxHeight] = useState(0);
  const [renderFirst, setRenderFirst] = useState(false);
  const cacheRef = useRef({
    setCompetitionsStatus: () => {},
  });
  useEffect(() => {
    cacheRef.current.setCompetitionsStatus = setCompetitionsStatus;
  }, [setCompetitionsStatus]);
  useEffect(() => {
    setRenderFirst(true);
  }, []);
  const handleResize = useCallback(() => {
    cacheRef.current.setCompetitionsStatus((v) => {
      v.maxHeight = 0;
    });
    setMaxHeight(0);
    const playerElement = document.getElementById("cardLayout");
    const playerHeight = playerElement.clientHeight;
    setMaxHeight(playerHeight);
    cacheRef.current.setCompetitionsStatus((v) => {
      v.maxHeight = playerHeight;
    });
  }, []);
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [renderFirst, handleResize]);
  return (
    <Box
      id="cardLayout"
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="center"
      className={classes.cardLayout}
    >
      {maxHeight > 0 && (
        <Grid container>
          {battleAssets.slice(5, 11).map((item, index) => (
            <Grid
              key={item.url}
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                padding: "8px",
                maxHeight: (maxHeight - 8) / 2,
              }}
            >
              <Box className={classes.cardImg}>
                <ButtonBase
                  onClick={() => {
                    const role = getValues("role");
                    const key1 =
                      role === "A" ? "cardA1" : role === "B" ? "cardB1" : "";
                    const key2 =
                      role === "A" ? "cardA2" : role === "B" ? "cardB2" : "";
                    switch (index) {
                      case 0:
                      case 1:
                      case 2:
                        setValue(key1, item);
                        break;
                      case 3:
                      case 4:
                      case 5:
                        setValue(key2, item);
                        break;
                      default:
                        break;
                    }
                  }}
                  disabled={locked}
                >
                  <img
                    src={item.url}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    alt={`card${index + 1}`}
                  />
                </ButtonBase>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
});
