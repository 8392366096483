/** - 卡牌列舉 */
export const battleCardEnum = {
  COURAGE: "勇氣",
  PERSEVERANCE: "毅力",
  LEARNING: "學習",
  APTITUDE: "天賦",
  BACKGROUND: "身世",
  LUCK: "運氣",
};
/** - 卡牌列表 */
export const battleCardList = [
  { label: "勇氣", value: "COURAGE" },
  { label: "毅力", value: "PERSEVERANCE" },
  { label: "學習", value: "LEARNING" },
  { label: "天賦", value: "APTITUDE" },
  { label: "身世", value: "BACKGROUND" },
  { label: "運氣", value: "LUCK" },
];

export const firstBattleCardList = [
  { label: "勇氣", value: "COURAGE" },
  { label: "毅力", value: "PERSEVERANCE" },
  { label: "學習", value: "LEARNING" },
];

export const secondBattleCardList = [
  { label: "天賦", value: "APTITUDE" },
  { label: "身世", value: "BACKGROUND" },
  { label: "運氣", value: "LUCK" },
];
