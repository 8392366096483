import React, { useState } from "react";
// material-ui
import { Box, makeStyles, Container, Grid } from "@material-ui/core";
// react-router-dom
import { useHistory } from "react-router-dom";
// apollo
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

// component
import { useAlert } from "../component/Alert";
import { PasswordWordInput, TextInput } from "../component/Form";
import Button from "../component/Button";
import { CardContent } from "../component/Card";
import LoadingFloating from "../component/LoadingFloating";
import Text from "../component/Text";
// zustand
import useTokenStore from "../zustand/useTokenStore";

const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      success
      message
      member {
        id
        mobile
        token
      }
    }
  }
`;

const administrators = [1, 886, 1282, 1285, 3725, 4441, 4590, 4914, 8000];
const administratorsMobile = [
  "0910647227",
  "0937079906",
  "0956318020",
  "0978608905",
  "0916614141",
  "0983181101",
  "0926998902",
  "0922788005",
  "0987121768",
  "0909200116",
  "0937731313",
  "0920646377",
  "0987667878",
  "0917730556",
];

export default function LoginPage() {
  const Alert = useAlert();
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const useStyles = makeStyles((theme) => ({
    title: {
      color: theme.palette.primary.main,
    },
    imageBackgroung: {
      height: "100%",
      width: "100%",
      position: "absolute",
      zIndex: -1,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  }));
  const classes = useStyles();
  function _login() {
    if (!username) {
      Alert.alert("通知", "帳號未填寫！！", [
        {
          text: "確定",
          type: "ok",
        },
      ]);
      return null;
    }

    if (!password) {
      Alert.alert("通知", "密碼未填寫！！", [
        {
          text: "確定",
          type: "ok",
        },
      ]);
      return null;
    }
    login();
  }
  const [login, { loading }] = useMutation(LOGIN, {
    variables: {
      username,
      password,
    },
    onCompleted({ login }) {
      if (
        login.success &&
        (administrators.includes(login.member.id) ||
          administratorsMobile.includes(login.member.mobile))
      ) {
        const token = login.member.token;
        useTokenStore.getState().setConsoleToken(token);
        history.replace("/console/product");
        return Alert.alert("通知", `登入成功`, [{ text: "確認", type: "ok" }]);
      } else {
        return Alert.alert("通知", "登入失敗，帳號或密碼錯誤！", [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });
  return (
    <Box
      display="flex"
      alignItems="center"
      height="100vh"
      width="100%"
      position="relative"
    >
      <LoadingFloating loading={loading} />
      <Container className={classes.container} maxWidth="xs">
        <Grid container justify="center">
          <Grid container item>
            <CardContent>
              <Grid container spacing={2}>
                <Grid container item direction="column">
                  <Text type="h3" className={classes.title}>
                    路遙圓創有限公司
                  </Text>
                  <Text type="h3" className={classes.title}>
                    管理後台
                  </Text>
                </Grid>
                <Grid container item>
                  <TextInput
                    value={username}
                    onChange={setUsername}
                    label="帳號"
                    fullWidth
                  />
                </Grid>
                <Grid container item>
                  <PasswordWordInput
                    value={password}
                    onChange={setPassword}
                    label="密碼"
                    placeholder="長度至少8碼"
                    fullWidth
                  />
                </Grid>
                <Grid container item>
                  <Button
                    disabled={false}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onPress={_login}
                  >
                    登入
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
