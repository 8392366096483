import { useEffect, useRef, useState, useCallback, useMemo, memo } from "react";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { debounce } from "throttle-debounce";
import { deepEqual } from "fast-equals";
// apollo
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
// jotai
import { atom, useAtom, useAtomValue } from "jotai";
import withJotai from "../jotai/withJotai";
// NOTE 工具
import useSearchParams from "../hooks/useSearchParams";
// 組件
import LoadingModal from "../component/LoadingModal";
import { useAlert } from "../component/Alert";
// NOTE zustand
import useGameStore from "../zustand/useGameStore";
// css
import "./PVPRaid/index.css";

// SECTION apollo
// NOTE 確認遊戲密碼
/** - 確認遊戲密碼 */
const CHECK_GAME_PASSWORD = gql`
  mutation checkGamePassword($password: String) {
    checkGamePassword(password: $password) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;
// NOTE 小遊戲設定
const GET_GAME_VIDEOS = gql`
  query gameConfig {
    gameConfig {
      id
      # "主影片網址"
      mainVideoUrl
      # "遊戲結果"
      gameResults {
        # "結果影片"
        resultVideoUrl
      }
    }
  }
`;
// NOTE 遊戲設定
const GET_GAME_CONFIG = gql`
  query gameConfig {
    gameConfig {
      id
      # "遊戲結果"
      gameResults {
        # "ID"
        id
        # "機率"
        rate
        # "次數"
        amount
      }
    }
  }
`;
// NOTE 更新遊戲結果次數
const UPDATE_GAME_RESULT_AMOUNT = gql`
  mutation updateGameResultAmount($gameResultId: Int!) {
    updateGameResultAmount(gameResultId: $gameResultId) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;
// !SECTION

/** - 遊戲狀態 */
const statusAtom = atom("beforeStarting");

export default withJotai(function MiniGamePage() {
  return (
    <>
      <Helmet>
        <title>小遊戲｜路遙圓創</title>
      </Helmet>
      <WorldFootball />
    </>
  );
});
// ANCHOR 世足外框
function WorldFootball() {
  const useStyles = makeStyles({
    outside: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
    },
    bg: {
      height: "100vh",
      width: "100%",
      backgroundColor: "rgba(0,0,0)",
      overflowY: "hidden",
    },
  });
  const classes = useStyles();
  const { searchParamsObject, createQueryString } = useSearchParams();
  const searchParamsObjectRef = useRef();
  const writeGamePassword = useGameStore(
    useCallback((state) => state.writeGamePassword, [])
  );
  useEffect(() => {
    if (!deepEqual(searchParamsObjectRef.current, searchParamsObject)) {
      searchParamsObjectRef.current = searchParamsObject;
      if (searchParamsObject.token) {
        writeGamePassword(searchParamsObject.token);
        createQueryString({ token: "" });
      }
    }
  }, [searchParamsObject, writeGamePassword, createQueryString]);
  return (
    <Box className={`${classes.outside}`}>
      <Box className={classes.bg}>
        <GameAnimation />
      </Box>
    </Box>
  );
}
// ANCHOR 陣列洗牌亂數
function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
// ANCHOR 遊戲影片
function GameAnimation() {
  const { notice } = useAlert();
  const token = useGameStore(useCallback((state) => state.gamePassword, []));
  const [status, setStatus] = useAtom(statusAtom);
  const [open, setOpen] = useState(false);
  const handleRef = useRef({
    notice: () => {},
    updateGameResultAmountFn: () => {},
  });
  const allowRenderRef = useRef(false);
  const renderFirstRef = useRef(true);
  const renderSecondRef = useRef(false);
  const gameIsEndRef = useRef(false);

  useEffect(() => {
    handleRef.current.notice = notice;
  }, [notice]);

  const [
    getGameConfigVideos,
    { data: miniGameConfigData, loading: miniGameConfigLoading },
  ] = useLazyQuery(GET_GAME_VIDEOS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ gameConfig }) {
      setTimeout(() => {
        if (gameConfig) {
          allowRenderRef.current = true;
          return;
        }
      }, 0);
    },
    onError() {
      return null;
    },
  });

  const [checkGamePasswordFn, { loading: checkGamePasswordLoading }] =
    useMutation(CHECK_GAME_PASSWORD, {
      onCompleted({ checkGamePassword: { success, message } }) {
        if (success) {
          getGameConfigVideos();
        } else if (message) {
          handleRef.current.notice(message);
        }
      },
      onError() {
        return null;
      },
    });
  /** - 延遲Query */
  const debounceOnChange = useMemo(
    () =>
      debounce(1000, async ({ token: currentToken }) => {
        checkGamePasswordFn({ variables: { password: currentToken } });
      }),
    [checkGamePasswordFn]
  );
  useEffect(() => {
    debounceOnChange({ token });
  }, [token, debounceOnChange]);

  const gameConfig = useMemo(
    () => miniGameConfigData?.gameConfig,
    [miniGameConfigData]
  );

  const switchGame = useCallback(() => {
    if (!renderFirstRef.current) {
      if (renderSecondRef.current) {
        const hasDialog = document.getElementById("letterOfAcceptance");
        if (
          status === "beforeStarting" &&
          !hasDialog &&
          allowRenderRef.current
        ) {
          setOpen(true);
        }
        if (gameIsEndRef.current) {
          gameIsEndRef.current = false;
          renderSecondRef.current = false;
          setStatus("beforeStarting");
          return null;
        }
      } else {
        renderSecondRef.current = true;
      }
    } else {
      renderFirstRef.current = false;
    }
  }, [status, setStatus]);
  useEffect(() => {
    switchGame();
    window.addEventListener("click", switchGame);
    return () => window.removeEventListener("click", switchGame);
  }, [switchGame]);
  const [updateGameResultAmountFn] = useMutation(UPDATE_GAME_RESULT_AMOUNT, {
    onError() {
      return null;
    },
  });
  useEffect(() => {
    handleRef.current.updateGameResultAmountFn = updateGameResultAmountFn;
  }, [updateGameResultAmountFn]);
  const _onClose = useCallback(
    (e, initialArray) => {
      if (e === "mutation") {
        const gameResult = shuffle(initialArray)[0];
        if (gameResult) {
          handleRef.current.updateGameResultAmountFn({
            variables: { gameResultId: gameResult.id },
          });
          setStatus(gameResult.index);
          setOpen(false);
          return;
        }
        handleRef.current.notice("獎品已全數抽完。");
      }
      setOpen(false);
    },
    [setStatus]
  );
  const _renderGame = useMemo(() => {
    if (typeof gameConfig === "object" && gameConfig) {
      if (status === "beforeStarting") {
        return <WorldFootballVideo url={gameConfig.mainVideoUrl} loop />;
      }
      const resultVideoUrl =
        gameConfig.gameResults[Number(status)]?.resultVideoUrl;
      if (resultVideoUrl) {
        return (
          <WorldFootballVideo
            url={resultVideoUrl}
            onEnded={() => {
              gameIsEndRef.current = true;
            }}
          />
        );
      }
      return null;
    } else {
      return null;
    }
  }, [status, gameConfig]);

  if (miniGameConfigLoading) {
    return (
      <Box
        display="flex"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
  return (
    <>
      <LoadingModal loading={checkGamePasswordLoading} />
      <LetterOfAcceptance open={open} onClose={_onClose} />
      {_renderGame}
    </>
  );
}
// ANCHOR 同意書浮層
function LetterOfAcceptance({ open, onClose = () => {} }) {
  const [getGameConfig, { loading: gameConfigLoading }] = useLazyQuery(
    GET_GAME_CONFIG,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ gameConfig }) {
        if (gameConfig) {
          setTimeout(() => {
            const initialArray = [];
            gameConfig.gameResults.forEach((item, index) => {
              if (
                typeof item.rate === "number" &&
                typeof item.amount === "number" &&
                item.amount > 0
              ) {
                for (let i = 0; i < item.rate; i++) {
                  initialArray.push({ ...item, index });
                }
              }
            });
            onClose("mutation", initialArray);
          }, 0);
        }
      },
      onError() {
        return null;
      },
    }
  );
  const _agree = useCallback(() => getGameConfig(), [getGameConfig]);
  return (
    <Dialog open={open} maxWidth="sm" fullWidth id="letterOfAcceptance">
      <DialogTitle>同意書</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          我同意進行動畫抽獎遊戲，並接受遊戲結果，且提供指定商品卡片數量給予工作人員審查記號。
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item style={{ paddingRight: "10px" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={onClose}
              disabled={gameConfigLoading}
            >
              不同意
            </Button>
          </Grid>
          <Grid item style={{ paddingLeft: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={_agree}
              disabled={gameConfigLoading}
            >
              同意
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
// ANCHOR 影片
function WorldFootballVideo({ url, loop = false, onEnded }) {
  const playerRef = useRef();
  const [duration, setDuration] = useState();

  useEffect(() => {
    function videoPlayer() {
      document.querySelector("video").muted = false;
    }
    window.addEventListener("click", videoPlayer);
    return () => window.removeEventListener("click", videoPlayer);
  }, []);

  return (
    <Box
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
      }}
    >
      <SkipButton playerRef={playerRef} duration={duration} />
      <ReactPlayer
        id="video"
        ref={playerRef}
        playing
        loop={loop}
        width={"100%"}
        height={"100%"}
        url={url}
        muted
        playsinline
        onEnded={onEnded}
        onReady={() => setDuration(playerRef.current?.getDuration?.())}
      />
    </Box>
  );
}

/** - skip按鈕 */
const SkipButton = memo(function SkipButton({ playerRef, duration }) {
  const useStyles = makeStyles({
    dialogContainer: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      zIndex: 1,
    },
    dialogButton: {
      position: "absolute",
      top: 16,
      right: 16,
    },
  });
  const classes = useStyles();
  /** - 遊戲狀態 */
  const status = useAtomValue(statusAtom);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [skipped, setSkipped] = useState(false);
  /** - 計算撥放器大小 */
  const handleResize = useCallback(() => {
    const playerElement = playerRef.current.getInternalPlayer();
    const playerWidth = playerElement.clientWidth;
    const playerHeight = playerElement.clientHeight;
    /** - 以寬度為基底計算16:9的高度比例 */
    const getProportion = (playerWidth / 16).toFixed(2);
    const calculatePlayerHeight = Number(getProportion) * 9;
    /** - 如果計算出來的高度高於瀏覽器高度 */
    if (calculatePlayerHeight > playerHeight) {
      /** - 以高度為基底計算16:9的寬度比例 */
      const getProportion = (playerHeight / 9).toFixed(2);
      const calculatePlayerWidth = Number(getProportion) * 16;
      setHeight(playerHeight);
      setWidth(calculatePlayerWidth);
    } else {
      setHeight(calculatePlayerHeight);
      setWidth(playerWidth);
    }
  }, [playerRef]);

  /** - 有取得影片秒數就調用一次，否則第一次進入無法取得 */
  useEffect(() => {
    if (duration) {
      handleResize();
    }
  }, [duration, handleResize]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (status === "beforeStarting") {
      setSkipped(false);
    }
  }, [status]);

  return (
    <Box className={classes.dialogContainer}>
      <Box
        style={{
          position: "relative",
          height,
          width,
        }}
      >
        {status !== "beforeStarting" && !skipped && (
          <Box className={classes.dialogButton}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                playerRef.current?.seekTo?.(19);
                setSkipped(true);
              }}
            >
              Skip
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
});
