import React, { useCallback, useEffect, useState, memo } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  InputAdornment,
  FormHelperText,
  Box,
  ButtonBase,
  IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  Controller,
  useForm,
  FormProvider,
  useFieldArray,
} from "react-hook-form";
// apollo
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
// react-player
import ReactPlayer from "react-player";

// component
import { useAlert } from "../../component/Alert";
import { Card, CardContent, CardActions } from "../../component/Card";
import {
  CustomNumberInput,
  CustomTextField,
  InputTitle,
} from "../../component/Form";
import Button from "../../component/Button";
import LoadingModal from "../../component/LoadingModal";
import AdaptiveImage from "../../component/AdaptiveImage";
import CustomUploadFiles from "../../component/CustomUploadFiles";

// SECTION apollo
// NOTE 小遊戲設定
const GET_GAME_CONFIG = gql`
  query gameConfig {
    gameConfig {
      id
      # "主影片網址"
      mainVideoUrl
      # "遊戲結果"
      gameResults {
        # "結果影片"
        resultVideoUrl
        # "機率"
        rate
        # "次數"
        amount
      }
      # "密碼"
      password
    }
  }
`;
// NOTE 儲存小遊戲設定
const SAVE_GAME_CONFIG = gql`
  mutation saveGameConfig($gameConfigInput: GameConfigInput!) {
    saveGameConfig(gameConfigInput: $gameConfigInput) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;
// !SECTION

export default function ShootingGameForm() {
  const { notice } = useAlert();
  const form = useForm({
    defaultValues: {
      mainVideoUrl: "",
      gameResults: [
        {
          resultVideoUrl: "",
          rate: "",
          amount: 0,
        },
        {
          resultVideoUrl: "",
          rate: "",
          amount: 0,
        },
      ],
      password: "",
    },
  });
  const { handleSubmit, reset } = form;
  const [realPassword, setRealPassword] = useState("");
  const {
    data: gameConfigData,
    loading: gameConfigLoading,
    refetch,
  } = useQuery(GET_GAME_CONFIG, {
    fetchPolicy: "network-only",
    onError() {
      return null;
    },
  });
  useEffect(() => {
    if (gameConfigData?.gameConfig) {
      const { __typename, id, password, gameResults, ...otherGameConfig } =
        gameConfigData.gameConfig;
      setRealPassword(password);
      const newGameResults = gameResults.map((item) => ({
        resultVideoUrl: item.resultVideoUrl || "",
        rate: item.rate || "",
        amount: item.amount || 0,
      }));
      reset({ ...otherGameConfig, gameResults: newGameResults, password });
    }
  }, [gameConfigData, reset]);
  const [saveGameConfigFn, { loading: saveGameConfigLoading }] = useMutation(
    SAVE_GAME_CONFIG,
    {
      onCompleted({ saveGameConfig }) {
        if (saveGameConfig.success) {
          notice("儲存成功");
          refetch();
        } else if (saveGameConfig.message) {
          notice(saveGameConfig.message);
        }
      },
      onError() {
        return null;
      },
    }
  );
  const _saveGameConfig = useCallback(
    ({ gameResults, password, ...otherDatas }) => {
      const rates = gameResults.map((item) => item.rate).map(Number);
      const totalRate = rates.reduce((sum, rate) => sum + (rate || 0), 0);

      if (totalRate !== 100) {
        return notice("結果機率不等於100。");
      }
      const newGameResults = [];

      gameResults.forEach((item) => {
        if (item.rate) {
          newGameResults.push({
            resultVideoUrl: item.resultVideoUrl || "",
            rate: Number(item.rate) || 0,
            amount: Number(item.amount) || 0,
          });
        }
      });

      const gameConfigInput = {
        ...otherDatas,
        gameResults: newGameResults,
        password: password || null,
      };

      saveGameConfigFn({ variables: { gameConfigInput } });
    },
    [saveGameConfigFn, notice]
  );
  const getGameUrl = useCallback((password) => {
    const port = window.location.port ? `:${window.location.port}` : "";
    const passwordUrl = password ? `?token=${password}` : "";
    const domain =
      window.location.protocol +
      "//" +
      window.location.hostname +
      port +
      "/shooting-game" +
      passwordUrl;
    return domain;
  }, []);
  if (gameConfigLoading) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  return (
    <FormProvider {...form}>
      <Card>
        <LoadingModal loading={saveGameConfigLoading} />
        <CardContent>
          <Typography>小遊戲設定</Typography>
        </CardContent>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>小遊戲網址：{getGameUrl(realPassword)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="password"
                    render={({ field }) => (
                      <div>
                        <InputTitle label="密碼" />
                        <CustomTextField {...field} fullWidth />
                      </div>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="mainVideoUrl"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="待機動畫(影片)" required />
                    <AdaptiveImage ratio="75%">
                      <CustomUploadFiles
                        name="mainVideoUrl"
                        multiple={false}
                        accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                        type="video"
                        render={() => (
                          <ReactPlayer
                            url={field?.value}
                            width={"100%"}
                            height="100%"
                            controls
                          />
                        )}
                        uploadIconStyle={{
                          border: `1px solid ${
                            Boolean(error) ? "red" : "#9c9c9c"
                          }`,
                          borderRadius: "5px",
                        }}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.location);
                        }}
                      />
                    </AdaptiveImage>
                    {error?.message && (
                      <FormHelperText error>{error.message}</FormHelperText>
                    )}
                  </div>
                )}
              />
            </Grid>
            <GameResultsField />
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            onPress={handleSubmit(_saveGameConfig)}
            variant="contained"
            color="primary"
          >
            儲存
          </Button>
        </CardActions>
      </Card>
    </FormProvider>
  );
}

/** -  */
const GameResultsField = memo(function GameResultsField() {
  const { fields, append, remove } = useFieldArray({ name: "gameResults" });
  const addResult = useCallback(() => {
    append({
      resultVideoUrl: "",
      rate: "",
      amount: 0,
    });
  }, [append]);
  return (
    <>
      {fields.map((item, index) => (
        <Grid key={item.id} container item xs={12} sm={4}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: 16,
            }}
          >
            <Controller
              name={`gameResults.${index}.resultVideoUrl`}
              rules={{
                required: "必填欄位 !",
              }}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <Box display="flex" justifyContent="space-between">
                    <InputTitle label={`結果${index + 1}影片`} required />
                    {index !== 0 && index !== 1 && (
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => remove(index)}
                      >
                        <CancelIcon />
                      </IconButton>
                    )}
                  </Box>
                  <AdaptiveImage ratio="75%">
                    <CustomUploadFiles
                      name={`gameResults.${index}.resultVideoUrl`}
                      multiple={false}
                      accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                      type="video"
                      render={() => (
                        <ReactPlayer
                          url={field?.value}
                          width={"100%"}
                          height="100%"
                          controls
                        />
                      )}
                      uploadIconStyle={{
                        border: `1px solid ${
                          Boolean(error) ? "red" : "#9c9c9c"
                        }`,
                        borderRadius: "5px",
                      }}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.location);
                      }}
                    />
                  </AdaptiveImage>
                  {error?.message && (
                    <FormHelperText error>{error.message}</FormHelperText>
                  )}
                </div>
              )}
            />
            <Controller
              name={`gameResults.${index}.rate`}
              rules={{
                required: "必填欄位",
                validate: (e) => {
                  if (!isNaN(e) && (Number(e) < 0 || Number(e) > 100)) {
                    return "只允許 0～100 的整數";
                  }
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomNumberInput
                  {...field}
                  label={`機率`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      textAlign: "end",
                      paddingY: "10px",
                    },
                  }}
                  inputProps={{ maxLength: 3 }}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name={`gameResults.${index}.amount`}
              rules={{
                required: "必填欄位",
                validate: (e) => {
                  if (!isNaN(e) && Number(e) < 0) {
                    return "只允許 0 以上的整數";
                  }
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <CustomNumberInput
                  {...field}
                  label={`次數`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">次</InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      textAlign: "end",
                      paddingY: "10px",
                    },
                  }}
                  inputProps={{ maxLength: 5 }}
                  error={Boolean(error)}
                  helperText={error?.message}
                />
              )}
            />
          </Box>
        </Grid>
      ))}
      <Grid container item xs={12} sm={4}>
        <ButtonBase
          style={{ height: "100%", width: "100%" }}
          onClick={addResult}
        >
          <AdaptiveImage ratio="100%">
            <Box
              display="flex"
              height="100%"
              alignItems="center"
              justifyContent="center"
              border="1px solid"
              borderRadius="5px"
            >
              <Typography>新增結果</Typography>
            </Box>
          </AdaptiveImage>
        </ButtonBase>
      </Grid>
    </>
  );
});
