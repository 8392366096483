import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
  ButtonBase,
  Grid,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { deepEqual } from "fast-equals";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { gql, useApolloClient, useLazyQuery } from "@apollo/client";
import { debounce } from "throttle-debounce";
// NOTE 工具
import useSearchParams from "../hooks/useSearchParams";
// NOTE 組件
import { useAlert } from "../component/Alert";
import AdaptiveImage from "../component/AdaptiveImage";
import Image from "../component/Image";
import withJotai from "../jotai/withJotai";
// NOTE zustand
import useAvatarsStore from "../zustand/useAvatarsStore";

// SECTION apollo
// NOTE 檢查路遙大頭貼密碼
/** - 檢查路遙大頭貼密碼 */
const CHECK_LUYAO_PROFILE_PICTURE_PASSWORD = gql`
  mutation checkLuyaoProfilePicturePassword(
    $pictureId: Int!
    $password: String!
  ) {
    checkLuyaoProfilePicturePassword(
      pictureId: $pictureId
      password: $password
    ) {
      success
      message
    }
  }
`;
// NOTE 路遙大頭貼
/** - 路遙大頭貼 */
const GET_LUYAO_PROFILE_PICTURE = gql`
  query luyaoProfilePicture($id: Int!) {
    luyaoProfilePicture(id: $id) {
      # "ID"
      id
      # "名稱"
      name
      # "檔案"
      file {
        location
      }
    }
  }
`;
// NOTE 領取路遙大頭貼
/** - 領取路遙大頭貼 */
const RETRIEVAL_LUYAO_PROFILE_PICTURE = gql`
  mutation retrievalLuyaoProfilePicture($mobile: String!, $pictureId: Int!) {
    retrievalLuyaoProfilePicture(mobile: $mobile, pictureId: $pictureId) {
      success
      message
    }
  }
`;
// !SECTION

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#fff",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 32,
    paddingRight: 32,
  },
  numberButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  clearButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
/** - 狀態`atom` */
const statusAtom = atom("UNACCALIMED");
const profileLocationAtom = atom("");
const loadingStatusAtom = atom("loading");
/** - 大頭貼領取 */
function AvatarPage() {
  const { id } = useParams();
  const { searchParamsObject, createQueryString } = useSearchParams();
  const searchParamsObjectRef = useRef();
  const writeAvatar = useAvatarsStore(
    useCallback((state) => state.writeAvatar, [])
  );
  useEffect(() => {
    if (!deepEqual(searchParamsObjectRef.current, searchParamsObject)) {
      searchParamsObjectRef.current = searchParamsObject;
      if (searchParamsObject.token) {
        writeAvatar({ id, token: searchParamsObject.token });
        createQueryString({ token: "" });
      }
    }
  }, [id, searchParamsObject, writeAvatar, createQueryString]);
  return (
    <>
      <AvatarLayout />
    </>
  );
}
export default memo(withJotai(AvatarPage));

/** - Avatar`Layout` */
function AvatarLayout() {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" disableGutters className={classes.container}>
      <Box flex={1}>
        <AvatarContent />
      </Box>
    </Container>
  );
}

/** - Avatar`Content` */
function AvatarContent() {
  const { notice } = useAlert();
  const { id } = useParams();
  const classes = useStyles();
  const client = useApolloClient();
  const avatars = useAvatarsStore(useCallback((state) => state.avatars, []));
  const status = useAtomValue(statusAtom);
  const setProfileLocation = useSetAtom(profileLocationAtom);
  const [loadingStatus, setLoadingStatus] = useAtom(loadingStatusAtom);
  const { gap } = useGetFontSize();
  const handleRef = useRef({
    notice,
    setProfileLocation,
    setLoadingStatus,
    getLuyaoProfilePicture: () => {},
  });
  const form = useForm({
    defaultValues: {
      mobile: "",
    },
    mode: "onChange",
  });
  const token = useMemo(
    () => avatars.find((item) => item.id === id)?.token,
    [id, avatars]
  );
  const [getLuyaoProfilePicture, { data: luyaoProfilePictureData }] =
    useLazyQuery(GET_LUYAO_PROFILE_PICTURE, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ luyaoProfilePicture }) {
        if (luyaoProfilePicture) {
          handleRef.current.setLoadingStatus?.("success");
          const { file } = luyaoProfilePicture;
          handleRef.current.setProfileLocation?.(file?.location);
        }
      },
      onError(error) {
        handleRef.current.setLoadingStatus?.("faild");
        handleRef.current.notice?.(
          `${error.message.replace("GraphQL error: ", "")}`
        );
        return null;
      },
    });
  useEffect(() => {
    handleRef.current.notice = notice;
    handleRef.current.setProfileLocation = setProfileLocation;
    handleRef.current.setLoadingStatus = setLoadingStatus;
    handleRef.current.getLuyaoProfilePicture = getLuyaoProfilePicture;
  }, [notice, setProfileLocation, setLoadingStatus, getLuyaoProfilePicture]);
  const luyaoProfilePictureName =
    luyaoProfilePictureData?.luyaoProfilePicture?.name || "";
  /** - 延遲Query */
  const debounceOnChange = useMemo(
    () =>
      debounce(1000, async ({ id: currentId, token: currentToken }) => {
        try {
          handleRef.current.setLoadingStatus?.("loading");
          const pictureId = Number(currentId);
          const { data } = await client.mutate({
            mutation: CHECK_LUYAO_PROFILE_PICTURE_PASSWORD,
            variables: { pictureId, password: currentToken ?? "" },
          });
          if (data?.checkLuyaoProfilePicturePassword) {
            const { success, message } = data.checkLuyaoProfilePicturePassword;
            if (success) {
              handleRef.current.getLuyaoProfilePicture({
                variables: {
                  id: pictureId,
                },
              });
            } else if (message) {
              handleRef.current.setLoadingStatus?.("faild");
              handleRef.current.notice?.(message);
            }
          }
        } catch (error) {
          handleRef.current.setLoadingStatus?.("faild");
          handleRef.current.notice?.(
            `${error.message.replace("GraphQL error: ", "")}`
          );
        }
      }),
    [client]
  );
  useEffect(() => {
    debounceOnChange({ id, token });
  }, [id, token, debounceOnChange]);

  if (loadingStatus === "loading") {
    return (
      <Box
        style={{
          display: "flex",
          flex: 1,
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  } else if (loadingStatus === "faild") {
    return (
      <Box
        style={{
          display: "flex",
          flex: 1,
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">找不到大頭貼</Typography>
      </Box>
    );
  } else if (loadingStatus === "success") {
    return (
      <FormProvider {...form}>
        <Box className={`game-area ${classes.contentBox}`} style={{ gap }}>
          <BannerLogoArea />
          <AvatarArea />
          <AvatarNarrate name={luyaoProfilePictureName} status={status} />
          {status === "UNACCALIMED" && <MobileFiels status={status} />}
          <ButtonsArea />
          <ContentIllustrate />
          <FooterContent />
        </Box>
      </FormProvider>
    );
  }
  return null;
}

/** - Banner`Logo` */
const BannerLogoArea = memo(function BannerLogoArea() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "40%",
        mx: "auto",
      }}
    >
      <AdaptiveImage ratio="13.8%">
        <Image
          style={{
            width: "100%",
            height: "100%",
          }}
          src={"/img/avatar/Logo.png"}
          resizeMode="cover"
        />
      </AdaptiveImage>
    </Box>
  );
});

/** - Avatar */
const AvatarArea = memo(function AvatarArea() {
  const profileLocation = useAtomValue(profileLocationAtom);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "60%",
        mx: "auto",
        borderRadius: 999,
      }}
    >
      <AdaptiveImage ratio="100%">
        <Image
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 999,
          }}
          src={profileLocation}
          resizeMode="cover"
        />
      </AdaptiveImage>
    </Box>
  );
});

function useGetFontSize() {
  const isPC = useMediaQuery("(max-width:1280px)");
  const isDownPC = useMediaQuery("(max-width:1160px)");
  const isTable = useMediaQuery("(max-width:1060px)");
  const isMobile = useMediaQuery("(max-width:700px)");
  const isDownMobile = useMediaQuery("(max-width:500px)");
  const fontSize = useMemo(() => {
    if (isDownMobile) {
      return {
        titleFontSize: "1rem",
        contentFontSize: "0.8rem",
        textFieleFontSize: "0.8rem",
        illustrateFontSize: "0.8rem",
        height: 25,
        width: 100,
        gap: 24,
        numberHeight: 25,
        numberWidth: 25,
        clearHeight: 25,
        clearWidth: 50,
      };
    } else if (isMobile) {
      return {
        titleFontSize: "2rem",
        contentFontSize: "1rem",
        textFieleFontSize: "1rem",
        illustrateFontSize: "1rem",
        height: 25,
        width: 100,
        gap: 24,
        numberHeight: 25,
        numberWidth: 25,
        clearHeight: 25,
        clearWidth: 50,
      };
    } else if (isTable) {
      return {
        titleFontSize: "3rem",
        contentFontSize: "2rem",
        textFieleFontSize: "1.5rem",
        illustrateFontSize: "1.3rem",
        height: 50,
        width: 200,
        gap: 40,
        numberHeight: 50,
        numberWidth: 50,
        clearHeight: 50,
        clearWidth: 100,
      };
    } else if (isDownPC) {
      return {
        titleFontSize: "3rem",
        contentFontSize: "2rem",
        textFieleFontSize: "1.5rem",
        illustrateFontSize: "1.3rem",
        height: 50,
        width: 200,
        gap: 40,
        numberHeight: 50,
        numberWidth: 50,
        clearHeight: 50,
        clearWidth: 100,
      };
    } else if (isPC) {
      return {
        titleFontSize: "3.5rem",
        contentFontSize: "2.5rem",
        textFieleFontSize: "2rem",
        illustrateFontSize: "1.5rem",
        height: 65,
        width: 250,
        gap: 56,
        numberHeight: 70,
        numberWidth: 70,
        clearHeight: 70,
        clearWidth: 130,
      };
    }
    return {
      titleFontSize: "3.5rem",
      contentFontSize: "2.5rem",
      textFieleFontSize: "2rem",
      illustrateFontSize: "1.5rem",
      height: 65,
      width: 250,
      gap: 56,
      numberHeight: 70,
      numberWidth: 70,
      clearHeight: 70,
      clearWidth: 130,
    };
  }, [isPC, isDownPC, isTable, isMobile, isDownMobile]);
  return fontSize;
}

/** - Avatar`Narrate` */
const AvatarNarrate = memo(function AvatarNarrate({ name = "", status }) {
  const { titleFontSize } = useGetFontSize();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "75%",
        mx: "auto",
        gap: 24,
      }}
    >
      <Typography
        variant="h1"
        style={{
          fontSize: titleFontSize,
          fontWeight: "bold",
          fontFamily: "Noto Serif TC",
        }}
      >
        路遙圓創官網大頭貼領取
      </Typography>
      {status === "RECEIVED" && <ReceivedSuccessfully name={name} />}
    </Box>
  );
});

const ReceivedSuccessfully = memo(function ReceivedSuccessfully({ name }) {
  const { contentFontSize } = useGetFontSize();
  return (
    <>
      <Typography
        variant="h3"
        style={{
          fontSize: contentFontSize,
          fontWeight: 500,
          fontFamily: "Noto Serif TC",
          wordBreak: "break-all",
          whiteSpace: "pre-wrap",
          textAlign: "center",
        }}
      >
        {`恭喜您成功領取${name}\n請開啟官方網站前往會員專區查看`}
      </Typography>
    </>
  );
});

const keyboardOfNumber = Array.from({ length: 10 }).map(
  (_, index) => `/img/keyboardOfNumbers/${index}.png`
);
/** - Mobile`Fiels` */
const MobileFiels = memo(function MobileFiels() {
  const classes = useStyles();
  const {
    textFieleFontSize,
    numberHeight,
    numberWidth,
    clearHeight,
    clearWidth,
  } = useGetFontSize();
  const { setValue, getValues } = useFormContext();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "75%",
        mx: "auto",
        gap: 24,
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            name="mobile"
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                inputProps={{ readOnly: true }}
                InputProps={{
                  style: {
                    fontSize: textFieleFontSize,
                  },
                }}
                placeholder="請輸入您註冊的官網電話"
                error={error}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            style={{
              display: "flex",
              flex: 1,
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {keyboardOfNumber.map((item, index) => (
              <Box
                style={{ height: numberHeight, width: numberWidth }}
                className={classes.numberButton}
                onClick={() => {
                  const mobile = getValues("mobile");
                  const currentMobile = mobile + index.toString();
                  setValue("mobile", currentMobile);
                }}
              >
                <AdaptiveImage ratio="100%">
                  <Image
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={item}
                    resizeMode="cover"
                  />
                </AdaptiveImage>
              </Box>
            ))}
            <Box
              style={{
                height: clearHeight,
                width: clearWidth,
                backgroundImage: `url("/img/keyboardOfNumbers/clear.png")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "40% 50%",
              }}
              className={classes.clearButton}
              onClick={() => {
                const mobile = getValues("mobile");
                let currentMobile = mobile.replace(/.$/, "");
                setValue("mobile", currentMobile);
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});

/** - 按鈕區域 */
const ButtonsArea = memo(function ButtonsArea() {
  const { id } = useParams();
  const client = useApolloClient();
  const { notice } = useAlert();
  const { contentFontSize, height, width } = useGetFontSize();
  const { handleSubmit, reset } = useFormContext();
  const [status, setStatus] = useAtom(statusAtom);
  const [mutateLoading, setMutateLoading] = useState(false);
  const handleRef = useRef({
    notice,
    setStatus,
    reset,
  });
  useEffect(() => {
    handleRef.current.notice = notice;
    handleRef.current.setStatus = setStatus;
    handleRef.current.reset = reset;
  }, [notice, setStatus, reset]);
  const _retrievalLuyaoProfilePicture = useCallback(
    async ({ mobile }) => {
      setMutateLoading(true);
      try {
        const { data } = await client.mutate({
          mutation: RETRIEVAL_LUYAO_PROFILE_PICTURE,
          variables: {
            pictureId: Number(id),
            mobile,
          },
        });
        setMutateLoading(false);
        if (data?.retrievalLuyaoProfilePicture) {
          const { success, message } = data.retrievalLuyaoProfilePicture;
          if (success) {
            handleRef.current.setStatus?.("RECEIVED");
            handleRef.current.reset?.();
          } else if (message) {
            handleRef.current.notice?.(message);
          }
        }
      } catch (error) {
        setMutateLoading(false);
        handleRef.current.notice?.(
          `${error.message.replace("GraphQL error: ", "")}`
        );
      }
    },
    [id, client]
  );
  const _submit = useCallback(() => {
    if (status === "UNACCALIMED") {
      handleSubmit(_retrievalLuyaoProfilePicture)();
    } else if (status === "RECEIVED") {
      handleRef.current.setStatus?.("UNACCALIMED");
    }
  }, [status, handleSubmit, _retrievalLuyaoProfilePicture]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "75%",
        mx: "auto",
        gap: 24,
      }}
    >
      <ButtonBase
        style={{ borderRadius: 8 }}
        onClick={_submit}
        disabled={mutateLoading}
      >
        <Box
          style={{
            height,
            width,
            backgroundColor: "#000",
            opacity: mutateLoading ? 0.5 : 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 16,
          }}
        >
          {mutateLoading && <CircularProgress size={24} color="secondary" />}
          <Typography
            style={{
              fontSize: contentFontSize,
              fontWeight: 500,
              fontFamily: "Noto Serif TC",
              wordBreak: "break-all",
              whiteSpace: "pre-wrap",
              textAlign: "center",
              color: "#fff",
            }}
          >
            確認
          </Typography>
        </Box>
      </ButtonBase>
    </Box>
  );
});

/** - 內容說明 */
const ContentIllustrate = memo(function ContentIllustrate() {
  const { illustrateFontSize } = useGetFontSize();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "90%",
        mx: "auto",
        gap: 16,
      }}
    >
      <Typography
        style={{
          display: "flex",
          fontSize: illustrateFontSize,
          fontFamily: "Noto Serif TC",
          color: "#8b8b8b",
        }}
      >
        *{"\u2002"}
        <Typography
          style={{
            fontSize: illustrateFontSize,
            fontFamily: "Noto Serif TC",
            color: "#8b8b8b",
          }}
        >
          此大頭貼為路遙圓創官網會員專屬。
        </Typography>
      </Typography>
      <Typography
        style={{
          display: "flex",
          fontSize: illustrateFontSize,
          fontFamily: "Noto Serif TC",
          color: "#8b8b8b",
        }}
      >
        *{"\u2002"}
        <Typography
          style={{
            fontSize: illustrateFontSize,
            fontFamily: "Noto Serif TC",
            color: "#8b8b8b",
          }}
        >
          大頭貼為每次場次限定發送，領取後可至會員中心查看大頭貼是否有新增。
        </Typography>
      </Typography>
      <Typography
        style={{
          display: "flex",
          fontSize: illustrateFontSize,
          fontFamily: "Noto Serif TC",
          color: "#8b8b8b",
        }}
      >
        *{"\u2002"}
        <Typography
          style={{
            fontSize: illustrateFontSize,
            fontFamily: "Noto Serif TC",
            color: "#8b8b8b",
          }}
        >
          官網大頭貼領取前，請確認您填入的電話是否為官網註冊帳號之電話，如無法領取，請前往官網確認或私訊路遙圓創官方社群。
        </Typography>
      </Typography>
      <Typography
        style={{
          display: "flex",
          fontSize: illustrateFontSize,
          fontFamily: "Noto Serif TC",
          color: "#8b8b8b",
        }}
      >
        *{"\u2002"}
        <Typography
          style={{
            fontSize: illustrateFontSize,
            fontFamily: "Noto Serif TC",
            color: "#8b8b8b",
          }}
        >
          大頭貼為帳號專屬頭貼，除了顯示於會員中心外，至官網『最新消息』發表與討論內容時也會同時顯示。
        </Typography>
      </Typography>
      <Typography
        style={{
          display: "flex",
          fontSize: illustrateFontSize,
          fontFamily: "Noto Serif TC",
          color: "#8b8b8b",
        }}
      >
        *{"\u2002"}
        <Typography
          style={{
            fontSize: illustrateFontSize,
            fontFamily: "Noto Serif TC",
            color: "#8b8b8b",
          }}
        >
          如有任何會員問題可詢問路遙圓創工作人員或私訊私訊路遙圓創官方社群。
        </Typography>
      </Typography>
    </Box>
  );
});

/** -  */
const FooterContent = memo(function FooterContent() {
  const { illustrateFontSize } = useGetFontSize();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "90%",
        mx: "auto",
        gap: 16,
      }}
    >
      <Typography
        style={{
          fontSize: illustrateFontSize,
          fontFamily: "Noto Serif TC",
          color: "#8b8b8b",
        }}
      >
        此為路遙圓創專屬活動，圖片與內容為路遙圓創所有，圖片僅提供路遙圓創官網會員於官網使用。
      </Typography>
    </Box>
  );
});
