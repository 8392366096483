export default function formErrorFocus(error) {
  if (Object.keys(error).length > 0) {
    const findErrorElement = (obj, prefix = "") => {
      return Object.entries(obj).flatMap(([key, value]) => {
        const fullKey = prefix ? `${prefix}.${key}` : key;
        if (value && typeof value === "object") {
          if (Array.isArray(value)) {
            // 處理數組
            return value.flatMap((_, index) =>
              findErrorElement({ [index]: value[index] }, fullKey)
            );
          } else if ("message" in value) {
            // 處理 { message: string } 類型的錯誤
            return findElementByKey(fullKey);
          } else {
            // 處理嵌套對象
            return findErrorElement(value, fullKey);
          }
        } else {
          // 處理字符串類型的錯誤
          return findElementByKey(fullKey);
        }
      });
    };

    const findElementByKey = (key) => {
      const selectors = [
        `[name="${key}"]`,
        `[name^="${key}."]`,
        `[id="${key}"]`,
        `[id^="${key}."]`,
        `[data-error-key="${key}"]`,
      ];
      for (let selector of selectors) {
        const elements = document.querySelectorAll(selector);
        if (elements.length > 0) {
          return Array.from(elements);
        }
      }
      return [];
    };

    const errorElements = findErrorElement(error);

    if (errorElements.length > 0) {
      errorElements.sort(
        (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top
      );
      const firstErrorElement = errorElements[0];
      firstErrorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      firstErrorElement.focus({ preventScroll: true });
    }
  }
}
