import React, { memo, useMemo } from "react";
import { Grid, FormHelperText, FormControl } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
// NOTE 工具
import { battleAssetTypeEnum } from "../../../utils/options/battleAssetType";
import {
  battleCardList,
  battleCardEnum,
} from "../../../utils/options/battleCard";
// NOTE 組件
import { InputTitle } from "../../../component/Form";
import AdaptiveImage from "../../../component/AdaptiveImage";
import CustomUploadFiles from "../../../component/CustomUploadFiles";

const cards = Array.from({ length: 8 }).map((_, index) => {
  if (index === 0 || index === 1) {
    return {
      type: "CARDBACK",
      url: "",
    };
  }
  const battleCard = battleCardList.find(
    (_, index2) => index2 === index - 2
  )?.value;
  return {
    type: "CARDFRONT",
    cardType: battleCard,
    url: "",
  };
});
/** - 卡牌設定 */
export default memo(function CardSettings() {
  const { setValue } = useFormContext();
  const ratio = useMemo(() => `${(4 / 3).toFixed(2) * 100}%`, []);
  return (
    <>
      {cards.map((item, index) => (
        <Grid key={"cards" + index} item xs={12} sm={6} md={4}>
          <Controller
            key={`battleAssetInputs.${index + 3}.url`}
            name={`battleAssetInputs.${index + 3}.url`}
            rules={{
              required: "必填欄位 !",
            }}
            render={({ field, fieldState: { error } }) => (
              <div>
                <InputTitle
                  label={
                    index === 0 || index === 1
                      ? `${battleAssetTypeEnum[item.type]}${index + 1}`
                      : battleCardEnum[item.cardType]
                  }
                  required
                />
                <AdaptiveImage ratio={ratio}>
                  <CustomUploadFiles
                    name={`battleAssetInputs.${index + 3}.url`}
                    multiple={false}
                    type="file"
                    accept="image/*"
                    render={() => (
                      <img
                        src={field?.value}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                        alt=""
                      />
                    )}
                    uploadIconStyle={{
                      border: `1px solid ${Boolean(error) ? "red" : "#9c9c9c"}`,
                      borderRadius: "5px",
                    }}
                    {...field}
                    onChange={(e) => {
                      if (item.cardType) {
                        setValue(
                          `battleAssetInputs.${index + 3}.cardType`,
                          item.cardType
                        );
                      }
                      setValue(
                        `battleAssetInputs.${index + 3}.type`,
                        item.type
                      );
                      field.onChange(e.location);
                    }}
                  />
                </AdaptiveImage>
                <FormControl name={`battleAssetInputs.${index + 3}.url`}>
                  {error?.message && (
                    <FormHelperText error>{error.message}</FormHelperText>
                  )}
                </FormControl>
              </div>
            )}
          />
        </Grid>
      ))}
    </>
  );
});
