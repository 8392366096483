import { Box, useTheme } from "@material-ui/core";
import { createElement } from "react";
import QRCode, { QRCodeProps } from "react-qr-code";
/**
 * @param {QRCodeProps} props
 */
export default function Qrcode({ size = 0, value = "" }) {
  const theme = useTheme();
  const spacing = 0.5;
  const currentSize = size + theme.spacing(spacing * 2);
  return (
    <Box height={currentSize} width={currentSize} p={spacing}>
      {createElement(QRCode, {
        value,
        size,
      })}
    </Box>
  );
}
