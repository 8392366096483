/** - 對戰結果列舉 */
export const battleResultEnum = {
  A_WIN: "A獲勝",
  B_WIN: "B獲勝",
  DRAW: "平手",
};
/** - 對戰結果列表 */
export const battleResultList = [
  { label: "A獲勝", value: "A_WIN" },
  { label: "B獲勝", value: "B_WIN" },
  { label: "平手", value: "DRAW" },
];
