import React, { memo, useMemo } from "react";
import { Grid, FormHelperText, FormControl } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import ReactPlayer from "react-player";
// NOTE 組件
import { InputTitle } from "../../../component/Form";
import AdaptiveImage from "../../../component/AdaptiveImage";
import CustomUploadFiles from "../../../component/CustomUploadFiles";

/** - 基本設定 */
export default memo(function BasicSettings() {
  const { setValue } = useFormContext();
  const ratio = useMemo(() => `${(4 / 3).toFixed(2) * 100}%`, []);
  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <Controller
          name={`battleAssetInputs.${0}.url`}
          rules={{
            required: "必填欄位 !",
          }}
          render={({ field, fieldState: { error } }) => (
            <div>
              <InputTitle label="開場動畫" required />
              <AdaptiveImage ratio={ratio}>
                <CustomUploadFiles
                  name={`battleAssetInputs.${0}.url`}
                  multiple={false}
                  accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                  type="video"
                  render={() => (
                    <ReactPlayer
                      url={field?.value}
                      width={"100%"}
                      height="100%"
                      controls
                    />
                  )}
                  uploadIconStyle={{
                    border: `1px solid ${Boolean(error) ? "red" : "#9c9c9c"}`,
                    borderRadius: "5px",
                  }}
                  {...field}
                  onChange={(e) => {
                    setValue(
                      `battleAssetInputs.${0}.type`,
                      "OPENING_ANIMATION"
                    );
                    field.onChange(e.location);
                  }}
                />
              </AdaptiveImage>
              <FormControl name={`battleAssetInputs.${0}.url`}>
                {error?.message && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
              </FormControl>
            </div>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Controller
          name={`battleAssetInputs.${1}.url`}
          rules={{
            required: "必填欄位 !",
          }}
          render={({ field, fieldState: { error } }) => (
            <div>
              <InputTitle label="背景" required />
              <AdaptiveImage ratio={ratio}>
                <CustomUploadFiles
                  name={`battleAssetInputs.${1}.url`}
                  multiple={false}
                  type="file"
                  accept="image/*"
                  render={() => (
                    <img
                      src={field?.value}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  )}
                  uploadIconStyle={{
                    border: `1px solid ${Boolean(error) ? "red" : "#9c9c9c"}`,
                    borderRadius: "5px",
                  }}
                  {...field}
                  onChange={(e) => {
                    setValue(`battleAssetInputs.${1}.type`, "BACKGROUND");
                    field.onChange(e.location);
                  }}
                />
              </AdaptiveImage>
              <FormControl name={`battleAssetInputs.${1}.url`}>
                {error?.message && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
              </FormControl>
            </div>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Controller
          name={`battleAssetInputs.${2}.url`}
          rules={{
            required: "必填欄位 !",
          }}
          render={({ field, fieldState: { error } }) => (
            <div>
              <InputTitle label="島嶼" required />
              <AdaptiveImage ratio={"124%"}>
                <CustomUploadFiles
                  name={`battleAssetInputs.${2}.url`}
                  multiple={false}
                  type="file"
                  accept="image/*"
                  render={() => (
                    <img
                      src={field?.value}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  )}
                  uploadIconStyle={{
                    border: `1px solid ${Boolean(error) ? "red" : "#9c9c9c"}`,
                    borderRadius: "5px",
                  }}
                  {...field}
                  onChange={(e) => {
                    setValue(`battleAssetInputs.${2}.type`, "BACKGROUND");
                    field.onChange(e.location);
                  }}
                />
              </AdaptiveImage>
              <FormControl name={`battleAssetInputs.${2}.url`}>
                {error?.message && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
              </FormControl>
            </div>
          )}
        />
      </Grid>
    </>
  );
});
