import React, { useReducer } from "react";
// NOTE 組件
import BattleAssetsList from "./BattleAssetsList";
import BattleAssetsForm from "./BattleAssetsForm";
import BattleAssetsMaterial from "./BattleAssetsMaterial/BattleAssetsMaterial";

// NOTE reducerDispatch
function reducerDispatch(state, action) {
  switch (action.type) {
    case "add":
    case "content":
    case "material":
      return { type: action.type, data: action.data };
    default:
      return null;
  }
}
export default function BattleAssetsManage() {
  const [swtichContent, swtichContentDispatch] = useReducer(
    reducerDispatch,
    null
  );
  switch (swtichContent?.type) {
    case "add":
      return <BattleAssetsForm swtichContentDispatch={swtichContentDispatch} />;
    case "content":
      return swtichContent?.data?.id ? (
        <BattleAssetsForm
          swtichContent={swtichContent.data}
          swtichContentDispatch={swtichContentDispatch}
        />
      ) : null;
    case "material":
      return (
        <BattleAssetsMaterial swtichContentDispatch={swtichContentDispatch} />
      );
    default:
      return <BattleAssetsList swtichContentDispatch={swtichContentDispatch} />;
  }
}
