import { atomWithImmer } from "jotai-immer";

export const competitionsStatusAtom = atomWithImmer({
  /** - 步驟 */
  step: undefined,
  /** - 主螢幕步驟 */
  homeScreenStep: undefined,
  /** - 素材 */
  battleAssets: [],
  /** - 已鎖定 */
  locked: false,
  /** - 允許影片撥放 */
  allowVideoPlay: false,
  /** - 卡片區塊最大高度 */
  maxHeight: 0,
});
