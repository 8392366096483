import React, { useCallback, useMemo } from "react";
import { Box } from "@material-ui/core";
import { useAtomValue } from "jotai";
import { selectAtom } from "jotai/utils";
import { deepEqual } from "fast-equals";
// NOTE 工具
import { competitionsStatusAtom } from "../../../jotai/Competitions";
// NOTE 組件
import ParticipationPhase from "./ParticipationPhase";
import StartBattle from "./StartBattle";

/** - 參賽者 */
export default function Contestants() {
  const { step } = useAtomValue(
    selectAtom(
      competitionsStatusAtom,
      useCallback(
        (state) => ({
          step: state.step,
        }),
        []
      ),
      deepEqual
    )
  );
  const _renderStep = useMemo(() => {
    switch (step) {
      case "START_BATTLE":
        return <StartBattle />;
      default:
        return <ParticipationPhase />;
    }
  }, [step]);
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          maxWidth: "100%",
          backgroundColor: "#fff",
          aspectRatio: 2436 / 4096,
        }}
      >
        {_renderStep}
      </Box>
    </Box>
  );
}
