import {
  memo,
  useEffect,
  useRef,
  useState,
  Fragment,
  createElement,
  useCallback,
  useMemo,
} from "react";
import {
  Box,
  BoxProps,
  Typography,
  makeStyles,
  LinearProgress,
  Divider,
  DividerProps,
  CircularProgress,
  Grid,
  Modal,
  useTheme,
  Fade,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment-timezone";
import { batch, useDispatch, useSelector } from "react-redux";
import "./index.css";

import {
  setLoadInitialValue,
  setRemainingTime,
  restRaidSetting,
  setStatus,
  setNumberOfPlayer,
  setEndTime,
  setStartTime,
  setCurrentHealthPoint,
  setScore,
  setShowBoard,
  setShowResult,
} from "../../redux/raidMonster";
import { ReactComponent as VictorySvg } from "./assets/victory.svg";
import { ReactComponent as DefeatSvg } from "./assets/defeat.svg";
import Qrcode from "./components/Qrcode";
// import { a } from "caniuse-lite/dist/lib/supported";

//團體戰Query
const GET_EVENT_RAID = gql`
  query event($id: Int) {
    event(id: $id) {
      id
      ... on RaidEvent {
        monster
        maxHealthPoint
        damagePerAttack
        quota
        players {
          id
          score
          player {
            fullName
          }
        }
        startTime
        endTime
        status
      }
    }
  }
`;

// 團體戰遊戲中內容(全部)
const GET_RAIDBIGSCREEN = gql`
  query raidBigScreen($eventId: Int!) {
    raidBigScreen(eventId: $eventId) {
      numberOfPlayer
      totalScore
      currentHealthPoint
    }
  }
`;

// const useTextStyles = makeStyles({
//   serifText: {
//     fontFamily: "Noto Serif TC",
//   },
// });
// ANCHOR 主要組件
export default function GameUI() {
  return (
    <>
      <FetchGameInitialValue />
      <GameLayout>
        <GameContentSwitch />
        <ResultModal />
        <InformationBoard />
      </GameLayout>
    </>
  );
}
// ANCHOR 遊戲外框
function GameLayout({ children }) {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    outside: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
    },
    bg: {
      height: "100vh",
      width: "100%",
      // display: "block",
      // objectFit: "cover",
      backgroundColor: "rgba(0,0,0)",
      overflow: "hidden",
    },
    total: {
      position: "absolute",
      alignSelf: "flex-end",
      top: 40,
      right: 60,
      color: "white",
    },
    children: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: "white",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: theme.spacing(4),
    },
  }));
  const classes = useStyles();
  useEffect(() => {
    return () => dispatch(restRaidSetting());
  }, [dispatch]);
  return (
    <Box className={`${classes.outside}`}>
      <Box className={classes.bg}>
        <GameBackgroundAnimation />
      </Box>
      <Box className={classes.children}>
        <Box className={classes.total} display="none">
          <TotalPeople />
        </Box>
        {children}
      </Box>
    </Box>
  );
}
// ANCHOR 遊戲背景影片
function GameBackgroundAnimation() {
  const queryParameters = new URLSearchParams(window.location.search);
  const type = queryParameters.get("type");
  const monsterFolder = useMemo(() => {
    switch (type) {
      case "Ghost":
        return "Ghost";
      case "BlackCat":
        return "BlackCat";
      case "OrangeCat":
        return "OrangeCat";
      case "BenzCat":
        return "BenzCat";
      case "Godzilla":
        return "Godzilla";
      case "MagmaCat":
      default:
        return "MagmaCat";
    }
  }, [type]);
  const dispatch = useDispatch();
  const timingRef = useRef(null);
  const minute = 60;
  const status = useSelector((state) => state.raidMonster.status);
  const loaded = useSelector((state) => state.raidMonster.loaded);
  const startTime = useSelector((state) => state.raidMonster.startTime);
  const endTime = useSelector((state) => state.raidMonster.endTime);
  const showResult = useSelector((state) => state.raidMonster.showResult);
  // const currentHealthPoint = useSelector(
  //   (state) => state.raidMonster.currentHealthPoint
  // );
  // const expired =
  //   moment(endTime)
  //     .tz("Asia/Taipei")
  //     .diff(moment().tz("Asia/Taipei"), "seconds") < 0;

  const [endTimeSubtract, setEndTimeSubtract] = useState(null);

  useEffect(() => {
    timingRef.current = setInterval(() => {
      if (endTime) {
        setEndTimeSubtract(
          moment(endTime)
            .tz("Asia/Taipei")
            .diff(moment().tz("Asia/Taipei"), "seconds")
        );
      }
    }, 1000);
    return () => {
      if (timingRef.current) {
        clearInterval(timingRef.current);
      }
    };
  }, [startTime, endTime]);

  const _setShowResult = useCallback(() => {
    dispatch(setShowResult(true));
  }, [dispatch]);

  // NOTE 判定血量結果輸出輸或贏
  const gameResult = (() => {
    // if (status === "VICTORY" || (currentHealthPoint <= 0 && !expired)) {
    //   return "VICTORY";
    // } else
    if (status === "VICTORY") {
      return "VICTORY";
    } else if (status === "DEFEAT") {
      return "DEFEAT";
    }
    return null;
  })();

  const switchResultArea = useMemo(() => {
    if (loaded) {
      // 先進入 else 讓動畫跑完後再進入 LOOP.mp4
      if (gameResult === "VICTORY") {
        if (showResult) {
          return (
            <MonsterVideo
              url={`/video/${monsterFolder}/victoryLOOP.mp4`}
              loop
            />
          );
        } else {
          return (
            <MonsterVideo
              url={`/video/${monsterFolder}/victory.mp4`}
              minute={minute}
              endTimeSubtract={endTimeSubtract}
              onEnded={_setShowResult}
            />
          );
        }
      }
      if (gameResult === "DEFEAT") {
        if (showResult) {
          return (
            <MonsterVideo url={`/video/${monsterFolder}/defeatLOOP.mp4`} loop />
          );
        } else {
          return (
            <MonsterVideo
              url={`/video/${monsterFolder}/defeat.mp4`}
              minute={minute}
              endTimeSubtract={endTimeSubtract}
              onEnded={_setShowResult}
            />
          );
        }
      }
      return <div />;
    } else {
      return <div />;
    }
  }, [
    monsterFolder,
    loaded,
    gameResult,
    showResult,
    endTimeSubtract,
    _setShowResult,
  ]);

  // NOTE 判斷時間倒數為 0
  const endEarly = useMemo(() => {
    //     血量小於等於 0 (  回傳 switchResultArea 含式 => 要直接進入贏的畫面 )
    //   if (endTimeSubtract <= 0 && currentHealthPoint <= 0) {
    //     return true;
    //   }
    //    在倒數時間小於等於 0 / 血量還大於 0 (  回傳 switchResultArea 含式 => 要直接進入輸的畫面 )
    //   if (
    //     typeof endTimeSubtract === "number" &&
    //     endTimeSubtract <= 0 &&
    //     currentHealthPoint > 0
    //   ) {
    //     return true;
    //   }
    //   return false;
    // }, [currentHealthPoint, endTimeSubtract]);

    if (endTimeSubtract <= 0) {
      return true;
    }
  }, [endTimeSubtract]);

  // NOTE 沒有結束時間時轉圈圈
  if (endTimeSubtract === null) {
    return <CircularProgress color="inherit" />;
  }

  //NOTE 戰鬥 30 秒 ( 時間 30 秒內 / 倒數時間大於 0 / 血量大於 0 )
  // endTimeSubtract <= 0
  if (endTimeSubtract <= 30 && endTimeSubtract > -5) {
    dispatch(setShowBoard(true));
    return (
      <MonsterVideo
        url={`/video/${monsterFolder}/battle.mp4`}
        minute={minute}
        endTimeSubtract={endTimeSubtract}
      />
    );
  }

  //NOTE 倒數前 10 秒
  if (endTimeSubtract <= 40 && endTimeSubtract > 30) {
    dispatch(setShowBoard(true));
    return (
      <MonsterVideo
        url={`/video/${monsterFolder}/countdown.mp4`}
        minute={minute}
        endTimeSubtract={endTimeSubtract}
      />
    );
  }

  // NOTE 開始前 10 分鐘
  if (endTimeSubtract <= minute * 10 && endTimeSubtract > 40) {
    return (
      <MonsterVideo
        url={`/video/${monsterFolder}/10minwait.mp4`}
        minute={minute}
        endTimeSubtract={endTimeSubtract}
      />
    );
  }

  if (endEarly) {
    return switchResultArea;
  }

  if (endTimeSubtract >= minute * 10) {
    //NOTE 除此之外 => 大於 10 分鐘
    return (
      <Typography variant="h5" color="white">
        離開始時間還很久！
      </Typography>
    );
  }
}

// ANCHOR 怪物展示
/**
 * @typedef MonsterVideoProps
 * @property {string} url
 * @property {() => void} onEnded
 * @param {MonsterVideoProps} param0
 */
function MonsterVideo({ url, loop = false, onEnded }) {
  const videoParentRef = useRef();
  const videoFnRef = useRef();
  const disabledClickRef = useRef(false);
  useEffect(() => {
    if (videoParentRef.current) {
      const player = videoParentRef.current.children[0];
      if (player) {
        setTimeout(() => {
          player.controls = false;
          player.playsinline = true;
          player.muted = false;
          player.autoplay = true;
          player.loop = Boolean(loop);
          if (onEnded) {
            player.onended = () => {
              onEnded();
            };
          }
          videoFnRef.current = function videoPlayer() {
            if (!disabledClickRef.current) {
              player.muted = false;
              const promise = player.play();
              if (promise.then) {
                promise.then(() => {
                  disabledClickRef.current = true;
                });
              }
            }
          };
          window.addEventListener("click", videoFnRef.current);
          setTimeout(() => {
            const promise = player.play();
            if (promise.then) {
              promise.then(() => {
                disabledClickRef.current = true;
              });
            }
          }, 0);
        }, 0);
      }
    }
    return () => {
      if (videoParentRef.current) {
        const player = videoParentRef.current.children[0];
        if (player && videoFnRef.current) {
          disabledClickRef.current = false;
          window.removeEventListener("click", videoFnRef.current);
        }
      }
    };
  }, [url, loop, onEnded]);

  return (
    <div
      style={{ height: "100%", width: "100%" }}
      ref={videoParentRef}
      dangerouslySetInnerHTML={{
        __html: `
        <video
          muted
          autoplay
          playsinline
          preload="metadata"
          width="100%"
          height="100%"
          src="${url}"
          type="video/mp4"
        >
        </video>`,
      }}
    />
  );
}

// ANCHOR 遊戲內容切換
const GameContentSwitch = memo(() => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.raidMonster.status);
  const loaded = useSelector((state) => state.raidMonster.loaded);
  if (loaded) {
    if (typeof status === "undefined") {
      return <Typography variant="h5">找不到團體戰！</Typography>;
    } else {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignSelf="flex-start"
          height="100vh"
          width={360}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            {(() => {
              switch (status) {
                case "READY_TO_START":
                  // 開始前
                  dispatch(setShowBoard(false));
                  return <PrepareScenes />;
                default:
                  dispatch(setShowBoard(true));
                  return null;
              }
            })()}
          </Box>
        </Box>
      );
    }
  } else {
    return <CircularProgress color="inherit" />;
  }
});
// ANCHOR 開始前畫面
function PrepareScenes() {
  const dispatch = useDispatch();
  const timingRef = useRef(null);
  const minute = 60;
  const startTime = useSelector((state) => state.raidMonster.startTime);
  const endTime = useSelector((state) => state.raidMonster.endTime);
  const [startTimeSubtract, setStartTimeSubtract] = useState(null);
  useEffect(() => {
    timingRef.current = setInterval(() => {
      if (startTime) {
        setStartTimeSubtract(
          Math.max(
            moment(startTime)
              .tz("Asia/Taipei")
              .diff(moment().tz("Asia/Taipei"), "seconds"),
            0
          )
        );
      }
    }, 1000);
    return () => {
      if (timingRef.current) {
        clearInterval(timingRef.current);
      }
    };
  }, [startTime, endTime]);
  if (startTimeSubtract === null) {
    return <CircularProgress color="inherit" />;
  } else if (startTimeSubtract >= minute * 10) {
    // NOTE 時間大於開始 10 分鐘前
    return <Typography variant="h5">團體戰尚未開始！</Typography>;
  } else if (startTimeSubtract < minute * 10 && startTimeSubtract > 10) {
    // NOTE 時間小於開始 10 分鐘前 && 大於開始 10 秒
    return (
      <>
        <LOGO />
        <BeforeRaid />
        {/* 櫻花獸版本新增 */}
        <TotalPeopleSAKURA />
      </>
    );
  } else if (startTimeSubtract <= 10 && startTimeSubtract > 0) {
    //NOTE 倒數前 10 秒(目前是無字狀態)
    dispatch(setShowBoard(true));
    return <PrepareText />;
  } else {
    return null;
  }
}

// ANCHOR LOGO
function LOGO() {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "10%",
        left: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <img
        src={require("./assets/title.png").default}
        style={{ width: "100%" }}
        alt=""
      />
    </Box>
  );
}

// ANCHOR GamingLOGO
function GamingLOGO() {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 40,
        left: 60,
      }}
    >
      <img
        src={require("./assets/title.png").default}
        style={{ width: "100%" }}
        alt=""
      />
    </Box>
  );
}

// ANCHOR 團體賽開始前 (左邊面板)
function BeforeRaid() {
  // const textClasses = useTextStyles();
  const startTime = useSelector((state) => state.raidMonster.startTime);
  return (
    <Box padding={1}>
      <Grid container spacing={1} justifyContent="center">
        {/* 版面一起出現的 logo */}
        {/* <Grid item xs={12}>
          <img
            src={require("./assets/title.png").default}
            style={{ width: "100%" }}
          />
        </Grid> */}
        {/* 說明 */}
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            貓錦獸團體戰
            <br />
            遊戲開始時間
          </Typography>
        </Grid>
        {/* 開始時間 */}
        <Grid item xs={12}>
          <SeparateBox width="100%">
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              {moment(startTime).tz("Asia/Taipei").format("HH：mm")}
            </Typography>
          </SeparateBox>
        </Grid>
        {/* <Grid item xs={12} style={{ margin: "15px 0" }}>
          <Typography
            variant="h5"
            align="center"
            className={textClasses.serifText}
          >
            抽選團體戰活動即將開跑
          </Typography>
          <Typography
            variant="h5"
            align="center"
            className={textClasses.serifText}
          >
            請以打倒遊戲角色為目標
          </Typography>
        </Grid> */}
        {/* QRcode 和分享文字 */}
        <Grid
          item
          container
          justifyContent="center"
          spacing={0.5}
          style={{
            marginTop: "15px",
            backgroundColor: "#fff",
            color: "#000",
            width: "50%",
          }}
        >
          <Grid item>
            <Typography>歡迎分享 QRcode 邀請朋友來參戰！</Typography>
          </Grid>
          <br />
          <Grid item>
            <Qrcode size={150} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
// ANCHOR 即將開始文字
const PrepareText = memo(() => {
  const useStyles = makeStyles((theme) => ({
    prepareText: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      fontWeight: "bold",
      animation: `$prepare 250ms ${theme.transitions.duration.standard}`,
    },
    "@keyframes prepare": {
      "0%": { color: "white" },
      "100%": { color: "red" },
    },
  }));
  const classes = useStyles();
  return <Typography variant="h4" className={classes.prepareText}></Typography>;
});

// ANCHOR 參加人數
const TotalPeople = memo(() => {
  const numberOfPlayer = useSelector(
    (state) => state.raidMonster.numberOfPlayer
  );
  const labelArray = String(numberOfPlayer).padStart(4, "0").split("");
  return (
    <>
      <Grid item xs={12}>
        <Typography
          align="center"
          variant="h4"
          style={{
            marginBottom: 10,
            lineHeight: 1,
          }}
        >
          累計參戰人數
        </Typography>
      </Grid>
      <Grid container item justifyContent="center">
        <Box width="100%">
          <Grid container spacing={1} justifyContent="center">
            {labelArray.map((i, index) => (
              <Grid item key={`${i}-${index}`}>
                <Typography
                  variant="h3"
                  style={{ lineHeight: 1, textDecoration: "underline 4px" }}
                >
                  {i}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </>
  );
});

// SECTION 櫻花獸版本新增
// ANCHOR 參加人數
const TotalPeopleSAKURA = memo(() => {
  const numberOfPlayer = useSelector(
    (state) => state.raidMonster.numberOfPlayer
  );
  const labelArray = String(numberOfPlayer).padStart(4, "0").split("");
  return (
    <Box
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <Grid item xs={12}>
        <Typography
          align="center"
          variant="h3"
          style={{
            marginBottom: 10,
            lineHeight: 1,
            color: "rgba(255, 255, 255, .9) ",
            fontWeight: "bold",
          }}
        >
          累計參戰人數
        </Typography>
      </Grid>
      <Grid container item justifyContent="center">
        <Box width="100%">
          <Grid container spacing={1} justifyContent="center">
            {labelArray.map((i, index) => (
              <Grid item key={`${i}-${index}`}>
                <Typography
                  variant="h1"
                  style={{
                    lineHeight: 1,
                    textDecoration: "underline 4px",
                    color: "rgba(255, 255, 255, .9) ",
                  }}
                >
                  {i}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
});
// !SECTION

// ANCHOR 結算浮層
const ResultModal = memo(() => {
  const show = useSelector((state) => state.raidMonster.showResult);
  const status = useSelector((state) => state.raidMonster.status);
  const loaded = useSelector((state) => state.raidMonster.loaded);
  const endTime = useSelector((state) => state.raidMonster.endTime);
  const currentHealthPoint = useSelector(
    (state) => state.raidMonster.currentHealthPoint
  );
  const expired =
    moment(endTime)
      .tz("Asia/Taipei")
      .diff(moment().tz("Asia/Taipei"), "seconds") <= 0;
  function switchArea() {
    if (loaded && show) {
      if (currentHealthPoint <= 0 && !expired) {
        return <ResultContent result="VICTORY" />;
      }
      switch (status) {
        case "VICTORY":
          return <ResultContent result="VICTORY" />;
        case "DEFEAT":
          return <ResultContent result="DEFEAT" />;
        default:
          return <div />;
      }
    } else {
      return <div />;
    }
  }
  if (loaded && typeof status === "string" && expired) {
    return (
      <Modal open={show && (status === "VICTORY" || status === "DEFEAT")}>
        {switchArea()}
      </Modal>
    );
  } else {
    return null;
  }
});
// ANCHOR 結果畫面
function ResultContent({ result = "DEFEAT" }) {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(1),
    },
    content: {
      color: "white",
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  }));
  const classes = useStyles();
  // const textClasses = useTextStyles();
  const defeatText = [
    "｜請再接再厲！｜",
    "感謝所有馬力參與這次的團體戰，可惜沒有挑戰成功，下次再戰吧！",
  ];
  const victoryText = [
    "｜恭喜獲得抽選資格！｜",
    "稍後請至\n「會員中心」>「待付款訂單」\n查看抽選結果",
    "中選者商品將直接匯入，務必於時間內完成繳款，未中選者一律不另行通知。",
  ];
  return (
    <Box className={classes.content}>
      {createElement(result === "DEFEAT" ? DefeatSvg : VictorySvg, {
        width: 128,
        height: 128,
      })}
      {(result === "DEFEAT" ? defeatText : victoryText).map((i, index) => (
        <Fragment key={index}>
          {index === 0 ? (
            <>
              <ShadowBox>
                <Typography
                  variant="h1"
                  style={{ color: result === "DEFEAT" ? "#C91733" : "#F4CF21" }}
                >
                  {result}
                </Typography>
              </ShadowBox>
              <Box marginBottom={3}>
                <Typography align="center" variant="h4">
                  {i}
                </Typography>
              </Box>
            </>
          ) : (
            <Typography align="center" variant="h6" paragraph>
              {i}
            </Typography>
          )}
        </Fragment>
      ))}
      <Rankingboard />
    </Box>
  );
}
/** - 名稱中間改成`O` */
function replaceMiddleWithO(fullName) {
  // 確認名字的長度
  const length = fullName.length;

  // 確認要替換的字元數
  const middleCount = Math.max(0, length - 2);

  // 產生要替換的 "O" 字串
  const middleO = "O".repeat(middleCount);

  // 將名字的第二個字元開始的部分替換成 "O"
  const replacedName =
    fullName.substring(0, 1) + middleO + fullName.substring(length - 1);

  return replacedName;
}
// ANCHOR 排行榜
function Rankingboard() {
  const useStyles = makeStyles((theme) => ({
    first: {
      ...theme.typography.h3,
      fontWeight: "bold",
      color: "#F5CE2F",
    },
    other: { ...theme.typography.h4 },
  }));
  const classes = useStyles();
  const [rankList, setRankList] = useState([]);
  const params = useParams();
  const id = Number(params?.id);

  const [getEvent, { loading }] = useLazyQuery(GET_EVENT_RAID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          setRankList(
            event.players
              .map((item) => ({
                fullName: replaceMiddleWithO(item.player.fullName),
                score: item.score,
              }))
              .sort((a, b) => b.score - a.score)
              .filter((_, index) => index < 5)
          );
        }, 0);
      }
    },
  });
  useEffect(() => {
    if (id) {
      getEvent({ variables: { id } });
    }
  }, [getEvent, id]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      color="white"
    >
      <Typography variant="h2" style={{ fontWeight: "bold" }}>
        RANKING
      </Typography>
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          {rankList.map((item, index) => {
            const plusnember = 1.009 - 0.001 * index;
            return (
              <Typography className={classes.other}>
                {index + 1}.{item.fullName}---
                {(item.score * plusnember).toFixed()}
              </Typography>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
// ANCHOR 資訊板
function InformationBoard() {
  const theme = useTheme();
  const showBoard = useSelector((state) => state.raidMonster.showBoard);

  if (showBoard) {
    return (
      <>
        <GamingLOGO />
        <Box position={"absolute"} top={40} right={60}>
          <TotalPeople />
        </Box>
        <Box
          position="fixed"
          bottom={theme.spacing(2)}
          padding={1}
          width="100%"
          maxWidth={1200}
        >
          <Fade timeout={8000} in>
            <Grid container direction="column" alignItems="center" spacing={1}>
              <Grid container item>
                <StatusBoard />
              </Grid>
              {/* <Grid container item>
                <ProgressBar />
              </Grid> */}
            </Grid>
          </Fade>
        </Box>
      </>
    );
  } else {
    return null;
  }
}
// ANCHOR 狀態板
function StatusBoard() {
  // const currentHealthPoint = useSelector(
  //   (state) => state.raidMonster.currentHealthPoint
  // );
  const score = useSelector((state) => state.raidMonster.score);
  const remainingTime = useSelector((state) => state.raidMonster.remainingTime);
  const boardData = [
    // { label: "HP", value: currentHealthPoint },
    { label: "TIME", value: `${remainingTime}s` },
    { label: "SCORE", value: String(score).padStart(5, "0") },
  ];
  return (
    <Box display="flex" width="100%">
      {boardData.map((item, index) => (
        <Fragment key={item.label}>
          {index > 0 && <Separate orientation="vertical" />}
          <Box flex={1} flexDirection="column">
            <Typography
              variant="h4"
              align="center"
              style={{ fontWeight: "bold" }}
            >
              {item.label}
            </Typography>
            <Separate variant="middle" />
            <Typography variant="h4" align="center">
              {item.value}
            </Typography>
          </Box>
        </Fragment>
      ))}
    </Box>
  );
}

// ANCHOR 進度條
function ProgressBar() {
  const maxHealthPoint = useSelector(
    (state) => state.raidMonster.maxHealthPoint
  );
  const currentHealthPoint = useSelector(
    (state) => state.raidMonster.currentHealthPoint
  );
  const useStyles = makeStyles({
    bar: {
      width: "100%",
      height: 12,
      border: `1px solid white`,
      backgroundColor: "transparent",
      "& .MuiLinearProgress-bar": {
        backgroundColor: "white",
      },
    },
  });
  const classes = useStyles();
  return (
    <LinearProgress
      className={classes.bar}
      variant="determinate"
      color="primary"
      value={creatProgress(currentHealthPoint, maxHealthPoint)}
    />
  );
}
// ANCHOR 影子盒
function ShadowBox({ children }) {
  const useStyles = makeStyles({
    shadow: {
      position: "absolute",
      bottom: 4,
      right: 0,
      left: 0,
      background: `linear-gradient(to right, transparent, rgba(255, 255, 255), transparent)`,
      filter: `blur(1px)`,
      height: 4,
      borderRadius: "50%",
    },
  });
  const classes = useStyles();
  return (
    <Box position="relative">
      <Box className={classes.shadow} />
      {children}
    </Box>
  );
}
// ANCHOR 分隔線
/**
 * @param {Omit<DividerProps, 'className' | 'classes' | 'style' | 'flexItem'>} props
 */
function Separate(props) {
  const useStyles = makeStyles({
    hr: {
      paddingTop: 1,
      background: `linear-gradient(to ${
        props.orientation === "vertical" ? "bottom" : "right"
      }, transparent, white, transparent)`,
      filter: `blur(0.5px)`,
    },
  });
  const classes = useStyles();
  return <Divider className={classes.hr} flexItem {...props} />;
}
// ANCHOR 分隔線箱子
/**
 * @param {BoxProps} param0
 */
function SeparateBox({ children, ...props }) {
  return (
    <Box {...props}>
      <Separate />
      <Box
        marginY={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Box>
      <Separate />
    </Box>
  );
}

// SECTION 資料處理相關
// ANCHOR 獲得遊戲內容
function FetchGameInitialValue() {
  const params = useParams();
  const id = Number(params.id);
  const dispatch = useDispatch();
  // NOTE 只抓第一次當預設值
  const [getEvent] = useLazyQuery(GET_EVENT_RAID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          dispatch(
            setLoadInitialValue({
              status: event.status,
              maxHealthPoint: event.maxHealthPoint,
              numberOfPlayer: event.players ? event.players.length : 0,
              damagePerAttack: event.damagePerAttack,
              startTime: event.startTime,
              endTime: event.endTime,
              remainingTime: convertRemaining(event.endTime, event.startTime),
            })
          );
        }, 0);
      }
    },
    onError(error) {
      // Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      dispatch(
        setLoadInitialValue({
          status: undefined,
        })
      );
    },
  });
  // ANCHOR 取得怪物資料
  const [getRaidBigScreen] = useLazyQuery(GET_RAIDBIGSCREEN, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000,
    onCompleted({ raidBigScreen }) {
      if (raidBigScreen) {
        setTimeout(() => {
          batch(() => {
            dispatch(setNumberOfPlayer(raidBigScreen.numberOfPlayer));
            dispatch(setCurrentHealthPoint(raidBigScreen.currentHealthPoint));
            dispatch(setScore(raidBigScreen.totalScore));
          });
        }, 0);
      }
    },
  });
  useEffect(() => {
    if (id) {
      getEvent({ variables: { id } });
      getRaidBigScreen({ variables: { eventId: Number(id) } });
    }
  }, [getEvent, getRaidBigScreen, id]);
  useEventEffect();
  useRemainingTimeEffect();
  return null;
}
// ANCHOR 建立進度條
/**
 * @param {number} molecular 分子
 * @param {number} denominator 分母
 * @returns {number}
 */
function creatProgress(molecular, denominator = 100) {
  return Math.floor((molecular / denominator) * 100);
}
// ANCHOR 自動刷新遊戲資料
function useEventEffect() {
  const params = useParams();
  const id = Number(params?.id);
  const dispatch = useDispatch();
  const loaded = useSelector((state) => state.raidMonster.loaded);
  const [getEvent] = useLazyQuery(GET_EVENT_RAID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000,
    onCompleted({ event }) {
      if (event) {
        setTimeout(() => {
          batch(() => {
            dispatch(setStatus(event.status));
            dispatch(setStartTime(event.startTime));
            dispatch(setEndTime(event.endTime));
          });
        }, 0);
      }
    },
    // onError(error) {
    //   Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    // },
  });
  useEffect(() => {
    if (loaded) {
      if (id) {
        getEvent({ variables: { id } });
      }
    }
  }, [getEvent, id, loaded]);
}
// ANCHOR 取得遊戲剩餘時間
function useRemainingTimeEffect() {
  const dispatch = useDispatch();
  const endTime = useSelector((state) => state.raidMonster.endTime);
  // const currentHealthPoint = useSelector(
  //   (state) => state.raidMonster.currentHealthPoint
  // );
  const timerRef = useRef(null);

  // 只要判斷一次區
  // const survive = currentHealthPoint > 0;
  const endTiming =
    moment(endTime)
      .tz("Asia/Taipei")
      .diff(moment().tz("Asia/Taipei"), "seconds") <= 5;

  useEffect(() => {
    const timing = moment(endTime)
      .tz("Asia/Taipei")
      .diff(moment().tz("Asia/Taipei"), "seconds");

    timerRef.current = setInterval(
      //   () => {
      //   if (survive) {
      //     if (endTime && gaming) {
      //       // NOTE 血量變0時將剩餘秒數歸0
      //       dispatch(setRemainingTime(convertRemaining(endTime, undefined)));
      //     }
      //   }
      //   else  {
      //     dispatch(setRemainingTime(0));
      //   }
      // },

      /* 鯉魚版本更改 */
      () => {
        // NOTE 正常倒數
        if (timing > 0) {
          dispatch(setRemainingTime(convertRemaining(endTime, undefined)));
        }
        // NOTE 面板消失
        else {
          dispatch(setShowBoard(false));
          clearInterval(timerRef.current);
        }
      },
      1000
    );
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [endTime, dispatch, endTiming]);
}
// ANCHOR 換算遊戲剩餘時間
function convertRemaining(endTime, startTime) {
  return Math.max(
    Math.min(
      moment(endTime)
        .tz("Asia/Taipei")
        .diff(moment(startTime).tz("Asia/Taipei"), "seconds"),
      30
    ),
    0
  );
}
// !SECTION
