import React, { useCallback, useEffect, useReducer } from "react";
// material
import {
  Grid,
  Box,
  CircularProgress,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  TextField,
  FormHelperText,
} from "@material-ui/core";
// icon
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
// apollo
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
// react-hook-form
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
  useWatch,
} from "react-hook-form";
// react-player
import ReactPlayer from "react-player";
// date-fns
import { format, parseISO } from "date-fns";

// component
import Table from "../../component/Table";
import { useAlert } from "../../component/Alert";
import { Card, CardContent, CardActions } from "../../component/Card";
import { InputTitle } from "../../component/Form";
import Button from "../../component/Button";
import LoadingModal from "../../component/LoadingModal";
import AdaptiveImage from "../../component/AdaptiveImage";
import Image from "../../component/Image";
import CustomUploadFiles from "../../component/CustomUploadFiles";
// utils
import emptyArray from "../../utils/emptyArray";

// SECTION apollo
// NOTE 蛋種列表
const GET_EGGTYPES = gql`
  query eggTypes($eventId: Int!) {
    eggTypes(eventId: $eventId, latest: true) {
      # "總數"
      count
      # "總頁數"
      pageCount
      # "蛋種"
      contents {
        id
        # "名稱"
        name
        # "建立時間"
        createdAt
        # "更新時間"
        updatedAt
      }
    }
  }
`;
// NOTE 蛋種
const GET_EGGTYPE = gql`
  query eggType($id: Int!) {
    eggType(id: $id) {
      id
      # "名稱"
      name
      # "蛋gif"
      gifUrl
      # "抽蛋影片"
      drawLotsVideoUrl
      # "孵化影片(前)"
      hatchingVideoUrl
      # "餵食影片"
      feedingVideoUrl
      # "簡介"
      introduction
    }
  }
`;
// NOTE 儲存蛋種
const SAVE_EGGTYPE = gql`
  mutation saveEggType($eggTypeInput: EggTypeInput!) {
    saveEggType(eggTypeInput: $eggTypeInput) {
      success
      message
    }
  }
`;
// NOTE 隱藏蛋種
const HIDE_EGGTYPE = gql`
  mutation hideEggType($id: Int!) {
    hideEggType(id: $id) {
      success
      message
    }
  }
`;
// !SECTION
// NOTE reducerDispatch
function reducerDispatch(state, action) {
  switch (action.type) {
    case "add":
    case "edit":
      return action.data;
    default:
      return null;
  }
}
// ANCHOR 主要組件
export default function EggTypeManage({ eventId }) {
  const [swtichContent, swtichContentDispatch] = useReducer(
    reducerDispatch,
    null
  );
  const { control, setValue } = useForm({
    defaultValues: {
      listRefetch: false,
      contentRefetch: false,
    },
  });

  switch (swtichContent?.swtichType) {
    case "add":
      return (
        <EggTypeForm
          swtichContentDispatch={swtichContentDispatch}
          formControl={control}
          formSetValue={setValue}
          eventId={eventId}
        />
      );
    case "edit":
      return swtichContent?.id ? (
        <EggTypeForm
          swtichContent={swtichContent}
          swtichContentDispatch={swtichContentDispatch}
          formControl={control}
          formSetValue={setValue}
          eventId={eventId}
        />
      ) : null;
    default:
      return (
        <EggTypeList
          swtichContentDispatch={swtichContentDispatch}
          formControl={control}
          formSetValue={setValue}
          eventId={eventId}
        />
      );
  }
}
// ANCHOR 列表
function EggTypeList({
  swtichContentDispatch = () => {},
  formControl,
  formSetValue,
  eventId,
}) {
  const listRefetch = useWatch({
    control: formControl,
    name: "listRefetch",
  });
  const { alert, notice } = useAlert();

  const {
    data: eggTypesData,
    loading: eggTypesLoading,
    refetch,
  } = useQuery(GET_EGGTYPES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      eventId,
    },
    onCompleted({ eggTypes }) {
      if (eggTypes) {
        setTimeout(() => {
          formSetValue("listRefetch", false);
        }, 0);
      }
    },
    onError() {
      return null;
    },
  });

  useEffect(() => {
    if (listRefetch) {
      refetch();
    }
  }, [listRefetch, refetch]);

  const [hideEggTypeFn, { loading: hideEggTypeLoading }] = useMutation(
    HIDE_EGGTYPE,
    {
      onCompleted({ hideEggType: { message } }) {
        if (message) {
          notice(message);
        } else {
          notice("刪除成功");
          refetch();
        }
      },
      onError() {
        return null;
      },
    }
  );

  const _hideEggType = useCallback(
    ({ id, name }) => {
      alert("", `確定要刪除蛋種「${name}」？`, [
        {
          text: "確定",
          onPress: () => {
            hideEggTypeFn({
              variables: {
                id,
              },
            });
          },
          type: "ok",
        },
        {
          text: "取消",
          type: "cancel",
        },
      ]);
    },
    [alert, hideEggTypeFn]
  );

  if (eggTypesLoading) {
    return (
      <Box
        display="flex"
        height={`350px`}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  } else {
    return (
      <Card>
        <LoadingModal loading={hideEggTypeLoading} />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography>
              蛋種數量：{eggTypesData?.eggTypes?.count || 0}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onPress={() =>
                swtichContentDispatch({
                  type: "add",
                  data: {
                    swtichType: "add",
                  },
                })
              }
            >
              新增蛋種
            </Button>
          </Box>
        </CardContent>
        <CardContent>
          <Table
            data={emptyArray(eggTypesData?.eggTypes?.contents)}
            header={["名稱", "建立時間", "最後更新時間", "操作"]}
            tableWidth={2}
            columns={[
              "name",
              (item) =>
                item.createdAt &&
                format(parseISO(item.createdAt), "yyyy/LL/dd HH:mm"),
              (item) =>
                item.updatedAt &&
                format(parseISO(item.updatedAt), "yyyy/LL/dd HH:mm"),
              (item) => (
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onPress={(e) => {
                        e.stopPropagation();
                        _hideEggType(item);
                      }}
                    >
                      刪除
                    </Button>
                  </Grid>
                </Grid>
              ),
            ]}
            onPress={(item) => {
              swtichContentDispatch({
                type: "edit",
                data: {
                  swtichType: "edit",
                  id: item.id,
                  name: item.name,
                },
              });
            }}
          />
        </CardContent>
      </Card>
    );
  }
}
// ANCHOR form
function EggTypeForm({
  swtichContent,
  swtichContentDispatch = () => {},
  formControl,
  formSetValue,
  eventId,
}) {
  const contentRefetch = useWatch({
    control: formControl,
    name: "contentRefetch",
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const form = useForm({
    defaultValues: {
      name: "",
      gifUrl: "",
      drawLotsVideoUrl: "",
      hatchingVideoUrl: "",
      feedingVideoUrl: "",
      introduction: "",
    },
  });
  const { reset, setValue } = form;

  useEffect(() => {
    if (eventId) {
      setValue("eventId", eventId);
    }
  }, [eventId, setValue]);

  const [getEggType, { loading: eggTypeLoading, refetch }] = useLazyQuery(
    GET_EGGTYPE,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted({ eggType }) {
        if (eggType) {
          setTimeout(() => {
            Object.keys(eggType).forEach((item) => {
              if (item !== "__typename") {
                setValue(item, eggType[item]);
              }
            });
            formSetValue("contentRefetch", false);
          }, 0);
        }
      },
      onError() {
        return null;
      },
    }
  );

  useEffect(() => {
    if (contentRefetch && refetch) {
      refetch();
    }
  }, [contentRefetch, refetch]);

  useEffect(() => {
    if (swtichContent?.id) {
      getEggType({ variables: { id: swtichContent.id } });
    }
  }, [swtichContent, getEggType]);

  const _goBack = useCallback(() => {
    swtichContentDispatch({
      type: "list",
    });
    reset();
  }, [swtichContentDispatch, reset]);

  if (eggTypeLoading) {
    return (
      <Box
        display="flex"
        height={`350px`}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }
  return (
    <Card>
      <FormProvider {...form}>
        <CardContent>
          <Box display="flex" flexDirection="row" alignItems="center">
            <IconButton onClick={_goBack} size="small">
              <KeyboardBackspaceIcon />
            </IconButton>
            <Typography style={{ paddingLeft: "10px" }}>
              {swtichContent?.name || "新增蛋種"}
            </Typography>
          </Box>
          <Grid container spacing={3} style={{ marginTop: 5 }}>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="name"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="名稱" required />
                    <TextField
                      {...field}
                      fullWidth
                      error={error}
                      helperText={error?.message}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid
              item
              sm={6}
              md={9}
              style={{ display: isMobile ? "none" : "block" }}
            />
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="gifUrl"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="抽蛋：蛋種動畫(GIF)" required />
                    <AdaptiveImage ratio="100%">
                      <CustomUploadFiles
                        name="gifUrl"
                        multiple={false}
                        render={() => (
                          <Image
                            src={field?.value}
                            style={{ width: "100%", height: "100%" }}
                            resizeMode={"contain"}
                          />
                        )}
                        uploadIconStyle={{
                          border: `1px solid ${
                            Boolean(error) ? "red" : "#9c9c9c"
                          }`,
                          borderRadius: "5px",
                        }}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.location);
                        }}
                      />
                    </AdaptiveImage>
                    {error?.message && (
                      <FormHelperText error>{error.message}</FormHelperText>
                    )}
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="drawLotsVideoUrl"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="抽蛋：抽蛋動畫(影片)" required />
                    <AdaptiveImage ratio="177.77%">
                      <CustomUploadFiles
                        name="drawLotsVideoUrl"
                        multiple={false}
                        accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                        type="video"
                        render={() => (
                          <ReactPlayer
                            url={field?.value}
                            width={"100%"}
                            height="100%"
                            controls
                            // config={{
                            //   file: {
                            //     forceHLS: true,
                            //   },
                            // }}
                          />
                        )}
                        uploadIconStyle={{
                          border: `1px solid ${
                            Boolean(error) ? "red" : "#9c9c9c"
                          }`,
                          borderRadius: "5px",
                        }}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.location);
                        }}
                      />
                    </AdaptiveImage>
                    {error?.message && (
                      <FormHelperText error>{error.message}</FormHelperText>
                    )}
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="hatchingVideoUrl"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="孵蛋：孵化動畫前半段(影片)" required />
                    <AdaptiveImage ratio="177.77%">
                      <CustomUploadFiles
                        name="hatchingVideoUrl"
                        multiple={false}
                        accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                        type="video"
                        render={() => (
                          <ReactPlayer
                            url={field?.value}
                            width={"100%"}
                            height="100%"
                            controls
                            // config={{
                            //   file: {
                            //     forceHLS: true,
                            //   },
                            // }}
                          />
                        )}
                        uploadIconStyle={{
                          border: `1px solid ${
                            Boolean(error) ? "red" : "#9c9c9c"
                          }`,
                          borderRadius: "5px",
                        }}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.location);
                        }}
                      />
                    </AdaptiveImage>
                    {error?.message && (
                      <FormHelperText error>{error.message}</FormHelperText>
                    )}
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="feedingVideoUrl"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="餵食影片" required />
                    <AdaptiveImage ratio="177.77%">
                      <CustomUploadFiles
                        name="feedingVideoUrl"
                        multiple={false}
                        accept=".mp4,.mp4v,.mpg4,.3gp,.mov,video/mp4,video/x-m4v,video/*"
                        type="video"
                        render={() => (
                          <ReactPlayer
                            url={field?.value}
                            width={"100%"}
                            height="100%"
                            controls
                            // config={{
                            //   file: {
                            //     forceHLS: true,
                            //   },
                            // }}
                          />
                        )}
                        uploadIconStyle={{
                          border: `1px solid ${
                            Boolean(error) ? "red" : "#9c9c9c"
                          }`,
                          borderRadius: "5px",
                        }}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.location);
                        }}
                      />
                    </AdaptiveImage>
                    {error?.message && (
                      <FormHelperText error>{error.message}</FormHelperText>
                    )}
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="introduction"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="簡介" required />
                    <TextField
                      {...field}
                      rows={5}
                      multiline
                      fullWidth
                      error={error}
                      helperText={error?.message}
                    />
                  </div>
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <ToolButton _goBack={_goBack} formSetValue={formSetValue} />
        </CardActions>
      </FormProvider>
    </Card>
  );
}
// ANCHOR 按鈕
function ToolButton({ _goBack = () => {}, formSetValue }) {
  const { notice } = useAlert();
  const { handleSubmit } = useFormContext();

  const [saveEggTypeFn, { loading: saveEggTypeLoading }] = useMutation(
    SAVE_EGGTYPE,
    {
      onCompleted({ saveEggType: { message } }) {
        if (message) {
          notice(message);
        } else {
          formSetValue("listRefetch", true);
          formSetValue("contentRefetch", true);
          notice("成功");
          _goBack();
        }
      },
      onError() {
        return null;
      },
    }
  );
  const _submit = useCallback(
    (data) => {
      const eggTypeInput = {
        ...data,
      };
      saveEggTypeFn({
        variables: {
          eggTypeInput,
        },
      });
    },
    [saveEggTypeFn]
  );
  const _onCancel = useCallback(() => {
    _goBack();
  }, [_goBack]);
  return (
    <>
      <LoadingModal loading={saveEggTypeLoading} />
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onPress={handleSubmit(_submit)}
          >
            儲存
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" onPress={_onCancel}>
            取消
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
