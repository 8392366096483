import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
// material-ui
import {
  Box,
  Container,
  Grid,
  useTheme,
  makeStyles,
  Typography,
  List,
  ListItem,
  ListItemText,
  Card,
  CardActions,
  CardMedia,
  CardActionArea,
  Tab,
  Tabs,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TableFooter,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  FormLabel,
  DialogActions,
  Chip,
} from "@material-ui/core";
// react-router-dom
import { useLocation, useHistory } from "react-router-dom";
// react-helmet
import { Helmet } from "react-helmet";
// apollo
import { useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
// moment
import moment from "moment";
// react-hook-form
import {
  useForm,
  FormProvider,
  useWatch,
  useFormContext,
} from "react-hook-form";
// date-fns
import { format, parseISO } from "date-fns";
import { deepEqual } from "fast-equals";
import Barcode from "react-barcode";

// component
import CustomTableComponent from "../component/Table";
import TitleBox from "../component/TitleBox";
import { useAlert } from "../component/Alert";
import MemberForm from "../component/MemberForm";
import LoadingModal from "../component/LoadingModal";
import RuleDialog from "../component/RuleDialog";
import CreateOrderFloatingLayer from "../component/CreateOrderFloatingLayer";
import LuyaoProfilePictureFloadingLayer from "../component/LuyaoProfilePictureFloadingLayer";
import useDivision from "../component/useDivision";
import useCountryCallingCode from "../component/useCountryCallingCode";
import LoadingFloating from "../component/LoadingFloating";
import { FieldCache } from "../component/Form";
// svgs
import { ReactComponent as ArrowRight } from "../../src/svgs/special/full-arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../src/svgs/special/full-arrow-left.svg";
// utils
import emptyArray from "../utils/emptyArray";
import { paymentMethodEnum } from "../utils/localData";
import useSearchParams from "../hooks/useSearchParams";
import padWithZeros from "../utils/padWithZeros";
// zustand
import useMemberIdStore from "../zustand/useMemberIdStore";

// SECTION apollo
// NOTE 會員
const Get_Member = gql`
  query member($id: Int) {
    member(id: $id) {
      id
      tier
      profilePicture {
        filename
        mimetype
        encoding
        location
      }
      countryCallingCode
      fullName
      nickname
      mobile
      email
      address {
        district
        subdistrict
        address
      }
      nationality
      currentLuyaoProfilePicture {
        id
        file {
          location
        }
      }
    }
  }
`;
// NOTE 會員更新
const Update_Member = gql`
  mutation updateMember(
    $profilePicture: FileInput
    $fullName: String!
    $nickname: String
    $address: AddressInput
    $countryCallingCode: String
    $mobile: String!
    $verificationCode: String
    $email: String!
    $currentLuyaoProfilePictureId: Int
  ) {
    updateMember(
      profilePicture: $profilePicture
      fullName: $fullName
      nickname: $nickname
      address: $address
      countryCallingCode: $countryCallingCode
      mobile: $mobile
      verificationCode: $verificationCode
      email: $email
      currentLuyaoProfilePictureId: $currentLuyaoProfilePictureId
    ) {
      success
      message
    }
  }
`;
// NOTE 訂單
const GET_ORDERS = gql`
  query GetOrders($memberId: Int, $status: OrderStatus) {
    orders(memberId: $memberId, status: $status, latest: true) {
      orders {
        id
        items {
          product {
            id
            name
            eta
            images {
              location
            }
            # "付款方式"
            paymentMethods
          }
          # "活動"
          event {
            id
          }
          amount
          unitPrice
          updatedAt
        }
        # "活動"
        event {
          id
          ... on DrawingLotsEvent {
            # "參加人數上限"
            maxNumberOfPeople
          }
        }
        payments {
          id
          success
          bankCode
          virtualAccount
          expireDate
        }
        totalPrice
        status
        createdAt
        updatedAt
      }
    }
  }
`;
// NOTE 訂單商品
const GET_ORDER_ITEMS = gql`
  query GetOrderItems($status: OrderItemStatus) {
    orderItems(personal: true, status: $status, latest: true) {
      orderItems {
        id
        product {
          id
          name
          eta
          images {
            location
          }
          deliveryFee
          shippable
          # "付款方式"
          paymentMethods
        }
        # "活動"
        event {
          id
        }
        # "蛋種"
        eggType {
          id
          # "名稱"
          name
        }
        shipment {
          id
          payments {
            id
            success
            bankCode
            virtualAccount
            expireDate
          }
          status
          convenienceStore
          logisticsRecords {
            id
            # "狀態訊息"
            rtnMsg
            # "狀態更新時間"
            statusUpdateTime
          }
          # "建立日期"
          createdAt
        }
        unitPrice
        amount
        createdAt
      }
    }
  }
`;
// NOTE 商品關聯
const GET_PRODUCTRELATION = gql`
  query productRelation($productId: Int!) {
    productRelation(productId: $productId) {
      id
      name
    }
  }
`;
// NOTE 會員育成活動紀錄列表
const GET_MEMBER_RAISING_RECORDS = gql`
  query memberRaisingRecords($page: Int) {
    memberRaisingRecords(latest: true, pageSize: 10, page: $page) {
      # "總頁數"
      pageCount
      # "會員蛋"
      contents {
        id
        # "券數"
        tickets
        # "蛋數"
        eggs
        # "建立時間"
        createdAt
        # "更新時間"
        updatedAt
      }
    }
  }
`;
// NOTE 重新嘗試付款
const RETRY_PAYMENT = gql`
  mutation RetryPayment($orderId: Int!, $shipmentInput: ShipmentInput) {
    retryPayment(orderId: $orderId, shipmentInput: $shipmentInput) {
      id
      paymentUrl
    }
  }
`;
// NOTE 更新訂單狀態
const CANCEL_ORDER = gql`
  mutation CancelOrder($id: Int!) {
    updateOrderStatus(id: $id, status: CANCELLED) {
      success
      message
    }
  }
`;
// NOTE 出貨
const CREATE_SHIPMENT = gql`
  mutation createShipment(
    $shipmentInput: ShipmentInput!
    $orderItemIds: [Int]!
  ) {
    createShipment(shipmentInput: $shipmentInput, orderItemIds: $orderItemIds) {
      id
      paymentUrl
    }
  }
`;
// !SECTION

export default function MemberPage() {
  const Alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const { searchParamsObject } = useSearchParams();
  const theme = useTheme();
  const [openRule, setOpenRule] = useState(false);
  const [profileType, setProfileType] = useState("訂單內容");
  const memberId = useMemberIdStore(useCallback((state) => state.memberId, []));
  const searchParamsObjectRef = useRef();
  const memberForm = useForm({
    defaultValues: {
      profilePicture: undefined,
      fullName: "",
      nickname: "",
      district: null,
      subdistrict: null,
      address: "",
      countryCallingCodeObject: null,
      mobile: "",
      verificationCode: "",
      email: "",
      currentLuyaoProfilePicture: undefined,
    },
  });
  const { control, setValue } = memberForm;
  useEffect(() => {
    if (!deepEqual(searchParamsObjectRef.current, searchParamsObject)) {
      searchParamsObjectRef.current = searchParamsObject;
      if (searchParamsObject.type) {
        setProfileType(searchParamsObject.type);
      }
    }
  }, [searchParamsObject]);

  const district = useWatch({ control, name: "district" });

  const { divisionKey, subdivisionsKey } = useDivision(district?.value);
  const { countryCodesArray } = useCountryCallingCode();

  const districtRef = useRef();
  const subdistrictRef = useRef();
  const defaultFormValueRef = useRef();

  useEffect(() => {
    if (
      subdivisionsKey[0] &&
      subdistrictRef.current &&
      districtRef.current?.value === district?.value
    ) {
      const subdistrictObject = subdivisionsKey.find(
        (item) => item.value === subdistrictRef.current
      );
      setValue("subdistrict", subdistrictObject);
    }
  }, [subdivisionsKey, district?.value, setValue]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("clientBack") === "true") {
      // window.gtag("event", "conversion", {
      //   send_to: "AW-355124485/7Mf8CP_mjdQCEIWKq6kB",
      //   transaction_id: "",
      // });
      if (
        process.env.NODE_ENV === "production" &&
        window.location.host.search(".s3-") === -1
      ) {
        window.fbq("track", "Purchase", {
          value: parseFloat(searchParams.get("value")),
          currency: "TWD",
        });
      }
      history.replace({ search: "" });
    }
  }, [history, location.search]);

  const [getMember, { data, loading }] = useLazyQuery(Get_Member, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ member }) {
      setTimeout(() => {
        if (member) {
          const {
            profilePicture,
            fullName,
            nickname,
            address,
            countryCallingCode,
            mobile,
            email,
            currentLuyaoProfilePicture,
          } = member;
          const defaultFormValue = {};
          if (profilePicture) {
            const { __typename, ...currentProfilePicture } = profilePicture;
            setTimeout(() => {
              setValue("profilePicture", currentProfilePicture);
              defaultFormValue.profilePicture = currentProfilePicture;
            }, 0);
          }
          setValue("fullName", fullName);
          setValue("nickname", nickname);
          defaultFormValue.fullName = fullName;
          if (address) {
            const districtObject = divisionKey.find(
              (item) => item.value === address.district
            );
            districtRef.current = districtObject;
            defaultFormValue.district = districtObject;
            subdistrictRef.current = address.subdistrict;
            defaultFormValue.subdistrict = address.subdistrict;
            defaultFormValue.address = address.address;
            setValue("district", districtObject);
            setValue("address", address.address);
          }
          if (countryCallingCode) {
            const newCountryCallingCodeObject = countryCodesArray.find(
              (item) => item.value === countryCallingCode
            );
            setValue("countryCallingCodeObject", newCountryCallingCodeObject);
            defaultFormValue.countryCallingCodeObject =
              newCountryCallingCodeObject;
          }
          setValue("mobile", mobile);
          defaultFormValue.mobile = mobile;
          setValue("email", email);
          defaultFormValue.email = email;
          if (currentLuyaoProfilePicture?.file) {
            const {
              id,
              file: { __typename, ...file },
            } = currentLuyaoProfilePicture;
            setTimeout(() => {
              setValue("currentLuyaoProfilePicture", {
                id,
                file,
              });
              defaultFormValue.currentLuyaoProfilePicture = {
                id,
                file,
              };
            }, 0);
          }
          defaultFormValueRef.current = defaultFormValue;
        } else {
          window.location = `${window.location.protocol}//${window.location.host}/login`;
        }
      }, 0);
    },
    onError(error) {
      Alert.notice(error.message.replace("GraphQL error: ", ""));
    },
  });

  useEffect(() => {
    if (memberId) {
      getMember({ variables: { id: Number(memberId) } });
    }
  }, [getMember, memberId]);

  function switchArea() {
    switch (profileType) {
      case "訂單內容":
        return (
          <TitleBox
            title="訂單內容"
            titleAdornment={
              false && (
                <Button onClick={() => setOpenRule((e) => !e)}>到貨規則</Button>
              )
            }
          >
            <PreOrder />
            <RuleDialog open={openRule} onClose={() => setOpenRule(false)} />
          </TitleBox>
        );
      case "會員資料":
        return (
          <TitleBox title="會員資料">
            <MemberInfo
              divisionKey={divisionKey}
              subdivisionsKey={subdivisionsKey}
              countryCodesArray={countryCodesArray}
              defaultFormValueRef={defaultFormValueRef}
              onRefetch={() =>
                getMember({ variables: { id: Number(memberId) } })
              }
            />
          </TitleBox>
        );
      case "消費紀錄":
        return (
          <TitleBox title="消費紀錄">
            <ConsumptionRecords />
          </TitleBox>
        );
      default:
        return null;
    }
  }
  return (
    <Container>
      <Helmet>
        <title>會員中心｜路遙圓創</title>
      </Helmet>
      {loading ? (
        <Box
          display="flex"
          height={`calc(100vh - 64px - ${theme.spacing(3)}px)`}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box padding={`${theme.spacing(2)}px 0`}>
          <Grid container spacing={1}>
            <FormProvider {...memberForm}>
              <Grid item xs={12} md={3}>
                <ProfileBox
                  data={data?.member}
                  profileType={profileType}
                  onChangeType={setProfileType}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                {switchArea()}
              </Grid>
            </FormProvider>
          </Grid>
        </Box>
      )}
    </Container>
  );
}
function ProfileBox({ data, profileType = "", onChangeType = () => {} }) {
  const useStyles = makeStyles((theme) => ({
    avatarCard: {
      position: "relative",
      alignSelf: "center",
      margin: `0 ${theme.spacing(1)}px`,
    },
    profileContent: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    profileButton: {
      position: "absolute",
      bottom: 0,
      zIndex: 1,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(1),
    },
    nameArea: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: `${theme.spacing(1)}px 0`,
    },
    barcode: {
      display: "flex",
      position: "relative",
      width: "100%",
      height: 65,
    },
    list: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      minWidth: 200,
    },
    listItem: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
      "&.Mui-selected": {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },
    avatar: {
      width: 160,
      height: 160,
    },
  }));
  const classes = useStyles();

  const { control, setValue } = useFormContext();

  const profilePicture = useWatch({ control, name: "profilePicture" });
  const currentLuyaoProfilePicture = useWatch({
    control,
    name: "currentLuyaoProfilePicture",
  });
  const [headStickersOpen, setHeadStickersOpen] = useState(false);
  const memberBarcode = padWithZeros(data?.id, 8);

  const profileList = [
    // { label: "訂單" },
    { label: "訂單內容" },
    { label: "會員資料" },
    { label: "消費紀錄" },
  ];

  function getTier(value) {
    switch (value) {
      case "HORSE_POWER":
        return "小馬力";
      case "SUPER_HORSE_POWER":
        return "超級馬力";
      case "COLLECTOR_HORSE_POWER":
        return "藏家馬力";
      default:
        return "查無資料";
    }
  }
  return (
    <Box className={classes.profileContent}>
      <LuyaoProfilePictureFloadingLayer
        open={headStickersOpen}
        currentLuyaoProfilePicture={currentLuyaoProfilePicture}
        onChangeFloatingwindowClose={(value, currentLuyaoProfilePicture) => {
          if (value === "mutation") {
            setValue("currentLuyaoProfilePicture", currentLuyaoProfilePicture);
          }
          setHeadStickersOpen(false);
        }}
      />
      <Card variant="outlined" className={classes.avatarCard}>
        {currentLuyaoProfilePicture || profilePicture ? (
          <CardMedia
            image={
              currentLuyaoProfilePicture?.file
                ? currentLuyaoProfilePicture.file.location
                : profilePicture.location
            }
            className={classes.avatar}
          />
        ) : (
          <CardMedia
            image={require("../images/@luyaoLogo-new.jpg").default}
            className={classes.avatar}
          />
        )}
        {profileType === "會員資料" && (
          <CardActionArea
            className={classes.profileButton}
            component="label"
            onClick={() => setHeadStickersOpen(true)}
          >
            <Typography align="center" variant="body2">
              更換頭貼
            </Typography>
          </CardActionArea>
        )}
      </Card>
      <Box className={classes.nameArea}>
        <Typography gutterBottom style={{ fontWeight: "bold" }}>
          {data?.fullName}
        </Typography>
        <Chip label={getTier(data?.tier)} color="primary" />
      </Box>
      <Box className={classes.barcode}>
        <Box
          style={{
            position: "absolute",
            display: "flex",
            width: "100%",
            top: 0,
            justifyContent: "center",
          }}
        >
          <Box
            style={{ display: "flex", width: 222, justifyContent: "center" }}
          >
            <Barcode value={memberBarcode} displayValue={false} height={55} />
          </Box>
        </Box>
      </Box>
      <List className={classes.list}>
        {profileList.map((item, index) => {
          if (item.label === "消費紀錄") {
            return (
              <ListItem
                key={index}
                button
                selected={item.label === profileType}
                onClick={() => onChangeType(item.label)}
                className={`${classes.listItem}`}
              >
                <ListItemText primary={item.label} />
              </ListItem>
            );
          }
          return (
            <ListItem
              key={index}
              button
              selected={item.label === profileType}
              onClick={() => onChangeType(item.label)}
              className={classes.listItem}
            >
              <ListItemText primary={item.label} />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
const useStylesTable = makeStyles((theme) => ({
  table: {
    minWidth: 300,
  },
  tableHead: {
    backgroundColor: theme.palette.primary.main,
  },
  tableHeadCell: {
    color: theme.palette.primary.contrastText,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  tableBodyCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  tableBodyRow: {
    cursor: "pointer",
  },
  QuestionsContainer: {
    flexDirection: "column",
  },
}));
function PreOrder() {
  const location = useLocation();
  const [selectIndex, setSelectIndex] = useState(0);

  useEffect(() => {
    const status = new URLSearchParams(location.search).get("status");
    const indexes = {
      WAITING_FOR_PAYMENT: 0,
      PROCESSING: 1,
      WAITING_FOR_SHIPMENT: 2,
      SHIPPED: 3,
      CANCELLED: 4,
    };
    if (status && typeof indexes[status] === "number") {
      setSelectIndex(indexes[status]);
    }
  }, [setSelectIndex, location.search]);

  const tabList = [
    { label: "待付款訂單" },
    { label: "預購訂單" },
    { label: "待出貨商品" },
    { label: "出貨商品" },
    { label: "已取消訂單" },
  ];

  function switchTable() {
    switch (selectIndex) {
      case 0:
        return (
          <OrderList
            label="待付款訂單"
            status="WAITING_FOR_PAYMENT"
            setSelectIndex={setSelectIndex}
          />
        );
      case 1:
        return <OrderList label="預購訂單" status="PROCESSING" />;
      case 2:
        return (
          <OrderItemList
            label="待出貨商品"
            status="WAITING_FOR_SHIPMENT_FEE"
            setSelectIndex={setSelectIndex}
          />
        );
      case 3:
        return (
          <OrderItemList
            label="出貨商品"
            status="READY_FOR_SHIPMENT"
            status2="SHIPPED"
          />
        );
      case 4:
        return <OrderList label="已取消訂單" status="CANCELLED" />;
      default:
        return null;
    }
  }

  let orderInstructions;

  switch (selectIndex) {
    case 0:
      orderInstructions = require("../images/order_instructions_1.jpg");
      break;
    case 1:
      orderInstructions = require("../images/order_instructions_2.jpg");
      break;
    case 2:
      orderInstructions = require("../images/order_instructions_3.jpg");
      break;
    case 3:
      orderInstructions = require("../images/order_instructions_4.jpg");
      break;
    case 4:
      orderInstructions = require("../images/order_instructions_5.jpg");
      break;
    default:
      orderInstructions = require("../images/order_instructions_1.jpg");
      break;
  }

  return (
    <>
      <img src={orderInstructions.default} style={{ width: "100%" }} alt="" />
      <Grid container direction="column" spacing={1}>
        <Grid container item>
          <Tabs
            value={selectIndex}
            variant="scrollable"
            scrollButtons="on"
            onChange={(e, n) => setSelectIndex(n)}
            indicatorColor="primary"
          >
            {tabList.map((item, index) => (
              <Tab style={{ minWidth: 120 }} key={index} label={item.label} />
            ))}
          </Tabs>
        </Grid>
        <Grid container item>
          <TableContainer>{switchTable()}</TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
function MemberInfo({
  divisionKey,
  subdivisionsKey,
  countryCodesArray,
  defaultFormValueRef,
  onRefetch = () => {},
}) {
  const Alert = useAlert();
  const history = useHistory();

  const { handleSubmit, reset } = useFormContext();

  const [disabledMobile, setDisabledMobile] = useState(false);

  function _submit(data) {
    Alert.alert("", "確定修改？", [
      {
        text: "確定",
        onPress: () => _updateMember(data),
        type: "ok",
      },
      { text: "取消", type: "cancel" },
    ]);
  }

  function _updateMember(data) {
    const {
      profilePicture,
      fullName,
      nickname,
      district,
      subdistrict,
      address,
      countryCallingCodeObject,
      mobile,
      verificationCode,
      email,
      currentLuyaoProfilePicture,
    } = data;
    const newMemberData = {
      fullName,
      nickname,
      mobile,
      verificationCode,
      email,
      countryCallingCode: countryCallingCodeObject.value,
    };

    if (district || subdistrict || address) {
      newMemberData.address = {
        district: district.value,
        subdistrict: subdistrict.value,
        address,
      };
    }

    if (profilePicture?.location) {
      newMemberData.profilePicture = profilePicture;
    }

    if (currentLuyaoProfilePicture?.file.location) {
      newMemberData.currentLuyaoProfilePictureId =
        currentLuyaoProfilePicture.id;
    }

    updateMember({ variables: { ...newMemberData } });
  }

  const [updateMember, { loading: updateMemberLoading }] = useMutation(
    Update_Member,
    {
      onCompleted({ updateMember }) {
        if (updateMember.success) {
          return Alert.notice("會員資料更新成功！", onRefetch);
        } else if (updateMember.message) {
          if (updateMember.message.search("登入") > -1) {
            return Alert.alert("", updateMember.message, [
              {
                text: "回首頁",
                onPress: () => history.replace("/"),
                type: "ok",
              },
              {
                text: "登入",
                onPress: () => history.replace("/login"),
                type: "cancel",
              },
            ]);
          } else {
            return Alert.notice(updateMember.message);
          }
        } else {
          return Alert.notice("會員資料更新失敗！");
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  function _cancel() {
    const subdistrictObject = subdivisionsKey.find(
      (item) => item.value === defaultFormValueRef.current.subdistrict
    );
    reset({
      ...defaultFormValueRef.current,
      subdistrict: subdistrictObject,
    });
    setDisabledMobile(false);
  }

  return (
    <>
      <LoadingModal loading={updateMemberLoading} />
      <MemberForm
        needVerify
        divisionKey={divisionKey}
        subdivisionsKey={subdivisionsKey}
        countryCodesArray={countryCodesArray}
        disabledMobile={disabledMobile}
        onDisabledMobile={() => setDisabledMobile(true)}
      />
      <CardActions style={{ justifyContent: "flex-end" }}>
        <Button onClick={_cancel}>取消</Button>
        <Button
          onClick={handleSubmit(_submit)}
          variant="contained"
          color="primary"
        >
          修改
        </Button>
      </CardActions>
    </>
  );
}

function TableLoading() {
  return (
    <TableRow>
      <TableCell colSpan={4} align="center">
        <CircularProgress size={20} />
      </TableCell>
    </TableRow>
  );
}
function TableNoData({ label }) {
  return (
    <TableRow>
      <TableCell colSpan={4} align="center">
        暫時沒有{label}
      </TableCell>
    </TableRow>
  );
}
const OrderDialog = forwardRef(function OrderDialog(props, ref) {
  const [open, setOpen] = useState(false);
  const [incomingData, setIncomingData] = useState();
  useImperativeHandle(
    ref,
    () => ({
      open: (value) => {
        setIncomingData(value);
        setOpen(true);
      },
    }),
    []
  );
  const _onClose = useCallback(() => {
    setIncomingData(null);
    setOpen(false);
  }, []);
  return (
    <Dialog fullWidth open={open} onClose={_onClose}>
      {incomingData ? (
        !Boolean(incomingData?.data.product) &&
        !Boolean(incomingData?.data.eggType) ? (
          <>
            <DialogTitle>路遙券</DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid container item justify="center">
                  <img
                    src={"/img/special/ticket.png"}
                    style={{ height: 128 }}
                    alt="ticket"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>單價</FormLabel>
                  <Typography>{incomingData?.data.unitPrice}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>數量</FormLabel>
                  <Typography>{incomingData?.data.amount}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>
                    {incomingData?.status
                      ? incomingData?.status === "SHIPPED"
                        ? "建立時間"
                        : incomingData?.status === "CANCELLED"
                        ? "訂單取消時間"
                        : ""
                      : ""}
                  </FormLabel>
                  <Typography>
                    {incomingData?.data.createdAt
                      ? moment(incomingData?.data.createdAt).format(
                          "YYYY / MM / DD"
                        )
                      : incomingData?.data.updatedAt
                      ? moment(incomingData?.data.updatedAt).format(
                          "YYYY / MM / DD"
                        )
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle>
              {incomingData?.data.product?.name}
              {incomingData?.data.eggType && (
                <>
                  <br />({incomingData?.data.eggType.name})
                </>
              )}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid container item justify="center">
                  <img
                    src={incomingData?.data.product?.images[0]?.location}
                    style={{ height: 128 }}
                    alt=""
                  />
                </Grid>
                {!incomingData?.data.eggType && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormLabel>單價</FormLabel>
                    <Typography>{incomingData?.data.unitPrice}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>數量</FormLabel>
                  <Typography>{incomingData?.data.amount}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>
                    {incomingData?.data.eggType ||
                    incomingData?.data.shipment?.createdAt
                      ? "建立時間"
                      : incomingData?.status === "CANCELLED"
                      ? "訂單取消時間"
                      : incomingData?.status === "PROCESSING"
                      ? "最後結帳時間"
                      : "建立時間"}
                  </FormLabel>
                  <Typography>
                    {incomingData?.data.shipment?.createdAt
                      ? moment(incomingData?.data.shipment.createdAt).format(
                          "YYYY / MM / DD"
                        )
                      : incomingData?.data.createdAt
                      ? moment(incomingData?.data.createdAt).format(
                          "YYYY / MM / DD"
                        )
                      : incomingData?.data.updatedAt
                      ? moment(incomingData?.data.updatedAt).format(
                          "YYYY / MM / DD"
                        )
                      : ""}
                  </Typography>
                </Grid>
                {incomingData?.status === "PROCESSING" &&
                  incomingData?.data.product?.eta && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <FormLabel>預計到貨時間</FormLabel>
                      <Typography>
                        {moment(incomingData?.data.product.eta).format(
                          "YYYY / MM / DD"
                        )}
                      </Typography>
                    </Grid>
                  )}
                {incomingData?.paymentsData &&
                  !incomingData?.paymentsData.success &&
                  moment(
                    moment().tz("Asia/Taipei").format("YYYY-MM-DD")
                  ).isBefore(incomingData?.paymentsData.expireDate) && (
                    <>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormLabel>銀行代碼</FormLabel>
                        <Typography>
                          {incomingData?.paymentsData.bankCode}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormLabel>虛擬帳號</FormLabel>
                        <Typography>
                          {incomingData?.paymentsData.virtualAccount}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormLabel>過期時間</FormLabel>
                        <Typography>
                          {incomingData?.paymentsData.expireDate}
                        </Typography>
                      </Grid>
                    </>
                  )}
                {incomingData?.logisticsRecord && (
                  <>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <FormLabel>物流狀態</FormLabel>
                      <Typography>
                        {incomingData?.logisticsRecord.rtnMsg}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <FormLabel>狀態更新時間</FormLabel>
                      <Typography>
                        {moment(
                          incomingData?.logisticsRecord.statusUpdateTime
                        ).format("YYYY / MM / DD")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <FormLabel>超商名稱</FormLabel>
                      <Typography>{incomingData?.convenienceStore}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
          </>
        )
      ) : null}
      <DialogActions>
        <Button variant="contained" color="primary" onClick={_onClose}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
});
// NOTE reducerDispatch
function _reducerDispatch(state, action) {
  switch (action.type) {
    case "open":
      return action.data;
    case "close":
      return null;
    default:
      return state;
  }
}
function OrderList({ label, status, setSelectIndex }) {
  const Alert = useAlert();
  const memberId = useMemberIdStore(useCallback((state) => state.memberId, []));
  const [retryPaymentDialogOpen, retryPaymentDialogOpenDispatch] = useReducer(
    _reducerDispatch,
    null
  );
  const paymentMethodRef = useRef();
  const productDetailRef = useRef();
  const getRetryPaymentButtonId = document.getElementById("retryPaymentButton");

  function createShipmentSelectStoreResponse() {
    const luyaoCreateShipmentSelectStoreRandomCode = localStorage.getItem(
      `@luyaoCreateShipmentSelectStoreRandomCode`
    );
    if (luyaoCreateShipmentSelectStoreRandomCode) {
      setSelectIndex(2);
    }
  }

  const [getOrders, { data, loading }] = useLazyQuery(GET_ORDERS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ orders }) {
      if (orders) {
        setTimeout(() => {
          const luyaoRetryPaymentSelectStoreRandomCode = localStorage.getItem(
            "@luyaoRetryPaymentSelectStoreRandomCode"
          );
          const luyaoRetryPaymentSelectStoreOrderId = localStorage.getItem(
            "@luyaoRetryPaymentSelectStoreOrderId"
          );
          if (
            luyaoRetryPaymentSelectStoreRandomCode &&
            luyaoRetryPaymentSelectStoreOrderId
          ) {
            const hasOrder = orders.orders.find(
              (item) => item.id === Number(luyaoRetryPaymentSelectStoreOrderId)
            );
            const isFlashSale = Boolean(hasOrder?.event?.maxNumberOfPeople);
            retryPaymentDialogOpenDispatch({
              type: "open",
              data: {
                orderId: Number(luyaoRetryPaymentSelectStoreOrderId),
                isFlashSale,
              },
            });
            if (getRetryPaymentButtonId) {
              getRetryPaymentButtonId.disabled = true;
            }
          }
          createShipmentSelectStoreResponse();
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  useEffect(() => {
    getOrders({
      variables: { status, memberId },
    });
  }, [status, getOrders, memberId]);

  const [cancelOrder, { loading: updateOrderStatusLoading }] = useMutation(
    CANCEL_ORDER,
    {
      onCompleted({ updateOrderStatus }) {
        if (updateOrderStatus.success) {
          getOrders({
            variables: { status, memberId },
          });
        } else {
          Alert.notice(updateOrderStatus.message);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );
  const orders = data && data.orders.orders;
  const classesTable = useStylesTable();

  const [retryPayment, { loading: retryPaymentLoading }] = useMutation(
    RETRY_PAYMENT,
    {
      onCompleted({ retryPayment }) {
        if (retryPayment) {
          localStorage.removeItem("@luyaoRetryPaymentSelectStoreOrderId");
          window.location =
            retryPayment.paymentUrl +
            "&paymentMethod=" +
            paymentMethodRef.current;
        }
      },
      onError(error) {
        if (getRetryPaymentButtonId) {
          getRetryPaymentButtonId.disabled = false;
        }
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  function SwitchTableRow() {
    if (loading) {
      return <TableLoading />;
    } else if (orders && orders.length > 0) {
      return orders.map((order, index) => {
        const oddIndex = index % 2 === 1;
        const lastPaymentsData = order.payments[order.payments.length - 1];
        return (
          <TableRow className={classesTable.tableBodyRow} selected={oddIndex}>
            {order.items.map((item) => (
              <TableCell
                className={classesTable.tableBodyCell}
                align="center"
                onClick={() => {
                  productDetailRef?.current?.open({
                    data: item,
                    paymentsData: lastPaymentsData,
                    status,
                  });
                }}
              >
                <spam>
                  {item.product?.name || "路遙券"} × {item.amount}
                  <br />
                </spam>
              </TableCell>
            ))}
            {status === "CANCELLED" && (
              <TableCell className={classesTable.tableBodyCell} align="center">
                {order.items.map((item) => (
                  <spam>
                    {moment(item.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
                    <br />
                  </spam>
                ))}
              </TableCell>
            )}
            {status === "PROCESSING" && (
              <TableCell className={classesTable.tableBodyCell} align="center">
                {order.items.map((item) => (
                  <spam>
                    {item.product?.eta &&
                      moment(item.product.eta).format("YYYY-MM-DD")}
                    <br />
                  </spam>
                ))}
              </TableCell>
            )}
            {status === "PROCESSING" && (
              <TableCell className={classesTable.tableBodyCell} align="center">
                {order.items.map((item) => (
                  <spam>
                    {moment(item.updatedAt).format("YYYY-MM-DD")}
                    <br />
                  </spam>
                ))}
              </TableCell>
            )}
            {status === "WAITING_FOR_PAYMENT" && (
              <TableCell className={classesTable.tableBodyCell} align="right">
                <Button
                  id="retryPaymentButton"
                  style={{ marginLeft: "1vh", marginRight: "1vh" }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (
                      order.items.length === 1 &&
                      !Boolean(order.items[0].product) &&
                      Boolean(order.items[0].event)
                    ) {
                      Alert.alert("", "確認要重新付款？", [
                        {
                          text: "確認",
                          onPress: () => {
                            if (getRetryPaymentButtonId) {
                              getRetryPaymentButtonId.disabled = true;
                            }
                            paymentMethodRef.current = "Credit";
                            retryPayment({
                              variables: {
                                orderId: order.id,
                                paymentMethod: "Credit",
                              },
                            });
                          },
                          type: "ok",
                        },
                        { text: "取消", type: "cancel" },
                      ]);
                    } else {
                      const isFlashSale = Boolean(
                        order?.event?.maxNumberOfPeople
                      );
                      // NOTE 付款方式
                      const allPaymentMethods = [];
                      emptyArray(order?.items).forEach((item) => {
                        emptyArray(item.product?.paymentMethods).forEach(
                          (item2) => {
                            allPaymentMethods.push({
                              label: paymentMethodEnum[item2],
                              value: item2 === "CREDIT_CARD" ? "Credit" : item2,
                            });
                          }
                        );
                      });
                      const paymentMethods = allPaymentMethods.filter(
                        (obj, index, self) =>
                          index === self.findIndex((t) => t.value === obj.value)
                      );
                      setTimeout(() => {
                        retryPaymentDialogOpenDispatch({
                          type: "open",
                          data: {
                            orderId: order.id,
                            isFlashSale,
                            paymentMethods:
                              paymentMethods.length === 0
                                ? [
                                    {
                                      label: "信用卡",
                                      value: "Credit",
                                    },
                                  ]
                                : paymentMethods,
                          },
                        });
                      }, 0);
                    }
                  }}
                >
                  付款
                </Button>
                <Button
                  style={{ marginLeft: "1vh", marginRight: "1vh" }}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    Alert.alert("通知", "您確定要取消這張訂單嗎？", [
                      {
                        text: "確定",
                        type: "ok",
                        onPress: () =>
                          cancelOrder({ variables: { id: order.id } }),
                      },
                      {
                        text: "返回",
                        type: "cancel",
                      },
                    ])
                  }
                >
                  取消
                </Button>
              </TableCell>
            )}
          </TableRow>
        );
      });
    } else {
      return <TableNoData label={label} />;
    }
  }
  return (
    <Table className={classesTable.table}>
      <LoadingFloating
        loading={retryPaymentLoading || updateOrderStatusLoading}
      />
      <CreateOrderFloatingLayer
        open={Boolean(retryPaymentDialogOpen)}
        content={"重新付款"}
        type={"RetryPayment"}
        orderId={retryPaymentDialogOpen?.orderId}
        isFlashSale={retryPaymentDialogOpen?.isFlashSale}
        paymentMethods={retryPaymentDialogOpen?.paymentMethods}
        onChangeFloatingwindowClose={(
          value,
          paymentMethod,
          shipmentInput,
          otherValue
        ) => {
          retryPaymentDialogOpenDispatch({ type: "close" });
          if (value === "mutation") {
            paymentMethodRef.current = paymentMethod;
            if (getRetryPaymentButtonId) {
              getRetryPaymentButtonId.disabled = true;
            }
            const luyaoRetryPaymentSelectStoreOrderId = localStorage.getItem(
              "@luyaoRetryPaymentSelectStoreOrderId"
            );
            retryPayment({
              variables: {
                orderId:
                  otherValue ?? Number(luyaoRetryPaymentSelectStoreOrderId),
                paymentMethod,
                shipmentInput,
              },
            });
          } else {
            if (getRetryPaymentButtonId) {
              getRetryPaymentButtonId.disabled = false;
            }
          }
        }}
      />
      <TableHead className={classesTable.tableHead}>
        <TableRow>
          <TableCell className={classesTable.tableHeadCell} align="center">
            商品
          </TableCell>
          {status === "CANCELLED" && (
            <TableCell className={classesTable.tableHeadCell} align="center">
              訂單取消時間
            </TableCell>
          )}
          {status === "PROCESSING" && (
            <TableCell className={classesTable.tableHeadCell} align="center">
              預計到貨時間
            </TableCell>
          )}
          {status === "PROCESSING" && (
            <TableCell className={classesTable.tableHeadCell} align="center">
              最後結帳時間
            </TableCell>
          )}
          {status === "WAITING_FOR_PAYMENT" && (
            <TableCell className={classesTable.tableHeadCell} />
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        <SwitchTableRow />
        <OrderDialog ref={productDetailRef} />
      </TableBody>
    </Table>
  );
}

function OrderItemList({ label, status, status2, setSelectIndex }) {
  const Alert = useAlert();
  const history = useHistory();
  const {
    control: orderControl,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      selectedOrderItems: [],
      selectedOrderItemsDeliveryFee: [],
    },
  });
  const [dialog, setDialog] = useState(false);
  const [data, setData] = useState({ orderItems: {} });
  const [selectPaymentMethods, setSelectPaymentMethods] = useState([]);
  const paymentMethodRef = useRef();
  const orderItemProductDetailRef = useRef();
  useEffect(() => {
    if (status) {
      setData({ orderItems: {} });
    }
  }, [status]);

  const [getOrderItems, { loading }] = useLazyQuery(GET_ORDER_ITEMS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ orderItems }) {
      if (orderItems) {
        setTimeout(() => {
          setData({ orderItems });
          const luyaoCreateShipmentSelectStoreRandomCode = localStorage.getItem(
            `@luyaoCreateShipmentSelectStoreRandomCode`
          );
          if (luyaoCreateShipmentSelectStoreRandomCode) {
            setDialog(true);
          }
          if (status2) {
            getOrderItems2({ variables: { status: status2 } });
          }
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  useEffect(() => {
    if (!Boolean(data.orderItems.orderItems)) {
      getOrderItems({ variables: { status } });
    }
  }, [data, getOrderItems, status]);

  const [getOrderItems2, { loading: orderItemsLoading }] = useLazyQuery(
    GET_ORDER_ITEMS,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ orderItems }) {
        if (orderItems) {
          setTimeout(() => {
            if (data.orderItems.orderItems) {
              setData({
                orderItems: {
                  orderItems: data.orderItems.orderItems.concat(
                    orderItems.orderItems
                  ),
                },
              });
            }
          }, 0);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );
  const [
    getProductRelation,
    { data: productRelationData, loading: productRelationLoading },
  ] = useLazyQuery(GET_PRODUCTRELATION, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });
  const orderItems = data && data.orderItems && data.orderItems.orderItems;
  const classesTable = useStylesTable();
  function SwitchTableRow() {
    if (loading || orderItemsLoading || productRelationLoading) {
      return <TableLoading />;
    } else if (orderItems && orderItems.length > 0) {
      return orderItems.map((item, index) => {
        const oddIndex = index % 2 === 1;
        const logisticsRecord =
          Array.isArray(item.shipment?.logisticsRecords) &&
          item.shipment.logisticsRecords[
            item.shipment.logisticsRecords.length - 1
          ];
        const convenienceStore = item.shipment?.convenienceStore;
        return (
          <TableRow
            className={classesTable.tableBodyRow}
            selected={oddIndex}
            hover
          >
            {status === "WAITING_FOR_SHIPMENT_FEE" && (
              <>
                <TableCell
                  className={classesTable.tableBodyCell}
                  padding="checkbox"
                >
                  <FieldCache
                    control={orderControl}
                    name="selectedOrderItems"
                    render={(selectedOrderItems) => (
                      <Checkbox
                        size="small"
                        checked={selectedOrderItems.includes(item.id)}
                        onChange={() => {
                          if (
                            !selectedOrderItems.includes(item.id) &&
                            selectedOrderItems.length > 0
                          ) {
                            if (Boolean(productRelationData)) {
                              if (
                                !productRelationData.productRelation
                                  .map((item2) => item2.id)
                                  .includes(item.product.id) &&
                                !getValues("selectedOrderItemsDeliveryFee")
                                  .map((item3) => item3.productId)
                                  .includes(item.product.id)
                              ) {
                                return Alert.notice("活動期間不開放合併訂單！");
                              }
                            }
                          }
                          if (item.product.shippable) {
                            const hasItemId = getValues(
                              "selectedOrderItemsDeliveryFee"
                            ).find((item2) => item2.id === item.id);
                            if (hasItemId) {
                              getValues(
                                "selectedOrderItemsDeliveryFee"
                              ).forEach((item2, index2) => {
                                if (item2.id === item.id) {
                                  getValues(
                                    "selectedOrderItemsDeliveryFee"
                                  ).splice(index2, 1);
                                }
                              });
                            } else {
                              if (
                                getValues("selectedOrderItemsDeliveryFee")
                                  .length === 0
                              ) {
                                getProductRelation({
                                  variables: { productId: item.product.id },
                                });
                              }
                              getValues("selectedOrderItemsDeliveryFee").push({
                                id: item.id,
                                deliveryFee: item.product.deliveryFee,
                                productId: item.product.id,
                              });
                            }
                            setValue("selectedOrderItemsDeliveryFee", [
                              ...getValues("selectedOrderItemsDeliveryFee"),
                            ]);

                            if (selectedOrderItems.includes(item.id)) {
                              setSelectPaymentMethods((e) => {
                                return e.filter(
                                  (i) => i.id !== item?.product?.id
                                );
                              });
                              setValue(
                                "selectedOrderItems",
                                selectedOrderItems.filter(
                                  (id) => id !== item.id
                                )
                              );
                            } else {
                              setSelectPaymentMethods((e) => {
                                const newDate = [...e];
                                newDate.push({
                                  id: item?.product?.id,
                                  paymentMethods: emptyArray(
                                    item?.product?.paymentMethods
                                  ),
                                });
                                return newDate;
                              });
                              setValue("selectedOrderItems", [
                                ...selectedOrderItems,
                                item.id,
                              ]);
                            }
                          } else {
                            return Alert.notice("這項商品尚無法出貨！");
                          }
                        }}
                      />
                    )}
                  />
                </TableCell>
                <TableCell
                  className={classesTable.tableBodyCell}
                  align="center"
                  onClick={() => {
                    orderItemProductDetailRef?.current?.open({
                      data: item,
                      paymentsData:
                        item.shipment &&
                        item.shipment.payments[
                          item.shipment.payments.length - 1
                        ],
                      logisticsRecord,
                      convenienceStore,
                      status,
                    });
                  }}
                >
                  {item.eggType?.name}
                </TableCell>
              </>
            )}
            {status2 === "READY_FOR_SHIPMENT" && (
              <TableCell
                className={classesTable.tableBodyCell}
                align="center"
                onClick={() => {
                  orderItemProductDetailRef?.current?.open({
                    data: item,
                    paymentsData:
                      item.shipment &&
                      item.shipment.payments[item.shipment.payments.length - 1],
                    logisticsRecord,
                    convenienceStore,
                    status,
                  });
                }}
              >
                {item.eggType?.name}
              </TableCell>
            )}
            <TableCell
              className={classesTable.tableBodyCell}
              align="center"
              onClick={() => {
                orderItemProductDetailRef?.current?.open({
                  data: item,
                  paymentsData:
                    item.shipment &&
                    item.shipment.payments[item.shipment.payments.length - 1],
                  logisticsRecord,
                  convenienceStore,
                  status,
                });
              }}
            >
              {item.product ? item.product.name : item.eggType ? "" : "路遙券"}
            </TableCell>
            <TableCell
              className={classesTable.tableBodyCell}
              width={16 + 48}
              align="center"
            >
              {item.amount}
            </TableCell>
            {(status === "SHIPPED" || status === "READY_FOR_SHIPMENT") && (
              <TableCell className={classesTable.tableBodyCell} align="center">
                {item.product || item.eggType
                  ? item.shipment
                    ? item.shipment.status === "WAITING_FOR_SHIPMENT"
                      ? "待出貨"
                      : item.shipment.status === "SHIPPED"
                      ? "已出貨"
                      : ""
                    : ""
                  : "已出貨"}
              </TableCell>
            )}
          </TableRow>
        );
      });
    } else {
      return <TableNoData label={label} />;
    }
  }

  const [createShipment, { loading: createShipmentLoading }] = useMutation(
    CREATE_SHIPMENT,
    {
      onCompleted({ createShipment }) {
        if (createShipment) {
          if (createShipment.paymentUrl) {
            window.location =
              createShipment.paymentUrl +
              "&paymentMethod=" +
              paymentMethodRef.current;
          } else {
            setSelectIndex(3);
          }
        } else {
          return Alert.alert("", "請先登入後再操作。", [
            {
              text: "回首頁",
              onPress: () => history.replace("/"),
              type: "ok",
            },
            {
              text: "登入",
              onPress: () => history.replace("/login"),
              type: "cancel",
            },
          ]);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );
  // NOTE 付款方式
  const paymentMethods = useMemo(() => {
    const allPaymentMethods = [];
    selectPaymentMethods.forEach((item) => {
      allPaymentMethods.push({
        label: paymentMethodEnum[item],
        value: item === "CREDIT_CARD" ? "Credit" : item,
      });
    });
    const uniqueArray = allPaymentMethods.filter(
      (obj, index, self) =>
        index === self.findIndex((t) => t.value === obj.value)
    );
    return uniqueArray.length === 0
      ? [
          {
            label: "信用卡",
            value: "Credit",
          },
        ]
      : uniqueArray;
  }, [selectPaymentMethods]);

  return (
    <>
      <LoadingModal loading={createShipmentLoading} />
      <Table className={classesTable.table}>
        <TableHead className={classesTable.tableHead}>
          <TableRow>
            {status === "WAITING_FOR_SHIPMENT_FEE" && (
              <>
                <TableCell
                  className={classesTable.tableHeadCell}
                  padding="checkbox"
                />
                <TableCell
                  className={classesTable.tableHeadCell}
                  align="center"
                />
              </>
            )}
            {status2 === "READY_FOR_SHIPMENT" && (
              <TableCell
                className={classesTable.tableHeadCell}
                align="center"
              />
            )}
            <TableCell className={classesTable.tableHeadCell} align="center">
              商品名稱
            </TableCell>
            <TableCell className={classesTable.tableHeadCell} align="center">
              數量
            </TableCell>
            {(status === "SHIPPED" || status === "READY_FOR_SHIPMENT") && (
              <TableCell className={classesTable.tableHeadCell} align="center">
                狀態
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <SwitchTableRow />
          <OrderDialog ref={orderItemProductDetailRef} />
        </TableBody>
        {status === "WAITING_FOR_SHIPMENT_FEE" && (
          <TableFooter>
            <TableRow>
              <TableCell
                colSpan={6}
                align="left"
                className={classesTable.tableBodyCell}
              >
                <FieldCache
                  control={orderControl}
                  name="selectedOrderItems"
                  render={(selectedOrderItems) => (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setDialog(true)}
                      disabled={
                        selectedOrderItems.length < 1 || productRelationLoading
                      }
                    >
                      {selectedOrderItems.length < 1
                        ? "請至少選擇一項"
                        : selectedOrderItems.length === 1
                        ? "單獨出貨"
                        : "合併出貨"}
                    </Button>
                  )}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
      <CreateOrderFloatingLayer
        orderControl={orderControl}
        open={dialog}
        content={"合併出貨"}
        type={"CreateShipment"}
        onChangeFloatingwindowClose={(
          value,
          paymentMethod,
          shipmentInput,
          otherValue
        ) => {
          setDialog(false);
          if (value === "mutation") {
            paymentMethodRef.current = paymentMethod;
            const luyaoCreateShipmentSelectStoreSelectedOrderItems =
              localStorage.getItem(
                `@luyaoCreateShipmentSelectStoreSelectedOrderItems`
              );
            createShipment({
              variables: {
                shipmentInput,
                orderItemIds:
                  Array.isArray(otherValue) && otherValue.length > 0
                    ? otherValue
                    : JSON.parse(
                        luyaoCreateShipmentSelectStoreSelectedOrderItems
                      ),
              },
            });
          }
        }}
        paymentMethods={paymentMethods}
      />
    </>
  );
}

// ANCHOR 消費紀錄
function ConsumptionRecords() {
  const memberId = useMemberIdStore(useCallback((state) => state.memberId, []));
  const [page, setPage] = useState(1);
  const [
    getMemberRaisingRecords,
    { data: memberRaisingRecordsData, loading: memberRaisingRecordsLoading },
  ] = useLazyQuery(GET_MEMBER_RAISING_RECORDS, {
    fetchPolicy: "network-only",
    variables: {
      page,
    },
    onError() {
      return null;
    },
  });
  useEffect(() => {
    if (memberId) {
      getMemberRaisingRecords({
        variables: { memberId },
      });
    }
  }, [memberId, getMemberRaisingRecords]);
  const maxPage = useMemo(
    () => memberRaisingRecordsData?.memberRaisingRecords?.pageCount || 1,
    [memberRaisingRecordsData]
  );
  // handle pages 頁數
  const handlePrev = useCallback(() => {
    if (page <= 1) {
      setPage(maxPage);
    } else {
      setPage((e) => e - 1);
    }
  }, [page, maxPage]);
  const handleNext = useCallback(() => {
    if (page < maxPage) {
      setPage((e) => e + 1);
    } else {
      setPage(1);
    }
  }, [page, maxPage]);

  // styles
  const useStyles = makeStyles({
    tableBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      "& .MuiTableCell-root": {
        border: 0 + "!important",
        padding: "8px 0",
      },
    },
    paginationBox: {
      display: "flex",
      justifyContent: "center",
      maringTop: "24px",
      alignItems: "center",
      "& svg": {
        width: 20,
        height: 20,
        cursor: "pointer",
        transform: "scale(0.75)",
      },
    },
  });
  const classes = useStyles();
  // -- end styles
  return (
    // MuiTableCell-root
    <Box minHeight="450px" className={classes.tableBox}>
      <CustomTableComponent
        minWidth={160}
        data={emptyArray(
          memberRaisingRecordsData?.memberRaisingRecords?.contents
        )}
        header={["日期", "類別", "品項"]}
        columnWidths={[100, 100, 100]}
        columns={[
          (item) =>
            item.createdAt && format(parseISO(item.createdAt), "yyyy/LL/dd"),
          (item) => (item.tickets ? "購買" : item.eggs ? "兌換" : ""),
          (item) =>
            item.tickets
              ? `路遙券*${item.tickets}張`
              : item.eggs
              ? `蛋蛋*${item.eggs}抽`
              : "",
        ]}
        emptyItem={{
          children: memberRaisingRecordsLoading ? "讀取中" : "暫時沒有資料",
          align: "center",
        }}
      />
      <Box className={classes.paginationBox}>
        <ArrowLeft onClick={handlePrev} />

        <Box width="150px">
          <Typography variant="body1" align="center" component="p">
            {page}
          </Typography>
        </Box>
        <ArrowRight onClick={handleNext} />
      </Box>
    </Box>
  );
}
