import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Tabs,
  Tab,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { FormProvider, useForm } from "react-hook-form";
import { gql, useQuery, useMutation } from "@apollo/client";
// NOTE 工具
import formErrorFocus from "../../../utils/formErrorFocus";
// NOTE 組件
import { useAlert } from "../../../component/Alert";
import { Card, CardContent } from "../../../component/Card";
import LoadingModal from "../../../component/LoadingModal";
import Button from "../../../component/Button";
import BasicSettings from "./BasicSettings";
import CardSettings from "./CardSettings";
import ResultSetting from "./ResultSetting";

// SECTION apollo
// NOTE 對戰素材
/** - 對戰素材 */
const GET_BATTLE_ASSETS = gql`
  query battleAssets {
    battleAssets {
      # "ID"
      id
      # "類型"
      type
      # "卡片類型"
      cardType
      # "參賽者A卡牌1"
      cardA1
      # "參賽者A卡牌2"
      cardA2
      # "參賽者B卡牌1"
      cardB1
      # "參賽者B卡牌2"
      cardB2
      # "結果"
      result
      # "網址"
      url
    }
  }
`;
// NOTE 儲存素材
/** - 儲存素材 */
const SAVE_BATTLE_ASSETS = gql`
  mutation saveBattleAssets($battleAssetInputs: [BattleAssetInput]!) {
    saveBattleAssets(battleAssetInputs: $battleAssetInputs) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;
// !SECTION

export const arr1 = ["COURAGE", "PERSEVERANCE", "LEARNING"]; // 剪刀 -> COURAGE, 石頭 -> PERSEVERANCE, 布 -> LEARNING
export const arr2 = ["APTITUDE", "BACKGROUND", "LUCK"]; // 剪刀 -> APTITUDE, 石頭 -> BACKGROUND, 布 -> LUCK

const defaultBasic = [
  {
    type: "OPENING_ANIMATION",
    url: "",
  },
  {
    type: "BACKGROUND",
    url: "",
  },
  {
    type: "BACKGROUND",
    url: "",
  },
];

const defaultCards = [
  {
    type: "CARDBACK",
    url: "",
  },
  {
    type: "CARDBACK",
    url: "",
  },
  {
    type: "CARDFRONT",
    cardType: "COURAGE",
    url: "",
  },
  {
    type: "CARDFRONT",
    cardType: "PERSEVERANCE",
    url: "",
  },
  {
    type: "CARDFRONT",
    cardType: "LEARNING",
    url: "",
  },
  {
    type: "CARDFRONT",
    cardType: "APTITUDE",
    url: "",
  },
  {
    type: "CARDFRONT",
    cardType: "BACKGROUND",
    url: "",
  },
  {
    type: "CARDFRONT",
    cardType: "LUCK",
    url: "",
  },
];

const defaultCategorizedPairs = arr1
  .flatMap((item1) =>
    arr2.flatMap((item2) =>
      arr1.flatMap((item3) =>
        arr2.map((item4) => ({ item1, item2, item3, item4 }))
      )
    )
  )
  .map((item) => ({
    type: "RESULT",
    cardA1: item.item1,
    cardA2: item.item2,
    cardB1: item.item3,
    cardB2: item.item4,
    url: "",
    result: undefined,
  }));

/** - 對戰素材 */
export default function BattleAssetsMaterial({ swtichContentDispatch }) {
  const { notice } = useAlert();
  const cacheRef = useRef({
    swtichContentDispatch,
    notice,
  });
  const [select, setSelect] = useState(0);
  const form = useForm({
    defaultValues: {
      battleAssetInputs: [
        ...defaultBasic,
        ...defaultCards,
        ...defaultCategorizedPairs,
      ],
    },
  });
  const { reset, setValue, handleSubmit } = form;

  useEffect(() => {
    cacheRef.current.swtichContentDispatch = swtichContentDispatch;
    cacheRef.current.notice = notice;
  }, [swtichContentDispatch, notice]);

  const { data: battleAssetsData, loading } = useQuery(GET_BATTLE_ASSETS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      cacheRef.current.notice?.(error.message.replace("GraphQL error: ", ""));
    },
  });

  const battleAssets = useMemo(
    () => battleAssetsData?.battleAssets ?? [],
    [battleAssetsData]
  );

  useEffect(() => {
    if (battleAssets.length > 0) {
      const newBattleAssetInputs = battleAssets.map(
        ({ __typename, ...battleAsset }) => ({ ...battleAsset })
      );
      setValue("battleAssetInputs", newBattleAssetInputs);
    }
  }, [battleAssets, setValue]);

  const _goBack = useCallback(() => {
    cacheRef.current.swtichContentDispatch?.({
      type: undefined,
    });
    reset();
  }, [reset]);

  const [saveBattleAssetsFn, { loading: saveBattleAssetsLoading }] =
    useMutation(SAVE_BATTLE_ASSETS, {
      onCompleted({ saveBattleAssets: { message } }) {
        if (message) {
          cacheRef.current.notice?.(message);
        } else {
          cacheRef.current.notice?.("儲存成功");
          _goBack();
        }
      },
      onError() {
        return null;
      },
    });
  const _saveBattleAssets = useCallback(
    ({ battleAssetInputs }) => {
      saveBattleAssetsFn({ variables: { battleAssetInputs } });
    },
    [saveBattleAssetsFn]
  );

  if (loading) {
    return (
      <div>
        <CircularProgress color="secondary" />
      </div>
    );
  }
  return (
    <FormProvider {...form}>
      <Card>
        <LoadingModal loading={saveBattleAssetsLoading} />
        <CardContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <IconButton onClick={_goBack} size="small">
                <KeyboardBackspaceIcon />
              </IconButton>
              <Typography style={{ paddingLeft: "10px" }}>素材管理</Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onPress={handleSubmit(_saveBattleAssets, formErrorFocus)}
            >
              儲存
            </Button>
          </Box>
          <Grid container spacing={3} style={{ marginTop: 4 }}>
            <Grid container item>
              <Tabs value={select} onChange={(e, n) => setSelect(n)}>
                <Tab label="基本設定" />
                <Tab label="卡牌設定" />
                <Tab label="結果設定" />
              </Tabs>
            </Grid>
            <Grid container item>
              {Boolean(select === 0) && (
                <Grid container item spacing={3}>
                  <BasicSettings />
                </Grid>
              )}
              {Boolean(select === 1) && (
                <Grid container item spacing={3}>
                  <CardSettings />
                </Grid>
              )}
              {Boolean(select === 2) && (
                <Card style={{ width: "100%" }}>
                  <CardContent>
                    <Grid container item xs={12}>
                      <ResultSetting />
                    </Grid>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </FormProvider>
  );
}
