import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  Fragment,
} from "react";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  makeStyles,
  Divider,
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { gql, useQuery, useMutation } from "@apollo/client";
import moment from "moment";
// NOTE 工具
import { battleResultEnum } from "../../utils/options/battleResult";
// NOTE 組件
import { useAlert } from "../../component/Alert";
import { Card, CardContent } from "../../component/Card";
import LoadingModal from "../../component/LoadingModal";
import { CustomTextField, InputTitle } from "../../component/Form";
import Button from "../../component/Button";

// SECTION apollo
// NOTE 對戰房
/** - 對戰房 */
const GET_BATTLE_ROOM = gql`
  query battleRoom($id: Int!) {
    battleRoom(id: $id) {
      # "ID"
      id
      # "名稱"
      name
      # "密碼"
      password
      # "對戰紀錄"
      battleRecodes {
        # "ID"
        id
        # "參賽者A"
        participantA {
          # "ID"
          id
          # "姓名"
          fullName
          # "手機號碼"
          mobile
        }
        # "參賽者B"
        participantB {
          # "ID"
          id
          # "姓名"
          fullName
          # "手機號碼"
          mobile
        }
        # "參賽者A卡牌1"
        cardA1
        # "參賽者A卡牌2"
        cardA2
        # "參賽者B卡牌1"
        cardB1
        # "參賽者B卡牌2"
        cardB2
        # "結果"
        result
        # "建立時間"
        createdAt
      }
    }
  }
`;
// NOTE 儲存對戰房
/** - 儲存對戰房 */
const SAVE_BATTLE_ROOM = gql`
  mutation saveBattleRoom($battleRoomInput: BattleRoomInput!) {
    saveBattleRoom(battleRoomInput: $battleRoomInput) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;
// !SECTION

/** - 對戰欄位 */
export default function BattleAssetsForm({
  swtichContent,
  swtichContentDispatch,
}) {
  const { notice } = useAlert();
  const cacheRef = useRef({
    swtichContentDispatch,
  });
  const form = useForm({
    defaultValues: {
      name: "",
      password: null,
    },
  });
  const { reset, setValue, handleSubmit } = form;

  useEffect(() => {
    cacheRef.current.swtichContentDispatch = swtichContentDispatch;
  }, [swtichContentDispatch]);
  const { data: battleRoomData, loading } = useQuery(GET_BATTLE_ROOM, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip: !swtichContent?.id,
    variables: { id: swtichContent?.id },
    onError(error) {
      notice(error.message.replace("GraphQL error: ", ""));
    },
  });

  const battleRoom = useMemo(
    () => battleRoomData?.battleRoom ?? {},
    [battleRoomData]
  );
  useEffect(() => {
    if (battleRoom.id) {
      setValue("id", battleRoom.id);
      setValue("name", battleRoom.name);
      setValue("password", battleRoom.password);
    }
  }, [battleRoom, setValue]);

  const _goBack = useCallback(() => {
    cacheRef.current.swtichContentDispatch?.({
      type: undefined,
    });
    reset();
  }, [reset]);

  const [saveBattleRoomFn, { loading: saveBattleRoomLoading }] = useMutation(
    SAVE_BATTLE_ROOM,
    {
      onCompleted({ saveBattleRoom: { message } }) {
        if (message) {
          notice(message);
        } else {
          notice("儲存成功");
          _goBack();
        }
      },
      onError() {
        return null;
      },
    }
  );
  const _saveBattleRoom = useCallback(
    (battleRoomInput) => {
      saveBattleRoomFn({ variables: { battleRoomInput } });
    },
    [saveBattleRoomFn]
  );

  if (loading) {
    return (
      <div>
        <CircularProgress color="secondary" />
      </div>
    );
  }
  return (
    <FormProvider {...form}>
      <Card>
        <LoadingModal loading={saveBattleRoomLoading} />
        <CardContent>
          <Box display="flex" flexDirection="row" alignItems="center">
            <IconButton onClick={_goBack} size="small">
              <KeyboardBackspaceIcon />
            </IconButton>
            <Typography style={{ paddingLeft: "10px" }}>{`${
              swtichContent?.id
                ? `編輯 ${battleRoom.name} 對戰內容`
                : "新增對戰"
            }`}</Typography>
          </Box>
          <Grid container spacing={3} style={{ marginTop: 5 }}>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="name"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="名稱" required />
                    <CustomTextField
                      {...field}
                      fullWidth
                      error={error}
                      helperText={error?.message}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="password"
                rules={{
                  required: "必填欄位 !",
                }}
                render={({ field, fieldState: { error } }) => (
                  <div>
                    <InputTitle label="密碼" required />
                    <CustomTextField
                      {...field}
                      fullWidth
                      error={error}
                      helperText={error?.message}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid container item justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onPress={handleSubmit(_saveBattleRoom)}
              >
                儲存
              </Button>
            </Grid>
            <Grid container item>
              <Grid item xs={12}>
                <DropdownItem
                  title={"對戰紀錄"}
                  data={battleRoom.battleRecodes}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </FormProvider>
  );
}

function DropdownItem({ title, data = [] }) {
  const useStyles = makeStyles({
    accordion: {
      overflow: "hidden",
    },
    listPadding: {
      paddingTop: "3px",
      paddingBottom: "3px",
    },
    dividerBox: {
      width: "100%",
      paddingTop: "8px",
      paddingBottom: "8px",
    },
  });
  const classes = useStyles();
  return (
    <Fragment>
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Typography>
                {title} ({data.length})
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {data.map((item, index) => (
              <Fragment key={"data" + index}>
                <Grid container item xs={12} sm={12}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    direction="row"
                    classes={{ root: classes.listPadding }}
                  >
                    <Typography>參賽者 A 姓名</Typography>
                    <Typography>{item?.participantA?.fullName}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    direction="row"
                    classes={{ root: classes.listPadding }}
                  >
                    <Typography>參賽者 A 手機號碼</Typography>
                    <Typography>{item?.participantA?.mobile}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    direction="row"
                    classes={{ root: classes.listPadding }}
                  >
                    <Typography>參賽者 B 姓名</Typography>
                    <Typography>{item?.participantB?.fullName}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    direction="row"
                    classes={{ root: classes.listPadding }}
                  >
                    <Typography>參賽者 B 手機號碼</Typography>
                    <Typography>{item?.participantB?.mobile}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    direction="row"
                    classes={{ root: classes.listPadding }}
                  >
                    <Typography>結果</Typography>
                    <Typography>{battleResultEnum[item?.result]}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    direction="row"
                    classes={{ root: classes.listPadding }}
                  >
                    <Typography>對戰時間</Typography>
                    <Typography>
                      {moment(item?.createdAt).format("YYYY/MM/DD HH:mm")}
                    </Typography>
                  </Grid>
                </Grid>
                <Box classes={{ root: classes.dividerBox }}>
                  <Divider />
                </Box>
              </Fragment>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
}
