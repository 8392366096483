import { useCallback, useEffect } from "react";
// apollo
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

// hooks
import { useCartId } from "../hooks/cart";
import { useRefreshToken } from "../hooks/refreshToken";
// zustand
import useTokenStore from "../zustand/useTokenStore";

const GET_MEMBER = gql`
  query member {
    member {
      id
    }
  }
`;

export default function AuthTokenProvider({ children }) {
  useCartId();
  useRefreshToken();
  const memberToken = useTokenStore(
    useCallback((state) => state.memberToken, [])
  );
  const consoleToken = useTokenStore(
    useCallback((state) => state.consoleToken, [])
  );

  const [getMember] = useLazyQuery(GET_MEMBER, {
    fetchPolicy: "network-only",
    onCompleted({ member }) {
      setTimeout(() => {
        if (!member && !window.location.pathname.includes("login")) {
          if (window.location.pathname.includes("console")) {
            useTokenStore.getState().cleanToken();
            useTokenStore
              .getState()
              .cleanConsoleToken()
              .then((result) => {
                if (result) {
                  window.location = `${window.location.protocol}//${window.location.host}/console`;
                }
              });
          } else {
            useTokenStore.getState().cleanConsoleToken();
            useTokenStore
              .getState()
              .cleanToken()
              .then((result) => {
                if (result) {
                  window.location = `${window.location.protocol}//${window.location.host}/login`;
                }
              });
          }
        }
      }, 0);
    },
  });
  useEffect(() => {
    const interval = setInterval(() => {
      if (memberToken || consoleToken) {
        getMember();
      }
    }, 1800000);
    return () => clearInterval(interval);
  }, [getMember, memberToken, consoleToken]);
  return children;
}
