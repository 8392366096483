import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import {
  FormLabel,
  AccordionDetails,
  Typography,
  AccordionSummary,
  Accordion,
  makeStyles,
  CircularProgress,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment";

import { useAlert } from "../../component/Alert";
import { Card, CardContent, CardActions } from "../../component/Card";
import Grid from "../../component/Grid";
import Text from "../../component/Text";
import Button from "../../component/Button";
import LoadingModal from "../../component/LoadingModal";
import { excelDownload } from "../../component/ExcelDownload";
import { getMemberTier, thousandsSeparator } from "../../component/utils";
import SaveBlocklistFloaingLayer from "./SaveBlocklistFloaingLayer";
import MembersOfBoughtProducts from "./Event/MembersOfBoughtProducts";

import { pvpTeamEnum } from "../../utils/localData";
import removeDuplicates from "../../utils/removeDuplicates";

const GET_EVENT = gql`
  query event($id: Int) {
    event(id: $id) {
      id
      type
      name
      product {
        id
        name
      }
      startTime
      endTime
      activated
      createdAt
      updatedAt
      memberTierLimit
      enabled
      ... on DrawingLotsEvent {
        quota
        # "已達人數上限"
        reachedMaxNumberOfPeople
        showQuota
        reservations {
          id
          fullName
          mobile
        }
        participants {
          id
          fullName
          mobile
        }
        drew
        results {
          id
          fullName
          mobile
        }
        blocklist {
          id
          fullName
          mobile
        }
        # "參加人數上限"
        maxNumberOfPeople
      }
      ... on AuctionEvent {
        bidStartAt
        bidIncrement
        bidLimitPerBidding
        biddings {
          id
          bidder {
            id
            fullName
          }
          bid
          createdAt
        }
        newestBid
        winningBidder {
          id
          fullName
        }
        bidResult
      }
      ... on RaidEvent {
        status
        monster
        showQuota
        maxHealthPoint
        players {
          id
          player {
            id
            fullName
          }
          score
          team
        }
        attacks {
          id
          player {
            id
            fullName
          }
          attackPoint
        }
        quota
        drew
        results {
          id
          fullName
          mobile
        }
        blocklist {
          id
          fullName
          mobile
        }
        totalScore
        winningTeam
      }
    }
  }
`;

const DRAWLOTS = gql`
  mutation drawLots($id: Int!) {
    drawLots(id: $id) {
      success
      message
    }
  }
`;

const DRAWLOTS_FORRAID = gql`
  mutation drawLotsForRaid($id: Int!) {
    drawLotsForRaid(id: $id) {
      success
      message
    }
  }
`;
// NOTE 活動認證名單
/** - 活動認證名單 */
const EVENT_CERTIFICATION_LIST = gql`
  query eventCertificationList($eventId: Int!) {
    eventCertificationList(eventId: $eventId) {
      # "黑名單"
      blocklist {
        id
      }
      # "白名單"
      whitelist {
        id
      }
    }
  }
`;
// NOTE 儲存抽選黑名單
/** - 儲存抽選黑名單 */
const SAVE_BLOCKLIST = gql`
  mutation saveBlocklist($eventId: Int!, $memberIds: [Int!]!) {
    saveBlocklist(eventId: $eventId, memberIds: $memberIds) {
      success
      message
    }
  }
`;
// NOTE 儲存抽選白名單
/** - 儲存抽選白名單 */
const SAVE_WHITELIST = gql`
  mutation saveWhitelist($eventId: Int!, $memberIds: [Int!]!) {
    saveWhitelist(eventId: $eventId, memberIds: $memberIds) {
      success
      message
    }
  }
`;

export default function EventContent({ id, onEditSelect = () => {} }) {
  const Alert = useAlert();
  const [select, setSelect] = useState(0);

  const [getEvent, { data, loading, refetch: eventRefetch }] = useLazyQuery(
    GET_EVENT,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  useEffect(() => {
    if (Boolean(id)) {
      getEvent({ variables: { id } });
    }
  }, [id, getEvent]);

  const [drawLots, { loading: drawLotsLoading }] = useMutation(DRAWLOTS, {
    onCompleted({ drawLots }) {
      if (drawLots.success) {
        Alert.alert("", "開獎成功", [
          {
            text: "確定",
            onPress: () => getEvent({ variables: { id } }),
            type: "ok",
          },
        ]);
      } else if (drawLots.message) {
        Alert.alert("", `${drawLots.message}`, [{ text: "確定", type: "ok" }]);
      } else {
        Alert.alert("", "開獎失敗，請再次嘗試", [{ text: "確定", type: "ok" }]);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });

  const [drawLotsForRaid, { loading: drawLotsForRaidLoading }] = useMutation(
    DRAWLOTS_FORRAID,
    {
      onCompleted({ drawLotsForRaid }) {
        if (drawLotsForRaid.success) {
          Alert.alert("", "開獎成功", [
            {
              text: "確定",
              onPress: () => getEvent({ variables: { id } }),
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("", "開獎失敗，請再次嘗試。", [
            { text: "確定", type: "ok" },
          ]);
        }
      },
      onError(error) {
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (data) {
    if (data.event.type === "DRAWING_LOTS_EVENT") {
      return (
        <Card>
          <LoadingModal loading={drawLotsLoading} />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onPress={() => onEditSelect({ id })}
            >
              編輯
            </Button>
          </CardActions>
          <CardContent>
            <Grid container item justify="center">
              <Text type="h4">
                抽選活動 {data.event.maxNumberOfPeople ? "(限額抽選)" : ""}
              </Text>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container spacing={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>活動名稱</FormLabel>
                  <Text>{data.event.name}</Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>商品</FormLabel>
                  <Text>{data.event.product.name}</Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>開始時間</FormLabel>
                  <Text>
                    {moment(data.event.startTime).format("YYYY/MM/DD HH:mm")}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>結束時間</FormLabel>
                  <Text>
                    {moment(data.event.endTime).format("YYYY/MM/DD HH:mm")}
                  </Text>
                </Grid>
                {Boolean(data.event.maxNumberOfPeople) && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormLabel>繳費期限(小時)</FormLabel>
                    <Text>{data.event.maxNumberOfPeople}</Text>
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>名額</FormLabel>
                  <Text>{data.event.quota}</Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>參加人數上限</FormLabel>
                  <Text>{data.event.maxNumberOfPeople}</Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>已達人數上限</FormLabel>
                  <Text>
                    {data.event.reachedMaxNumberOfPeople ? "是" : "否"}
                  </Text>
                </Grid>
                {data.event.showQuota && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormLabel>前台顯示抽選名額</FormLabel>
                    <Text>{data.event.showQuota ?? ""}</Text>
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>會員級別參加限制</FormLabel>
                  <Text>{getMemberTier(data.event.memberTierLimit)}</Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>建立日期</FormLabel>
                  <Text>
                    {moment(data.event.createdAt).format("YYYY/MM/DD HH:mm")}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>最後更新日期</FormLabel>
                  <Text>
                    {moment(data.event.updatedAt).format("YYYY/MM/DD HH:mm")}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>狀態</FormLabel>
                  <Text>{data.event.activated ? "已上架" : "已下架"}</Text>
                </Grid>
              </Grid>
              {data.event.drew ? (
                <Grid container item>
                  <Grid item sm={4} />
                  <Grid item xs={12} sm={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onPress={() => drawLots({ variables: { id } })}
                      fullWidth
                    >
                      再次開獎
                    </Button>
                  </Grid>
                  <Grid item sm={4} />
                </Grid>
              ) : (
                <Grid container item>
                  <Grid item sm={4} />
                  <Grid item xs={12} sm={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onPress={() => drawLots({ variables: { id } })}
                      fullWidth
                    >
                      開獎
                    </Button>
                  </Grid>
                  <Grid item sm={4} />
                </Grid>
              )}
              <Grid container item>
                <Tabs value={select} onChange={(e, n) => setSelect(n)}>
                  <Tab label="名單" />
                  <Tab label="參加名單" />
                  <Tab label="第二頁" />
                  {Boolean(data.event.results) && <Tab label="抽選結果" />}
                </Tabs>
              </Grid>
              <Grid container item spacing={2} direction="row">
                {Boolean(select === 0) && (
                  <Grid item xs={12}>
                    <DropdownItem
                      id={id}
                      title={"名單"}
                      data={data.event.reservations}
                      type={data.event.type}
                      onRefetch={eventRefetch}
                    />
                  </Grid>
                )}
                {Boolean(select === 1) && (
                  <Grid item xs={12}>
                    <DropdownItem
                      id={id}
                      title={"參加名單"}
                      data={data.event.participants}
                      type={data.event.type}
                    />
                  </Grid>
                )}
                {Boolean(select === 2) && (
                  <Grid item xs={12}>
                    <DropdownItem
                      id={id}
                      title={"第二頁"}
                      data={data.event.blocklist}
                      type={data.event.type}
                      onRefetch={eventRefetch}
                    />
                  </Grid>
                )}
                {Boolean(select === 3) && Boolean(data.event.results) && (
                  <Grid item xs={12}>
                    <DropdownItem
                      id={id}
                      title={"抽選結果"}
                      data={data.event.results}
                      type={data.event.type}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    } else if (data.event.type === "AUCTION_EVENT") {
      return (
        <Card>
          <LoadingModal loading={drawLotsLoading} />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onPress={() => onEditSelect({ id })}
            >
              編輯
            </Button>
          </CardActions>
          <CardContent>
            <Grid container item justify="center">
              <Text type="h4">競標活動</Text>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container spacing={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>活動名稱</FormLabel>
                  <Text>{data.event.name}</Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>商品</FormLabel>
                  <Text>{data.event.product.name}</Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>會員級別參加限制</FormLabel>
                  <Text>{getMemberTier(data.event.memberTierLimit)}</Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>開始時間</FormLabel>
                  <Text>
                    {moment(data.event.startTime).format("YYYY/MM/DD HH:mm")}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>結束時間</FormLabel>
                  <Text>
                    {moment(data.event.endTime).format("YYYY/MM/DD HH:mm")}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>起標價</FormLabel>
                  <Text>{data.event.bidStartAt}</Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>出價增額</FormLabel>
                  <Text>{data.event.bidIncrement}</Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>每次出價最高金額限制</FormLabel>
                  <Text>{data.event.bidLimitPerBidding}</Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>目前出價</FormLabel>
                  <Text>
                    {data.event.newestBid ? data.event.newestBid : ""}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>建立日期</FormLabel>
                  <Text>
                    {moment(data.event.createdAt).format("YYYY/MM/DD HH:mm")}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>最後更新日期</FormLabel>
                  <Text>
                    {moment(data.event.updatedAt).format("YYYY/MM/DD HH:mm")}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>狀態</FormLabel>
                  <Text>{data.event.activated ? "已上架" : "已下架"}</Text>
                </Grid>
              </Grid>
              {Boolean(data.event.winningBidder) ? (
                <Grid container item justify="center">
                  <FormLabel style={{ color: "red" }}>已競標完畢</FormLabel>
                </Grid>
              ) : (
                <Grid container item justify="center">
                  <FormLabel style={{ color: "red" }}>競標中</FormLabel>
                </Grid>
              )}
              <Grid container item>
                <Tabs value={select} onChange={(e, n) => setSelect(n)}>
                  <Tab label="出價紀錄" />
                  {Boolean(data.event.winningBidder) && (
                    <Tab label="競標結果" />
                  )}
                </Tabs>
              </Grid>
              <Grid container item spacing={2} direction="row">
                {Boolean(select === 0) && (
                  <Grid item xs={12}>
                    <DropdownItem
                      id={id}
                      title={"出價紀錄"}
                      data={data.event.biddings ? data.event.biddings : null}
                      type={data.event.type}
                    />
                  </Grid>
                )}
                {Boolean(select === 1) && Boolean(data.event.winningBidder) && (
                  <Grid item xs={12}>
                    <DropdownItem
                      id={id}
                      title={"競標結果"}
                      data={data.event.winningBidder}
                      type={data.event.type}
                      bidResult={data.event.bidResult}
                      result
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    } else if (
      data.event.type === "RAID_EVENT" ||
      data.event.type === "PVP_RAID_EVENT"
    ) {
      const eventIsStart = new Date() > new Date(data.event.startTime);
      const eventIsEnd = new Date() > new Date(data.event.endTime);
      return (
        <Card>
          <LoadingModal loading={drawLotsForRaidLoading} />
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onPress={() => onEditSelect({ id })}
            >
              編輯
            </Button>
          </CardActions>
          <CardContent>
            <Grid container item justify="center">
              <Text type="h4">
                團體戰活動{data.event.type === "PVP_RAID_EVENT" && "-對戰模式"}
              </Text>
            </Grid>
          </CardContent>
          {data.event.type === "PVP_RAID_EVENT" &&
            eventIsEnd &&
            data.event.winningTeam && (
              <CardContent>
                <Grid container item justify="center">
                  <Text type="h5">
                    獲勝隊伍：
                    {pvpTeamEnum[data.event.winningTeam]}
                  </Text>
                </Grid>
              </CardContent>
            )}
          <CardContent>
            <Grid container spacing={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>活動名稱</FormLabel>
                  <Text>{data.event.name}</Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>商品</FormLabel>
                  <Text>{data.event.product.name}</Text>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>怪物名稱</FormLabel>
                  <Text>
                    {(() => {
                      switch (data.event.monster) {
                        case "MAO_MAO_ER":
                          return "毛毛二";
                        case "HUI_DAO":
                          return "灰島";
                        case "XIA_BO_MI":
                          return "夏波米";
                        case "INTOY":
                          return "INTOY";
                        default:
                          return null;
                      }
                    })()}
                  </Text>
                </Grid> */}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>最大血量</FormLabel>
                  <Text>{data.event.maxHealthPoint}</Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>抽選名額</FormLabel>
                  <Text>{data.event.quota}</Text>
                </Grid>
                {data.event.showQuota && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormLabel>前台顯示抽選名額</FormLabel>
                    <Text>{data.event.showQuota ?? ""}</Text>
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>會員級別參加限制</FormLabel>
                  <Text>{getMemberTier(data.event.memberTierLimit)}</Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>開始時間</FormLabel>
                  <Text>
                    {moment(data.event.startTime).format("YYYY/MM/DD HH:mm:ss")}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>結束時間</FormLabel>
                  <Text>
                    {moment(data.event.endTime).format("YYYY/MM/DD HH:mm:ss")}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>建立日期</FormLabel>
                  <Text>
                    {moment(data.event.createdAt).format("YYYY/MM/DD HH:mm")}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>最後更新日期</FormLabel>
                  <Text>
                    {moment(data.event.updatedAt).format("YYYY/MM/DD HH:mm")}
                  </Text>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormLabel>狀態</FormLabel>
                  <Text>{data.event.activated ? "已上架" : "已下架"}</Text>
                </Grid>
                {data.event.type === "PVP_RAID_EVENT" ? (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onPress={() =>
                        window.open(
                          `${window.location.protocol}//${window.location.host}/PVPRaid-monster/${data.event.id}`
                        )
                      }
                      disabled={
                        data.event.drew ||
                        moment(moment().tz("Asia/Taipei")).isAfter(
                          moment(data.event.endTime).tz("Asia/Taipei")
                        )
                      }
                    >
                      貓錦獸連結
                    </Button>
                  </Grid>
                ) : (
                  <>
                    {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        onPress={() =>
                          window.open(
                            `${window.location.protocol}//${window.location.host}/raid-monster/${data.event.id}?type=Godzilla`
                          )
                        }
                        disabled={
                          data.event.drew ||
                          moment(moment().tz("Asia/Taipei")).isAfter(
                            moment(data.event.endTime).tz("Asia/Taipei")
                          )
                        }
                      >
                        貓錦獸連結(哥吉拉)
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        onPress={() =>
                          window.open(
                            `${window.location.protocol}//${window.location.host}/raid-monster/${data.event.id}?type=BenzCat`
                          )
                        }
                        disabled={
                          data.event.drew ||
                          moment(moment().tz("Asia/Taipei")).isAfter(
                            moment(data.event.endTime).tz("Asia/Taipei")
                          )
                        }
                      >
                        貓錦獸連結(賓士貓)
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        onPress={() =>
                          window.open(
                            `${window.location.protocol}//${window.location.host}/raid-monster/${data.event.id}?type=BlackCat`
                          )
                        }
                        disabled={
                          data.event.drew ||
                          moment(moment().tz("Asia/Taipei")).isAfter(
                            moment(data.event.endTime).tz("Asia/Taipei")
                          )
                        }
                      >
                        貓錦獸連結(黑貓)
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        onPress={() =>
                          window.open(
                            `${window.location.protocol}//${window.location.host}/raid-monster/${data.event.id}?type=Ghost`
                          )
                        }
                        disabled={
                          data.event.drew ||
                          moment(moment().tz("Asia/Taipei")).isAfter(
                            moment(data.event.endTime).tz("Asia/Taipei")
                          )
                        }
                      >
                        貓錦獸連結(幽靈)
                      </Button>
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        onPress={() =>
                          window.open(
                            `${window.location.protocol}//${window.location.host}/raid-monster/${data.event.id}?type=MagmaCat`
                          )
                        }
                        disabled={
                          data.event.drew ||
                          moment(moment().tz("Asia/Taipei")).isAfter(
                            moment(data.event.endTime).tz("Asia/Taipei")
                          )
                        }
                      >
                        貓錦獸連結
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
              {data.event.activated ? (
                eventIsEnd ? (
                  data.event.status === "VICTORY" ? (
                    data.event.drew ? (
                      <Grid container item justify="center">
                        <FormLabel style={{ color: "red" }}>
                          已抽選完畢
                        </FormLabel>
                      </Grid>
                    ) : (
                      <Grid container item>
                        <Grid item sm={4} />
                        <Grid item xs={12} sm={4}>
                          <Button
                            variant="contained"
                            color="primary"
                            onPress={() => {
                              Alert.alert("", "確定要開獎？", [
                                {
                                  text: "確認",
                                  onPress: () =>
                                    drawLotsForRaid({ variables: { id } }),
                                  type: "ok",
                                },
                                {
                                  text: "取消",
                                  type: "cancel",
                                },
                              ]);
                            }}
                            fullWidth
                          >
                            開獎
                          </Button>
                        </Grid>
                        <Grid item sm={4} />
                      </Grid>
                    )
                  ) : (
                    <Grid container item justify="center">
                      <FormLabel style={{ color: "red" }}>挑戰失敗</FormLabel>
                    </Grid>
                  )
                ) : eventIsStart ? (
                  <Grid container item justify="center">
                    <FormLabel style={{ color: "red" }}>團體戰進行中</FormLabel>
                  </Grid>
                ) : (
                  <Grid container item justify="center">
                    <FormLabel style={{ color: "red" }}>
                      團體戰尚未開始
                    </FormLabel>
                  </Grid>
                )
              ) : (
                <Grid container item justify="center">
                  <FormLabel style={{ color: "red" }}>團體戰未上架</FormLabel>
                </Grid>
              )}
              <Grid container item>
                <Tabs value={select} onChange={(e, n) => setSelect(n)}>
                  <Tab label="參加玩家" />
                  <Tab label="攻擊紀錄" />
                  <Tab label="第二頁" />
                  {Boolean(data.event.results) && <Tab label="抽選結果" />}
                </Tabs>
              </Grid>
              <Grid container item spacing={2} direction="row">
                {Boolean(select === 0) && (
                  <Grid item xs={12}>
                    <DropdownItem
                      id={id}
                      title={"參加玩家"}
                      data={data.event.players ? data.event.players : null}
                      type={data.event.type}
                      totalScore={data.event.totalScore}
                    />
                  </Grid>
                )}
                {Boolean(select === 1) && (
                  <Grid item xs={12}>
                    <DropdownItem
                      id={id}
                      title={"攻擊紀錄"}
                      data={data.event.attacks ? data.event.attacks : null}
                      type={data.event.type}
                    />
                  </Grid>
                )}
                {Boolean(select === 2) && (
                  <Grid item xs={12}>
                    <DropdownItem
                      id={id}
                      title={"第二頁"}
                      data={data.event.blocklist}
                      type={data.event.type}
                      onRefetch={eventRefetch}
                      result
                    />
                  </Grid>
                )}
                {Boolean(select === 3) && Boolean(data.event.results) && (
                  <Grid item xs={12}>
                    <DropdownItem
                      id={id}
                      title={"抽選結果"}
                      data={data.event.results}
                      type={data.event.type}
                      bidResult={data.event.bidResult}
                      result
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    }
  } else {
    return null;
  }
}

function DropdownItem({
  id,
  title,
  data,
  type,
  result,
  bidResult,
  totalScore,
  onRefetch,
}) {
  const Alert = useAlert();
  // SECTION style
  const useStyles = makeStyles({
    accordion: {
      overflow: "hidden",
    },
    listPadding: {
      paddingTop: "3px",
      paddingBottom: "3px",
    },
  });
  const classes = useStyles();
  // !SECTION
  const [excelLoading, setExcelLoading] = useState(false);
  const blocklistRef = useRef();
  const typeRef = useRef();
  const membersOfBoughtProductsRef = useRef();

  const [getEventExcel] = useLazyQuery(GET_EVENT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: async ({ event }) => {
      if (event) {
        setTimeout(async () => {
          if (type === "DRAWING_LOTS_EVENT") {
            const excelData = [];
            const entozh = {
              fullName: "會員名稱",
              mobile: "會員電話",
            };
            if (title === "參加名單") {
              event.participants.forEach((item) => {
                excelData.push({
                  fullName: item.fullName,
                  mobile: item.mobile,
                });
              });
            } else {
              event.results.forEach((item) => {
                excelData.push({
                  fullName: item.fullName,
                  mobile: item.mobile,
                });
              });
            }
            const excelFinish = await excelDownload(
              entozh,
              excelData,
              `${moment().format("YYYY-MM-DD")} 路遙圓創活動${
                title === "參加名單" ? "參加人" : "抽選結果"
              }清單`
            );
            if (excelFinish === "Finish") {
              setExcelLoading(false);
            }
          } else {
            return null;
          }
        }, 0);
      }
    },
    onError(error) {
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });
  const [saveWhitelistFn, { loading: saveWhitelistLoading }] = useMutation(
    SAVE_WHITELIST,
    {
      variables: {
        eventId: id,
      },
      onCompleted({ saveWhitelist: { success, message } }) {
        if (success) {
          onRefetch();
          Alert.notice("匯入成功");
        } else if (message) {
          Alert.notice(message);
        }
        typeRef.current = null;
      },
      onError(error) {
        typeRef.current = null;
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );
  const [saveBlocklistFn, { loading: saveBlocklistLoading }] = useMutation(
    SAVE_BLOCKLIST,
    {
      variables: {
        eventId: id,
      },
      onCompleted({ saveBlocklist: { success, message } }) {
        if (success) {
          onRefetch();
          Alert.notice("匯入成功");
        } else if (message) {
          Alert.notice(message);
        }
        typeRef.current = null;
      },
      onError(error) {
        typeRef.current = null;
        Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
      },
    }
  );
  const [
    getEventCertificationListFn,
    { loading: eventCertificationListLoading },
  ] = useLazyQuery(EVENT_CERTIFICATION_LIST, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      eventId: id,
    },
    onCompleted({ eventCertificationList }) {
      if (eventCertificationList) {
        setTimeout(() => {
          if (typeRef.current === "blocklist") {
            const currentBlocklist =
              eventCertificationList.blocklist.concat(data);
            const memberIds = removeDuplicates(currentBlocklist).map(
              (item) => item.id
            );
            saveBlocklistFn({ variables: { memberIds } });
          } else if (typeRef.current === "whitelist") {
            const currentWhitelist =
              eventCertificationList.whitelist.concat(data);
            const memberIds = removeDuplicates(currentWhitelist).map(
              (item) => item.id
            );
            saveWhitelistFn({ variables: { memberIds } });
          }
        }, 0);
      }
    },
    onError(error) {
      typeRef.current = null;
      Alert.notice(`${error.message.replace("GraphQL error: ", "")}`);
    },
  });
  const _importWhitelist = useCallback(() => {
    typeRef.current = "whitelist";
    getEventCertificationListFn();
  }, [getEventCertificationListFn]);
  const _importBlacklist = useCallback(() => {
    typeRef.current = "blocklist";
    getEventCertificationListFn();
  }, [getEventCertificationListFn]);
  const _membersOfBoughtProducts = useCallback(() => {
    membersOfBoughtProductsRef.current?.open();
  }, []);
  const _membersOfBoughtProductsClose = useCallback(
    (param) => {
      if (param === "refetch") {
        onRefetch();
      }
    },
    [onRefetch]
  );
  return (
    <Fragment>
      <LoadingModal
        loading={
          excelLoading ||
          saveWhitelistLoading ||
          saveBlocklistLoading ||
          eventCertificationListLoading
        }
      />
      <Box display="flex" flexDirection={"column"} style={{ gap: "20px" }}>
        <MembersOfBoughtProducts
          ref={membersOfBoughtProductsRef}
          eventId={id}
          existingWhitelist={data}
          onClose={_membersOfBoughtProductsClose}
        />
        {type === "DRAWING_LOTS_EVENT" && title === "名單" && (
          <Box
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              gap: 16,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onPress={_membersOfBoughtProducts}
            >
              一鍵匯入指定商品名單
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onPress={_importWhitelist}
            >
              一鍵匯入白名單
            </Button>
          </Box>
        )}
        {type !== "AUCTION_EVENT" &&
          type !== "RAISING_EVENT" &&
          title === "第二頁" && (
            <>
              <SaveBlocklistFloaingLayer
                ref={blocklistRef}
                _onClose={(e) => {
                  if (e === "mutation") {
                    onRefetch();
                  }
                }}
                eventId={id}
              />
              <Box
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  gap: 16,
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onPress={_importBlacklist}
                >
                  一鍵匯入黑名單
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onPress={() => blocklistRef.current?.open(data)}
                >
                  新增
                </Button>
              </Box>
            </>
          )}
        <Accordion className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {(() => {
              switch (type) {
                case "DRAWING_LOTS_EVENT":
                  return (
                    <Typography>
                      {title}&nbsp;&nbsp;人數：{data?.length}
                    </Typography>
                  );
                case "AUCTION_EVENT":
                  return result ? (
                    <Typography>{title}</Typography>
                  ) : (
                    <Typography>
                      {title}&nbsp;&nbsp;次數：{data?.length}
                    </Typography>
                  );
                case "RAID_EVENT":
                case "PVP_RAID_EVENT":
                  return title === "攻擊紀錄" ? (
                    <Typography>
                      {title}&nbsp;&nbsp;次數：{data?.length}
                    </Typography>
                  ) : (
                    <Grid container>
                      <Grid item xs={12} sm={4}>
                        <Typography>
                          {title}&nbsp;&nbsp;人數：{data?.length}
                        </Typography>
                      </Grid>
                      {typeof totalScore === "number" && (
                        <Grid item xs={12} sm={4}>
                          <Typography>
                            團體戰攻擊總分：{thousandsSeparator(totalScore)}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  );
                default:
                  break;
              }
            })()}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              {(() => {
                switch (type) {
                  case "DRAWING_LOTS_EVENT":
                    const isDrawingLotsTitles = ["姓名", "電話"];
                    const isDrawingLotsContents = data.map((item) => ({
                      fullName: item.fullName,
                      mobile: item.mobile,
                    }));
                    return (
                      <AccordionContent
                        titles={isDrawingLotsTitles}
                        contents={isDrawingLotsContents}
                      />
                    );
                  case "AUCTION_EVENT":
                    const isAuctionTitles = [
                      "姓名",
                      result ? "得標價格" : "出價",
                      !result && "出價時間",
                    ];
                    const isAuctionContents = result
                      ? [
                          {
                            fullName: data.fullName,
                            bidResult,
                          },
                        ]
                      : data.map((item) => ({
                          fullName: item.bidder.fullName,
                          bid: item.bid,
                          createdAt: moment(item.createdAt).format(
                            "yyyy-MM-DD a HH:mm:ss"
                          ),
                        }));
                    return (
                      <AccordionContent
                        titles={isAuctionTitles}
                        contents={isAuctionContents}
                      />
                    );
                  case "RAID_EVENT":
                  case "PVP_RAID_EVENT":
                    const isRaidTitles = [
                      "姓名",
                      result ? "電話" : "分數",
                      title === "攻擊紀錄"
                        ? "攻擊時間"
                        : title === "參加玩家" &&
                          type === "PVP_RAID_EVENT" &&
                          "隊伍",
                    ];
                    const isRaidContents = data.map((item) =>
                      result
                        ? {
                            fullName: item.fullName,
                            mobile: item.mobile,
                          }
                        : {
                            fullName: item.player.fullName,
                            score: item.attackPoint
                              ? item.attackPoint
                              : item.score,
                            createdAt:
                              title === "攻擊紀錄"
                                ? moment(item.createdAt).format(
                                    "yyyy-MM-DD a HH:mm:ss"
                                  )
                                : title === "參加玩家" &&
                                  type === "PVP_RAID_EVENT" &&
                                  pvpTeamEnum[item.team],
                          }
                    );
                    return (
                      <AccordionContent
                        titles={isRaidTitles}
                        contents={isRaidContents}
                      />
                    );
                  default:
                    return null;
                }
              })()}
              {type === "DRAWING_LOTS_EVENT" &&
                title !== "名單" &&
                title !== "第二頁" && (
                  <Grid
                    container
                    item
                    justify="center"
                    style={{ margin: "1em" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={data?.length === 0}
                      onPress={() => {
                        setExcelLoading(true);
                        getEventExcel({
                          variables: {
                            id,
                          },
                        });
                      }}
                    >
                      下載{title}
                    </Button>
                  </Grid>
                )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Fragment>
  );
}

function AccordionContent({ titles = [], contents = [] }) {
  const useStyles = makeStyles({
    listPadding: {
      paddingTop: "3px",
      paddingBottom: "3px",
    },
  });
  const classes = useStyles();
  function _renderTitleItem(title) {
    return (
      <Grid item xs={3} sm={3}>
        <Typography>{title}</Typography>
      </Grid>
    );
  }
  function _renderContentItem(item, index) {
    function _renderItem(item2) {
      return (
        <Grid item xs={3} sm={3} classes={{ root: classes.listPadding }}>
          <Typography>{contents[index][item2]}</Typography>
        </Grid>
      );
    }
    return (
      <Grid container direction={"row"} item xs={12} sm={12}>
        {Object.keys(item).map(_renderItem)}
      </Grid>
    );
  }
  return (
    <Grid container item xs={12} sm={12}>
      {titles.map(_renderTitleItem)}
      {contents.map(_renderContentItem)}
    </Grid>
  );
}
