import React, { createElement, useCallback, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
  useHistory,
} from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";

import { useAlert } from "./component/Alert";
import WebSiteLayout from "./WebSiteLayout";
// NOTE 前台
import HomePage from "./page/HomePage";
import CartPage from "./page/CartPage";
import LoginPage from "./page/LoginPage";
import MemberPage from "./page/MemberPage";
import RegisterPage from "./page/RegisterPage";
import ForgePasswordPage from "./page/ForgePasswordPage";
import ProductListPage from "./page/ProductListPage";
import ProductPage from "./page/ProductPage";
import EventChoosePage from "./page/EventChoosePage";
import EventPage from "./page/EventPage";
import PaymentFlowPage from "./page/PaymentFlowPage";
import FaqPage from "./page/FaqPage";
import ArticleListPage from "./page/ArticleListPage";
import ArticlePage from "./page/ArticlePage";
import ShootingGamePage from "./page/ShootingGame";
import CompetitionsPage from "./page/Competitions/Page";
// Special
import SpecialEventList from "./page/SpecialEvent/SpecialEventListPage";
import SpecialEventInformation from "./page/SpecialEvent/InformationMainPage";
import SpecialEventPurchase from "./page/SpecialEvent/PurchaseMainPage";
import SpecialEventCultivation from "./page/SpecialEvent/CultivationMainPage";
import SpecialEventIllustrate from "./page/SpecialEvent/IllustrateMainPage";
//NOTE 後台
import ConsoleLoginPage from "./console/LoginPage";
import ConsoleCarouselsListPage from "./console/CarouselsListPage";
import ConsoleArticlesListPage from "./console/ArticlesListPage";
import ConsoleProductPage from "./console/ProductPage";
import ConsoleEventListPage from "./console/EventListPage";
import ConsoleHatchingListPage from "./console/HatchingListPage";
import ConsoleLuyaoProfilePicturesPage from "./console/LuyaoProfilePicturesListPage";
import ConsoleMemberListPage from "./console/MemberListPage";
import ConsoleMemberPage from "./console/MemberPage";
import ConsoleMemberDirectoryPage from "./console/MemberDirectoryPage";
import ConsoleOrderListPage from "./console/OrderListPage";
import ConsoleOrderPage from "./console/OrderPage";
import ConsoleShipmentListPage from "./console/ShipmentListPage";
import ConsoleShipmentPage from "./console/ShipmentPage";
import ConsoleFaqListPage from "./console/FaqListPage";
// NOTE 大頭貼領取
import AvatarPage from "./page/AvatarPage";
// NOTE 團體戰
import RaidPage from "./page/raid";
import RaidMonsterPage from "./page/raid/Monster";
// NOTE PVP團體戰
import PVPRaidPage from "./page/PVPRaid";
import PVPRaidMonsterPage from "./page/PVPRaid/Monster";

import Layout from "./Layout";
// 清除memberTierLimit
import { cleanMemberTierLimit } from "./redux/member";
// zustand
import useTokenStore from "./zustand/useTokenStore";

const consoleHomeRoute = "/console/product";
const consoleLoginRoute = "/console/login";

const consoleRoute = [
  { path: "/console/product", page: ConsoleProductPage },
  { path: "/console/event", page: ConsoleEventListPage },
  { path: "/console/hatching", page: ConsoleHatchingListPage },
  { path: "/console/carousel", page: ConsoleCarouselsListPage },
  { path: "/console/article", page: ConsoleArticlesListPage },
  {
    path: "/console/luyaoProfilePicture",
    page: ConsoleLuyaoProfilePicturesPage,
  },
  { path: "/console/member", page: ConsoleMemberListPage },
  { path: "/console/member/:id", page: ConsoleMemberPage },
  { path: "/console/member-directory", page: ConsoleMemberDirectoryPage },
  { path: "/console/order", page: ConsoleOrderListPage },
  { path: "/console/order/:id", page: ConsoleOrderPage },
  { path: "/console/shipment", page: ConsoleShipmentListPage },
  { path: "/console/shipment/:id", page: ConsoleShipmentPage },
  { path: "/console/faq", page: ConsoleFaqListPage },
];
const webSiteRoute = [
  { path: "/", component: HomePage },
  { path: "/cart", component: CartPage },
  { path: "/login", component: LoginPage },
  { path: "/member", component: MemberPage },
  { path: "/register", component: RegisterPage },
  { path: "/forge-password", component: ForgePasswordPage },
  { path: "/product", component: ProductListPage },
  { path: "/product/preorder", component: ProductListPage },
  { path: "/product/exclusive", component: ProductListPage },
  { path: "/product/:id", component: ProductPage },
  { path: "/event", component: EventChoosePage },
  { path: "/event/drawingLots/:id", component: EventPage },
  // { path: "/event/auction/:id", component: EventPage },
  { path: "/event/raid/:id", component: EventPage },
  { path: "/payment-flow", component: PaymentFlowPage },
  { path: "/faq", component: FaqPage },
  { path: "/article", component: ArticleListPage },
  { path: "/article/:id", component: ArticlePage },
  // 特殊活動
  { path: "/special-event", component: SpecialEventList },
  { path: "/special-event/information", component: SpecialEventInformation },
  { path: "/special-event/purchase", component: SpecialEventPurchase },
  { path: "/special-event/my-cultivation", component: SpecialEventCultivation },
  {
    path: "/special-event/illustrate",
    component: SpecialEventIllustrate,
  },
];

// 大頭貼領取
const avatarRoute = [{ path: "/avatar/:id", component: AvatarPage }];

const worldFootballRoute = [
  { path: "/shooting-game", component: ShootingGamePage },
];

/** - 對戰系統 */
const competitionsRoute = [
  { path: "/competitions/:roomId", component: CompetitionsPage },
];

const raidRoute = [
  { path: "/raid/:id", component: RaidPage },
  { path: "/raid-monster/:id", component: RaidMonsterPage },
];

const PVPraidRoute = [
  { path: "/PVPRaid/:id", component: PVPRaidPage },
  { path: "/PVPRaid-monster/:id", component: PVPRaidMonsterPage },
];

// ANCHOR 主要組件
export default function Routes() {
  return (
    <Router>
      <ScrollToTop />
      {/* <CheckToken /> */}
      <Switch>
        <Route exact path={webSiteRoute.map((item) => item.path)}>
          <SwitchWebRoute />
          <WebSiteLayout>
            <Switch>
              {webSiteRoute.map((item) => (
                <Route exact path={item.path} key={item.path}>
                  {createElement(item.component)}
                </Route>
              ))}
            </Switch>
          </WebSiteLayout>
        </Route>
        <Route path="/console">
          <SwitchConsoleRoute />
          <Switch>
            {/* 登入路由 */}
            <Route path={consoleLoginRoute}>
              <ConsoleLoginPage />
            </Route>
            {/* 一般路由 */}
            <Route exact path={consoleRoute.map((item) => item.path)}>
              <Layout>
                <Switch>
                  {consoleRoute.map((item) => (
                    <Route exact path={item.path} key={item.path}>
                      {createElement(item.page)}
                    </Route>
                  ))}
                </Switch>
              </Layout>
            </Route>
          </Switch>
        </Route>
        <Route path={avatarRoute.map((i) => i.path)}>
          <Switch>
            {/* 大頭貼領取 */}
            {avatarRoute.map((item) => (
              <Route path={item.path} key={item.path}>
                {createElement(item.component)}
              </Route>
            ))}
          </Switch>
        </Route>
        <Route path={worldFootballRoute.map((i) => i.path)}>
          <Switch>
            {/* 世足路由 */}
            {worldFootballRoute.map((item) => (
              <Route path={item.path} key={item.path}>
                {createElement(item.component)}
              </Route>
            ))}
          </Switch>
        </Route>
        <Route path={competitionsRoute.map((i) => i.path)}>
          <Switch>
            {/* 對戰系統 */}
            {competitionsRoute.map((item) => (
              <Route path={item.path} key={item.path}>
                {createElement(item.component)}
              </Route>
            ))}
          </Switch>
        </Route>
        <Route path={raidRoute.map((i) => i.path)}>
          <Switch>
            {/* 團體戰路由 */}
            {raidRoute.map((item) => (
              <Route path={item.path} key={item.path}>
                {createElement(item.component)}
              </Route>
            ))}
          </Switch>
        </Route>
        <Route path={PVPraidRoute.map((i) => i.path)}>
          <Switch>
            {/* PVP團體戰路由 */}
            {PVPraidRoute.map((item) => (
              <Route path={item.path} key={item.path}>
                {createElement(item.component)}
              </Route>
            ))}
          </Switch>
        </Route>
      </Switch>
    </Router>
  );
}
// ANCHOR 防堵前台
function SwitchWebRoute() {
  const { pathname } = useLocation();
  const memberToken = JSON.parse(localStorage.getItem("@luyaoMemberToken"))
    ?.state?.memberToken;
  if (pathname === "/member") {
    if (!Boolean(memberToken)) {
      return <Redirect to="/login" />;
    }
  }
  return null;
}
// ANCHOR 防堵後台
function SwitchConsoleRoute() {
  const { pathname } = useLocation();
  const consoleToken = JSON.parse(localStorage.getItem("@luyaoMemberToken"))
    ?.state?.consoleToken;
  if (
    (pathname === consoleLoginRoute || pathname === "/console") &&
    consoleToken
  ) {
    // 在登入情況下進到登入頁
    return <Redirect to={consoleHomeRoute} />;
  } else if (pathname !== consoleLoginRoute && !consoleToken) {
    // 在未登入情況進到非登入(後台)畫面
    return <Redirect to={consoleLoginRoute} />;
  }
  return null;
}
// ANCHOR 滾到最上方
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}
// ANCHOR 檢查token
function CheckToken() {
  const dispatch = useDispatch();
  const history = useHistory();
  const Alert = useAlert();

  const setNowTime = useCallback(() => {
    const memberToken = JSON.parse(localStorage.getItem("@luyaoMemberToken"))
      ?.state?.memberToken;
    if (Boolean(memberToken)) {
      if (
        moment().diff(localStorage.getItem("lastActivityTime"), "hour") >= 6
      ) {
        Alert.alert("", "登入逾時", [
          {
            text: "確認",
            type: "ok",
          },
        ]);
        useTokenStore.getState().cleanToken();
        dispatch(cleanMemberTierLimit());
        history.replace("/login");
      }
    }
    localStorage.setItem("lastActivityTime", moment());
  }, [Alert, dispatch, history]);

  useEffect(() => {
    setNowTime();
    window.addEventListener("click", setNowTime);
    return () => window.removeEventListener("click", setNowTime);
  }, [setNowTime]);
  return null;
}
