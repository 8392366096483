import { useEffect, useRef } from "react";

/**
 * @typedef VideoPlayerProps
 * @property {string} url
 * @property {boolean} loop
 * @property {() => void} onEnded
 * @param {VideoPlayerProps} param0
 */
export default function VideoPlayer({ url, loop = false, onEnded }) {
  const videoParentRef = useRef();
  const videoFnRef = useRef();
  const disabledClickRef = useRef(false);
  useEffect(() => {
    if (videoParentRef.current) {
      const player = videoParentRef.current.children[0];
      if (player) {
        setTimeout(() => {
          player.controls = false;
          player.playsinline = true;
          player.muted = false;
          player.autoplay = true;
          player.loop = Boolean(loop);
          if (onEnded) {
            player.onended = () => {
              onEnded();
            };
          }
          videoFnRef.current = function videoPlayer() {
            if (!disabledClickRef.current) {
              player.muted = false;
              const promise = player.play();
              if (promise.then) {
                promise.then(() => {
                  disabledClickRef.current = true;
                });
              }
            }
          };
          window.addEventListener("click", videoFnRef.current);
          setTimeout(() => {
            const promise = player.play();
            if (promise.then) {
              promise.then(() => {
                disabledClickRef.current = true;
              });
            }
          }, 0);
        }, 0);
      }
    }
    return () => {
      if (videoParentRef.current) {
        const player = videoParentRef.current.children[0];
        if (player && videoFnRef.current) {
          disabledClickRef.current = false;
          window.removeEventListener("click", videoFnRef.current);
        }
      }
    };
  }, [url, loop, onEnded]);

  return (
    <div
      style={{ height: "100%", width: "100%" }}
      ref={videoParentRef}
      dangerouslySetInnerHTML={{
        __html: `
        <video
          muted
          autoplay
          playsinline
          preload="metadata"
          width="100%"
          height="100%"
          src="${url}"
          type="video/mp4"
        >
        </video>`,
      }}
    />
  );
}
