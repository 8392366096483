import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Grid,
  Box,
  CircularProgress,
  Typography,
  Link,
  ButtonBase,
  makeStyles,
} from "@material-ui/core";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Pagination } from "@material-ui/lab";
import moment from "moment";
// NOTE 組件
import Table from "../../component/Table";
import { useAlert } from "../../component/Alert";
import { Card, CardContent, CardActions } from "../../component/Card";
import { Select } from "../../component/Form";
import Button from "../../component/Button";
import Qrcode from "../../component/Qrcode";
import QRcodeDialog from "../../component/QRcodeDialog";

// SECTION apollo
// NOTE 對戰房列表
/** - 對戰房列表 */
const GET_BATTLE_ROOMS = gql`
  query battleRooms($latest: Boolean, $pageSize: Int, $page: Int) {
    battleRooms(latest: $latest, pageSize: $pageSize, page: $page) {
      # "對戰房總數"
      count
      # "總頁數"
      pageCount
      # "對戰房"
      contents {
        # "ID"
        id
        # "名稱"
        name
        # "密碼"
        password
        # "建立時間"
        createdAt
        # "更新時間"
        updatedAt
      }
    }
  }
`;
// NOTE 清除對戰房
/** - 清除對戰房 */
const CLEAR_BATTLE_ROOM = gql`
  mutation clearBattleRoom($id: Int!) {
    clearBattleRoom(id: $id) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;
// NOTE 刪除對戰房
/** - 刪除對戰房 */
const DELETE_BATTLE_ROOM = gql`
  mutation deleteBattleRoom($id: Int!) {
    deleteBattleRoom(id: $id) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;
// !SECTION

const useStyles = makeStyles((theme) => ({
  qrcodeStyle: {
    height: "100%",
    width: "100%",
    "&:hover": {
      backgroundColor: "#000",
      opacity: 0.25,
    },
  },
}));
/** - 對戰列表 */
export default function BattleAssetsList({ swtichContentDispatch }) {
  const classes = useStyles();
  const { alert, notice } = useAlert();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [latest, setLatest] = useState(true);
  const clearBattleRoomIdRef = useRef();
  const deleteBattleRoomIdRef = useRef();
  const qrCodeDialogRef = useRef();
  const cacheRef = useRef({
    swtichContentDispatch,
  });
  useEffect(() => {
    cacheRef.current.swtichContentDispatch = swtichContentDispatch;
  }, [swtichContentDispatch]);

  const {
    data: battleRoomsData,
    loading,
    refetch,
  } = useQuery(GET_BATTLE_ROOMS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      latest,
      pageSize,
      page,
    },
    onError(error) {
      notice(error.message.replace("GraphQL error: ", ""));
    },
  });

  const battleRooms = useMemo(
    () => ({
      count: battleRoomsData?.battleRooms?.count || 0,
      pageCount: battleRoomsData?.battleRooms?.pageCount || 1,
      contents: battleRoomsData?.battleRooms?.contents || [],
    }),
    [battleRoomsData]
  );

  const [clearBattleRoomFn, { loading: clearBattleRoomLoading }] = useMutation(
    CLEAR_BATTLE_ROOM,
    {
      onCompleted({ clearBattleRoom: { success, message } }) {
        if (success) {
        } else if (message) {
          notice(message);
        }
      },
      onError(error) {
        notice(error.message.replace("GraphQL error: ", ""));
      },
    }
  );
  const _clearBattleRoomFn = useCallback(
    (item) => {
      alert("", "確定要清空房間最後一次遊玩的玩家以及紀錄？", [
        {
          text: "確定",
          onPress: () => {
            clearBattleRoomIdRef.current = item.id;
            clearBattleRoomFn({ variables: { id: Number(item.id) } });
          },
          type: "ok",
        },
        { text: "取消", type: "cancel" },
      ]);
    },
    [alert, clearBattleRoomFn]
  );

  const [deleteBattleRoomFn, { loading: deleteBattleRoomLoading }] =
    useMutation(DELETE_BATTLE_ROOM, {
      onCompleted({ deleteBattleRoom: { success, message } }) {
        if (success) {
          refetch();
        } else if (message) {
          notice(message);
        }
      },
      onError(error) {
        notice(error.message.replace("GraphQL error: ", ""));
      },
    });
  const _deleteBattleRoom = useCallback(
    (item) => {
      alert("", `確定要刪除房間「${item.name}」？`, [
        {
          text: "確定",
          onPress: () => {
            deleteBattleRoomIdRef.current = item.id;
            deleteBattleRoomFn({ variables: { id: Number(item.id) } });
          },
          type: "ok",
        },
        { text: "取消", type: "cancel" },
      ]);
    },
    [alert, deleteBattleRoomFn]
  );

  if (loading) {
    return (
      <Grid container>
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <Card>
        <QRcodeDialog ref={qrCodeDialogRef} />
        <CardContent>
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            sx={{ gap: 8 }}
          >
            <Typography>房間數量：{battleRooms.count}</Typography>
            <Box display="flex" flexDirection="row" sx={{ gap: 8 }}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onPress={() =>
                    cacheRef.current.swtichContentDispatch?.({
                      type: "add",
                    })
                  }
                >
                  新增對戰
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onPress={() =>
                    cacheRef.current.swtichContentDispatch?.({
                      type: "material",
                    })
                  }
                >
                  素材管理
                </Button>
              </div>
            </Box>
          </Box>
        </CardContent>
        <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2}>
              <Select
                label={"排序"}
                items={[
                  { label: "從新到舊", value: true },
                  { label: "從舊到新", value: false },
                ]}
                value={latest}
                onChange={(value) => setLatest(value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Select
                label={"每頁顯示"}
                items={[
                  { label: "10", value: 10 },
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                ]}
                value={pageSize}
                onChange={(value) => setPageSize(value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <CardContent>
          <Table
            data={battleRooms.contents}
            header={[
              "名稱",
              "玩家A",
              "玩家B",
              "觀戰者",
              "建立時間",
              "最後更新時間",
              "操作",
            ]}
            tableWidth={2}
            columns={[
              "name",
              (item) => {
                const href = `${window.location.protocol}//${window.location.host}/competitions/${item.id}?role=A&password=${item.password}`;
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ gap: "16px" }}
                  >
                    <Link
                      underline="hover"
                      target="_blank"
                      rel="noopener"
                      href={href}
                      style={{
                        color: "#0000ee",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Typography>玩家A</Typography>
                    </Link>
                    <Box position="relative" height="40px" width="40px">
                      <Box
                        position="absolute"
                        height="100%"
                        width="100%"
                        top={0}
                        left={0}
                      >
                        <ButtonBase
                          className={classes.qrcodeStyle}
                          onClick={() => {
                            qrCodeDialogRef.current?.open(href);
                          }}
                        />
                      </Box>
                      <Qrcode size={32} />
                    </Box>
                  </Box>
                );
              },
              (item) => {
                const href = `${window.location.protocol}//${window.location.host}/competitions/${item.id}?role=B&password=${item.password}`;
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ gap: "16px" }}
                  >
                    <Link
                      underline="hover"
                      target="_blank"
                      rel="noopener"
                      href={href}
                      style={{
                        color: "#0000ee",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Typography>玩家B</Typography>
                    </Link>
                    <Box position="relative" height="40px" width="40px">
                      <Box
                        position="absolute"
                        height="100%"
                        width="100%"
                        top={0}
                        left={0}
                      >
                        <ButtonBase
                          className={classes.qrcodeStyle}
                          onClick={() => {
                            qrCodeDialogRef.current?.open(href);
                          }}
                        />
                      </Box>
                      <Qrcode size={32} />
                    </Box>
                  </Box>
                );
              },
              (item) => {
                const href = `${window.location.protocol}//${window.location.host}/competitions/${item.id}?role=SPECTATOR&password=${item.password}`;
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ gap: "16px" }}
                  >
                    <Link
                      underline="hover"
                      target="_blank"
                      rel="noopener"
                      href={href}
                      style={{
                        color: "#0000ee",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Typography>觀戰者</Typography>
                    </Link>
                    <Box position="relative" height="40px" width="40px">
                      <Box
                        position="absolute"
                        height="100%"
                        width="100%"
                        top={0}
                        left={0}
                      >
                        <ButtonBase
                          className={classes.qrcodeStyle}
                          onClick={() => {
                            qrCodeDialogRef.current?.open(href);
                          }}
                        />
                      </Box>
                      <Qrcode size={32} />
                    </Box>
                  </Box>
                );
              },
              (item) => moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
              (item) => moment(item.updatedAt).format("YYYY/MM/DD HH:mm"),
              (item) => (
                <Box display="flex" style={{ gap: "8px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onPress={(event) => {
                      event.stopPropagation();
                      cacheRef.current.swtichContentDispatch?.({
                        type: "content",
                        data: {
                          id: item.id,
                        },
                      });
                    }}
                  >
                    編輯
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onPress={(event) => {
                      event.stopPropagation();
                      _clearBattleRoomFn(item);
                    }}
                    disabled={
                      clearBattleRoomIdRef.current === item.id &&
                      clearBattleRoomLoading
                    }
                  >
                    清空
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onPress={(event) => {
                      event.stopPropagation();
                      _deleteBattleRoom(item);
                    }}
                    disabled={
                      deleteBattleRoomIdRef.current === item.id &&
                      deleteBattleRoomLoading
                    }
                  >
                    刪除
                  </Button>
                </Box>
              ),
            ]}
          />
        </CardContent>
        <CardActions>
          <Grid container justifyContent="center">
            <Pagination
              count={battleRooms.pageCount}
              size="large"
              color="primary"
              page={page}
              onChange={(e, n) => setPage(n)}
              showFirstButton
              showLastButton
            />
          </Grid>
        </CardActions>
      </Card>
    );
  }
}
