import React, {
  useState,
  useEffect,
  useRef,
  memo,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
// material-ui
import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  makeStyles,
  InputLabel,
  Chip,
  FormHelperText,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { useForm, Controller, FormProvider, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
// apollo
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment";

// component
import Table from "../component/Table";
import Text from "../component/Text";
import { CardContent } from "../component/Card";
import { useAlert } from "../component/Alert";
import PageItem from "../component/PageItem";
import Button from "../component/Button";
import LoadingModal from "../component/LoadingModal";
import { CustomTextField, Select, TextInput } from "../component/Form";
import { excelDownload } from "../component/ExcelDownload";
import { useLocalStorage } from "../component/LocalStorageContext";
import { AutocompleteFocusSelect } from "../component/Form";
import LoadingFloating from "../component/LoadingFloating";
import MultipleAutoComplate from "../component/MultipleAutoComplate";
import { thousandsSeparator } from "../component/utils";
// utils
import emptyArray from "../utils/emptyArray";
// json
import countryCodes from "../countryCodes.json";
import divisions from "../divisions.json";

// SECTION apollo
// NOTE 抓取會員列
const GET_MEMBERS = gql`
  query GetMembers(
    $id: Int
    $startTime: Date
    $endTime: Date
    $productIds: [Int]
    $searchTerm: String
    $countryCallingCode: String
    $tier: MemberTier
    $hidden: Boolean
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    members(
      id: $id
      startTime: $startTime
      endTime: $endTime
      productIds: $productIds
      searchTerm: $searchTerm
      countryCallingCode: $countryCallingCode
      tier: $tier
      hidden: $hidden
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      pageCount
      memberCount
      members {
        id
        countryCallingCode
        fullName
        mobile
        email
        address {
          district
          subdistrict
          address
        }
        tier
        totalOrderAmount
        allowComment
        hidden
        hiddenEndTime
        # "隱藏原因"
        hiddenReason
        createdAt
      }
    }
  }
`;
// NOTE 商品列表
const GET_PRODUCTS_ALL = gql`
  query products {
    products {
      products {
        id
        value: id
        label: name
      }
    }
  }
`;
// NOTE 加入黑名單
const HIDE_MENBER = gql`
  mutation hideMember($id: Int!, $hiddenEndTime: Date, $reason: String!) {
    hideMember(id: $id, hiddenEndTime: $hiddenEndTime, reason: $reason) {
      success
      message
    }
  }
`;
// NOTE 解除隱藏會員
const UNLOCK_HIDDENMEMBER = gql`
  mutation unlockHiddenMember($id: Int!) {
    unlockHiddenMember(id: $id) {
      success
      message
    }
  }
`;
// NOTE 批次寄簡訊
const BATCH_SENDSMS = gql`
  mutation batchSendSms($memberIds: [Int!]!, $message: String!) {
    batchSendSms(memberIds: $memberIds, message: $message)
  }
`;
// NOTE 允許會員留言
const ALLOW_MEMBERCOMMENT = gql`
  mutation allowMemberComment($id: Int!, $allowed: Boolean) {
    allowMemberComment(id: $id, allowed: $allowed) {
      success
      message
    }
  }
`;
// !SECTION

// ANCHOR 主要組件
export default function MemberListPage() {
  const Alert = useAlert();
  const history = useHistory();
  const LocalStorage = useLocalStorage();
  const [memberId, setMemberId] = useState(
    Boolean(LocalStorage.getSearchMemberId())
      ? LocalStorage.getSearchMemberId()
      : ""
  );
  const [startTime, setStartTime] = useState(
    Boolean(LocalStorage.getSearchMemberStartTime())
      ? JSON.parse(LocalStorage.getSearchMemberStartTime())
      : null
  );
  const [endTime, setEndTime] = useState(
    Boolean(LocalStorage.getSearchMemberEndTime())
      ? JSON.parse(LocalStorage.getSearchMemberEndTime())
      : null
  );
  const [searchTerm, setSearchTerm] = useState(
    Boolean(LocalStorage.getSearchMemberSearchTerm())
      ? LocalStorage.getSearchMemberSearchTerm()
      : ""
  );
  const [countryCallingCode, setCountryCallingCode] = useState(
    Boolean(LocalStorage.getSearchMemberCountryCallingCode())
      ? LocalStorage.getSearchMemberCountryCallingCode()
      : "ALL"
  );
  const [tier, setTier] = useState(
    Boolean(LocalStorage.getSearchMemberTier())
      ? LocalStorage.getSearchMemberTier()
      : "ALL"
  );
  const [hidden, setHidden] = useState(
    Boolean(LocalStorage.getSearchMemberHidden())
      ? LocalStorage.getSearchMemberHidden() === "ALL"
        ? "ALL"
        : JSON.parse(LocalStorage.getSearchMemberHidden())
      : "ALL"
  );
  const [latest, setLatest] = useState(
    Boolean(LocalStorage.getSearchMemberLatest())
      ? JSON.parse(LocalStorage.getSearchMemberLatest())
      : true
  );
  const [page, setPage] = useState(
    Boolean(LocalStorage.getSearchMemberPage())
      ? JSON.parse(LocalStorage.getSearchMemberPage())
      : 1
  );
  const [pageMax, setPageMax] = useState(null);
  const [pageSize, setPageSize] = useState(
    Boolean(LocalStorage.getSearchMemberPageSize())
      ? JSON.parse(LocalStorage.getSearchMemberPageSize())
      : 10
  );
  const [pageArray, setPageArray] = useState([1]);
  const totalMemberCount = useRef(0);
  const [excelLoading, setExcelLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [countryCodesArray, setCountryCodesArray] = useState([]);
  const putOnBlacklistRef = useRef();
  const form = useForm({
    defaultValues: {
      productIds: Boolean(LocalStorage.getSearchMemberProductIds())
        ? JSON.parse(LocalStorage.getSearchMemberProductIds())
        : [],
    },
  });
  const { control, setValue } = form;

  const { data: productsData, loading: productsLoading } = useQuery(
    GET_PRODUCTS_ALL,
    {
      fetchPolicy: "network-only",
      onError() {
        return null;
      },
    }
  );
  const products = useMemo(
    () => emptyArray(productsData?.products.products),
    [productsData]
  );

  useEffect(() => {
    const newCountryCodesArray = [
      {
        label: "全部",
        value: "ALL",
      },
    ];
    countryCodes.forEach((item) => {
      newCountryCodesArray.push({
        label: item.countryName,
        value: item.phoneCode,
      });
    });
    setCountryCodesArray(newCountryCodesArray);
  }, []);

  const productIds = useWatch({ control, name: "productIds" });

  const { data, refetch, loading } = useQuery(GET_MEMBERS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      id: memberId ? parseInt(memberId, 10) : null,
      startTime,
      endTime,
      productIds: productIds.map((item) => item.value),
      searchTerm,
      countryCallingCode:
        countryCallingCode === "ALL" ? undefined : countryCallingCode,
      tier: tier === "ALL" ? undefined : tier,
      hidden: hidden === "ALL" ? undefined : hidden,
      latest,
      pageSize,
      page,
    },
    onCompleted({ members }) {
      setTimeout(() => {
        // if (members?.memberCount === 0) {
        //   window.location.reload();
        // }
        setPageMax(members.pageCount || 1);
        totalMemberCount.current = members.memberCount || 0;
      }, 0);
    },
    onError() {
      return null;
    },
  });

  const [getMembers] = useLazyQuery(GET_MEMBERS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ members }) {
      setTimeout(() => {
        const excelData = [];
        const entozh = {
          fullName: "姓名",
          mobile: "手機號碼",
          email: "信箱",
          address: "地址",
          createdAt: "註冊時間",
        };
        members.members.forEach((item) => {
          const excelDivision = item.address;
          excelData.push({
            fullName: item.fullName,
            mobile: item.mobile,
            email: item.email,
            address: excelDivision
              ? divisions[excelDivision.district]["zhTW"] +
                divisions[excelDivision.district]["subdivisions"][
                  excelDivision.subdistrict
                ]["zhTW"] +
                excelDivision.address
              : "",
            createdAt: moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
          });
        });
        const excelFinish = excelDownload(
          entozh,
          excelData,
          `${moment().format("YYYY-MM-DD")} 路遙圓創會員清單`
        );
        if (excelFinish === "Finish") {
          setExcelLoading(false);
        }
      }, 0);
    },
    onError() {
      return null;
    },
  });
  function _goToMember(memberId) {
    return history.push(`/console/member/${memberId}`);
  }
  useEffect(() => {
    let newArray = [];
    for (let i = 1; i <= pageMax; i++) {
      newArray.push(i);
    }
    setPageArray(newArray);
  }, [pageMax]);
  useEffect(() => {
    if (data) {
      if (!data.members.members[0] && page > 1) {
        setPage((e) => e - 1);
      }
    }
  }, [data, page]);

  function _allowMemberComment(item) {
    Alert.alert(
      "",
      `確定要${item.allowComment ? "關閉" : "重啟"}「${
        item.fullName
      }」的留言權？`,
      [
        {
          text: "確定",
          onPress: () =>
            allowMemberComment({
              variables: {
                id: Number(item.id),
                allowed: item.allowComment ? false : true,
              },
            }),
          type: "ok",
        },
        { text: "取消", type: "cancel" },
      ]
    );
  }

  const [allowMemberComment, { loading: allowMemberCommentLoading }] =
    useMutation(ALLOW_MEMBERCOMMENT, {
      onCompleted({ allowMemberComment }) {
        if (allowMemberComment.success) {
          Alert.notice("更改成功");
        } else if (allowMemberComment.message) {
          Alert.notice(allowMemberComment.message);
        } else {
          Alert.notice("更改失敗，請重新嘗試！");
        }
      },
      onError() {
        return null;
      },
    });

  function getTier(value) {
    switch (value) {
      case "HORSE_POWER":
        return "小馬力";
      case "SUPER_HORSE_POWER":
        return "超級馬力";
      case "COLLECTOR_HORSE_POWER":
        return "藏家馬力";
      default:
        return "查無資料";
    }
  }

  const [unlockHiddenMember, { loading: unlockHiddenMemberLoading }] =
    useMutation(UNLOCK_HIDDENMEMBER, {
      onCompleted({ unlockHiddenMember }) {
        if (unlockHiddenMember.success) {
          refetch();
          Alert.notice("操作成功！");
        } else if (unlockHiddenMember.message) {
          Alert.notice(unlockHiddenMember.message);
        }
      },
    });

  if (loading || productsLoading) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else {
    return (
      <>
        <LoadingModal
          loading={
            unlockHiddenMemberLoading ||
            excelLoading ||
            allowMemberCommentLoading
          }
        />
        <SendNewsletter
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        />
        <PutOnBlacklist ref={putOnBlacklistRef} handleRefetch={refetch} />
        <CardContent>
          <Typography>會員人數：{data && data.members.memberCount}</Typography>
        </CardContent>
        <Box padding={1} width="100%" style={{ backgroundColor: "white" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2}>
              <TextInput
                label={"搜尋"}
                value={searchTerm}
                onChange={(value) => {
                  LocalStorage.clearSearchMemberPage();
                  setSearchTerm(() => {
                    LocalStorage.setSearchMemberSearchTerm(value);
                    setPage(1);
                    return value;
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput
                label={"條碼搜尋會員"}
                value={memberId}
                onChange={(value) => {
                  LocalStorage.clearSearchMemberPage();
                  setMemberId(() => {
                    LocalStorage.setSearchMemberId(value);
                    setPage(1);
                    return value;
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Select
                label={"國籍"}
                items={countryCodesArray}
                value={countryCallingCode}
                onChange={(value) => {
                  LocalStorage.clearSearchMemberPage();
                  setCountryCallingCode(() => {
                    LocalStorage.setSearchMemberCountryCallingCode(`${value}`);
                    setPage(1);
                    return value;
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Select
                label={"黑名單"}
                items={[
                  { label: "全部", value: "ALL" },
                  { label: "只顯示黑名單", value: true },
                  { label: "不顯示黑名單", value: false },
                ]}
                value={hidden}
                onChange={(value) => {
                  LocalStorage.clearSearchMemberPage();
                  setHidden(() => {
                    LocalStorage.setSearchMemberHidden(`${value}`);
                    setPage(1);
                    return value;
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Select
                label={"會員等級"}
                items={[
                  { label: "全部", value: "ALL" },
                  { label: "小馬力", value: "HORSE_POWER" },
                  { label: "超級馬力", value: "SUPER_HORSE_POWER" },
                  { label: "藏家馬力", value: "COLLECTOR_HORSE_POWER" },
                ]}
                value={tier}
                onChange={(value) => {
                  LocalStorage.clearSearchMemberPage();
                  setTier(() => {
                    LocalStorage.setSearchMemberTier(`${value}`);
                    setPage(1);
                    return value;
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Select
                label={"排序"}
                items={[
                  { label: "從新到舊", value: true },
                  { label: "從舊到新", value: false },
                ]}
                value={latest}
                onChange={(value) => {
                  LocalStorage.clearSearchMemberPage();
                  setLatest(() => {
                    LocalStorage.setSearchMemberLatest(`${value}`);
                    setPage(1);
                    return value;
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Select
                label={"每頁顯示"}
                items={[
                  { label: "10", value: 10 },
                  { label: "50", value: 50 },
                  { label: "100", value: 100 },
                ]}
                value={pageSize}
                onChange={(value) => {
                  LocalStorage.clearSearchMemberPage();
                  setPageSize(() => {
                    LocalStorage.setSearchMemberPageSize(`${value}`);
                    setPage(1);
                    return value;
                  });
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <div style={{ width: "100%" }}>
                <FormProvider {...form}>
                  <MultipleAutoComplate
                    name="productIds"
                    objectName="商品"
                    options={products}
                    selectedOnChange={(_, value) => {
                      LocalStorage.clearSearchMemberPage();
                      LocalStorage.setSearchMemberProductIds(
                        JSON.stringify(value)
                      );
                      setValue("productIds", value);
                      setPage(1);
                    }}
                    deletedOnChange={(_, value) => {
                      LocalStorage.clearSearchMemberPage();
                      LocalStorage.setSearchMemberProductIds(
                        JSON.stringify(value)
                      );
                      setValue("productIds", value);
                      setPage(1);
                    }}
                    render={({ selected, search }) => (
                      <Grid item container direction="row" spacing={2}>
                        <Grid item xs={12} sm={6}>
                          {selected}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {search}
                        </Grid>
                      </Grid>
                    )}
                  />
                </FormProvider>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div style={{ display: "flex" }}>
                <DateTimePicker
                  ampm={false}
                  label={"開始時間"}
                  inputVariant="outlined"
                  format="yyyy年MM月dd日 a hh點mm分"
                  value={startTime}
                  onChange={(v) => {
                    LocalStorage.clearSearchMemberPage();
                    LocalStorage.setSearchMemberStartTime(JSON.stringify(v));
                    setStartTime(v);
                    LocalStorage.clearSearchMemberEndTime();
                    setEndTime(null);
                    setPage(1);
                  }}
                  fullWidth
                />
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onPress={() => {
                      LocalStorage.clearSearchMemberPage();
                      LocalStorage.clearSearchMemberStartTime();
                      setStartTime(null);
                      LocalStorage.clearSearchMemberEndTime();
                      setEndTime(null);
                      setPage(1);
                    }}
                    fullWidth
                  >
                    清除
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div style={{ display: "flex" }}>
                <DateTimePicker
                  ampm={false}
                  label={"結束時間"}
                  inputVariant="outlined"
                  format="yyyy年MM月dd日 a hh點mm分"
                  minDate={startTime}
                  value={endTime}
                  onChange={(v) => {
                    LocalStorage.clearSearchMemberPage();
                    LocalStorage.setSearchMemberEndTime(JSON.stringify(v));
                    setEndTime(v);
                    setPage(1);
                  }}
                  fullWidth
                />
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onPress={() => {
                      LocalStorage.clearSearchMemberPage();
                      LocalStorage.clearSearchMemberEndTime();
                      setEndTime(null);
                      setPage(1);
                    }}
                    fullWidth
                  >
                    清除
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                color="primary"
                onPress={() => setOpen(true)}
                fullWidth
              >
                發送簡訊
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Table
          paper
          data={data && data.members.members}
          header={[
            "姓名",
            "允許發表留言",
            "國籍",
            "會員等級",
            "消費總金額",
            "手機號碼",
            "信箱",
            "地址",
            "註冊時間",
            "是否為黑名單\n黑名單解除時間\n原因",
            "操作",
          ]}
          tableWidth={3}
          cellMaxWidth="130px"
          columns={[
            "fullName",
            (item) => (item.allowComment ? "是" : "否"),
            (item) => {
              const hasCountryCallingCode = countryCodes.find(
                (item2) => item2.phoneCode === item.countryCallingCode
              );
              if (hasCountryCallingCode) {
                return hasCountryCallingCode.countryName;
              } else {
                return "暫無資料";
              }
            },
            (item) => getTier(item.tier),
            (item) => thousandsSeparator(item.totalOrderAmount),
            "mobile",
            "email",
            (item) => {
              const division = item.address;
              return division ? (
                <Text>
                  {divisions[division.district]["zhTW"] +
                    divisions[division.district]["subdivisions"][
                      division.subdistrict
                    ]["zhTW"] +
                    division.address}
                </Text>
              ) : null;
            },
            (item) => moment(item.createdAt).format("YYYY/MM/DD HH:mm"),
            (item) => (
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Text>{item.hidden ? "是" : "否"}</Text>
                </Grid>
                <Grid item>
                  <Text>
                    {item.hiddenEndTime
                      ? moment(item.hiddenEndTime).format("YYYY/MM/DD HH:mm")
                      : item.hidden
                      ? "無限期"
                      : ""}
                  </Text>
                </Grid>
                <Grid item>
                  <Text>{item.hiddenReason}</Text>
                </Grid>
              </Grid>
            ),
            (item) => (
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onPress={(event) => {
                      event.stopPropagation();
                      _allowMemberComment(item);
                    }}
                  >
                    {item.allowComment ? "關閉發表留言" : "重啟發表留言"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onPress={(event) => {
                      event.stopPropagation();
                      if (item.hidden) {
                        Alert.alert(
                          "",
                          `確定復原\u2002${item.fullName}\u2002的會員權限？`,
                          [
                            {
                              text: "確定",
                              onPress: () =>
                                unlockHiddenMember({
                                  variables: { id: item.id },
                                }),
                              type: "ok",
                            },
                            {
                              text: "取消",
                              type: "cancel",
                            },
                          ]
                        );
                      } else {
                        Alert.alert(
                          "",
                          `確定要將\u2002${item.fullName}\u2002列入黑名單？`,
                          [
                            {
                              text: "確定",
                              onPress: () => {
                                putOnBlacklistRef.current?.open?.(item.id);
                              },
                              type: "ok",
                            },
                            {
                              text: "取消",
                              type: "cancel",
                            },
                          ]
                        );
                      }
                    }}
                  >
                    {item.hidden ? "解除黑名單" : "列入黑名單"}
                  </Button>
                </Grid>
              </Grid>
            ),
          ]}
          onPress={(item) => _goToMember(item.id)}
          isDoubleClick
        />
        <PageItem
          data={pageArray}
          page={page}
          onChangePage={(e) =>
            setPage(() => {
              LocalStorage.setSearchMemberPage(`${e}`);
              return e;
            })
          }
        />
        <Box display="flex" justifyContent="center" margin="1em">
          <Button
            variant="contained"
            color="primary"
            disabled={totalMemberCount.current === 0}
            onPress={() => {
              setExcelLoading(true);
              getMembers({
                variables: {
                  searchTerm,
                  countryCallingCode:
                    countryCallingCode === "ALL"
                      ? undefined
                      : countryCallingCode,
                  tier: tier === "ALL" ? undefined : tier,
                  latest,
                  pageSize:
                    totalMemberCount.current === 0
                      ? 10
                      : totalMemberCount.current,
                  page: 1,
                },
              });
            }}
          >
            下載會員清單
          </Button>
        </Box>
      </>
    );
  }
}

function SendNewsletter({ open = false, onClose = () => {} }) {
  const Alert = useAlert();
  const useStyles = makeStyles({
    memberIdsBox: {
      display: "flex",
      flexDirection: "row",
      minHeight: 40,
      border: "1px solid #C0C0C0",
      borderRadius: "4px",
      alignItems: "center",
      flexWrap: "wrap",
      "& > div": {
        padding: 5,
      },
    },
    text: {
      flexGrow: 1,
    },
  });
  const classes = useStyles();

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      memberIds: [],
      message: "",
    },
  });

  const [openSelect, setOpenSelect] = useState(false);
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermObject, setSearchTermObject] = useState({});

  function _onClose() {
    reset({
      memberIds: [],
      message: "",
    });
    setOpenSelect(false);
    setMembers([]);
    setSearchTerm("");
    setSearchTermObject({});
    onClose();
  }

  function _batchSendSms(data) {
    if (data.memberIds.length > 30) {
      return Alert.notice("簡訊發送每次最多三十人！");
    }
    batchSendSms({
      variables: {
        memberIds: data.memberIds.map((item) => item.value),
        message: data.message,
      },
    });
  }

  const handleInputChange = (event, newInputValue) => {
    setSearchTerm(newInputValue);
    setSearchTermObject({ label: newInputValue, value: "null" });
  };

  const [getMembers, { loading: membersLoading }] = useLazyQuery(GET_MEMBERS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ members }) {
      if (members) {
        setTimeout(() => {
          const newMembers = [];
          for (let i = 0; i < members.members.length; i++) {
            newMembers.push({
              label: members.members[i].fullName,
              value: members.members[i].id,
            });
          }
          setOpenSelect(() => {
            setMembers(newMembers);
            return true;
          });
        }, 0);
      }
    },
  });

  const [batchSendSms, { loading: batchSendSmsLoading }] = useMutation(
    BATCH_SENDSMS,
    {
      onCompleted({ batchSendSms }) {
        if (batchSendSms) {
          return Alert.alert("", "發送成功！", [
            {
              text: "確定",
              onPress: _onClose,
              type: "ok",
            },
          ]);
        } else {
          return Alert.alert("", "發送失敗！", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
        }
      },
      onError() {
        return null;
      },
    }
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      disableScrollLock
      open={open}
      onClose={_onClose}
    >
      <LoadingFloating loading={batchSendSmsLoading} />
      <DialogTitle>發送簡訊</DialogTitle>
      <DialogContent
        style={{
          height: "360px",
          display: "flex",
          justifyContent: "center",
          overflowY: 0,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="message"
              rules={{
                required: "必填欄位",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="訊息"
                  multiline
                  rows={5}
                  error={error}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="memberIds"
              rules={{
                required: "必填選項",
              }}
              render={({
                field: { value: memberIds, onChange },
                fieldState: { error },
              }) => (
                <Grid container item direction="column" spacing={1}>
                  <Grid item>
                    <InputLabel style={error && { color: "red" }}>
                      發送名單
                    </InputLabel>
                  </Grid>
                  <Grid item>
                    <Box
                      item
                      className={classes.memberIdsBox}
                      style={error && { borderColor: "red" }}
                    >
                      {memberIds.map((item, index) => {
                        return (
                          <Grid item>
                            <Chip
                              label={item.label}
                              color="primary"
                              onDelete={() => {
                                const deleteMemberIds = memberIds.filter(
                                  (i, j) => j !== index
                                );
                                onChange(deleteMemberIds);
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Box>
                  </Grid>
                  <Grid item>
                    <FormHelperText style={error && { color: "red" }}>
                      {error && error.message}
                    </FormHelperText>
                  </Grid>
                  <Grid container item>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex">
                        <AutocompleteFocusSelect
                          open={openSelect}
                          label={"選擇會員"}
                          items={members}
                          onInputChange={handleInputChange}
                          loading={membersLoading}
                          value={
                            searchTermObject.value === "null"
                              ? searchTermObject
                              : null
                          }
                          onChange={(e, value) => {
                            if (value) {
                              const hasOption = memberIds.find(
                                (item) => item.value === value.value
                              );
                              if (Boolean(hasOption)) {
                                return null;
                              } else {
                                const newMemberIds = [...memberIds, value];
                                onChange(newMemberIds);
                              }
                            } else {
                              setSearchTerm(null);
                              setSearchTermObject({});
                              setOpenSelect(false);
                            }
                          }}
                          renderOption={(option) => {
                            const hasOption = memberIds.find(
                              (item) => item.value === option.value
                            );
                            return (
                              <React.Fragment>
                                <div className={classes.text}>
                                  {option.label}
                                </div>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={Boolean(hasOption)}
                                >
                                  {hasOption ? "已加入" : "未加入"}
                                </Button>
                              </React.Fragment>
                            );
                          }}
                          fullWidth
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onPress={() => {
                            if (openSelect) {
                              setSearchTerm(null);
                              setSearchTermObject({});
                              setOpenSelect(false);
                            } else {
                              getMembers({
                                variables: { searchTerm },
                              });
                            }
                          }}
                          disabled={!Boolean(searchTerm) || membersLoading}
                        >
                          {openSelect ? "關閉" : "搜尋"}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justify="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onPress={handleSubmit(_batchSendSms)}
            >
              確定
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onPress={_onClose}>
              取消
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

/** - 列入黑名單 */
const PutOnBlacklist = memo(
  forwardRef(function PutOnBlacklist({ handleRefetch }, ref) {
    const Alert = useAlert();
    const [open, setOpen] = useState(false);
    const memberIdRef = useRef();
    const { control, handleSubmit, reset } = useForm({
      defaultValues: {
        hiddenEndTime: null,
        reason: "",
      },
    });
    const _onClose = useCallback(() => {
      setOpen(false);
      setTimeout(() => {
        memberIdRef.current = null;
        reset();
      }, 300);
    }, [reset]);
    useImperativeHandle(
      ref,
      () => ({
        open: (memberId) => {
          memberIdRef.current = memberId;
          setOpen(true);
        },
        close: () => {
          _onClose();
        },
      }),
      [_onClose]
    );
    const [hideMemberFn, { loading: hideMemberLoading }] = useMutation(
      HIDE_MENBER,
      {
        onCompleted({ hideMember: { success, message } }) {
          if (success) {
            Alert.notice("操作成功！");
            handleRefetch?.();
            _onClose();
          } else if (message) {
            Alert.notice(message);
          }
        },
        onError() {
          memberIdRef.current = null;
          return null;
        },
      }
    );
    const _hideMember = useCallback(
      ({ hiddenEndTime, reason }) => {
        hideMemberFn({
          variables: {
            id: memberIdRef.current,
            hiddenEndTime,
            reason,
          },
        });
      },
      [hideMemberFn]
    );
    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        disableScrollLock
        open={open}
        onClose={_onClose}
      >
        <DialogTitle>黑名單設定</DialogTitle>
        <DialogContent
          style={{
            height: "160px",
            display: "flex",
            justifyContent: "center",
            overflowY: 0,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="hiddenEndTime"
                render={({ field }) => (
                  <>
                    <Typography>黑名單解除時間(非必填)</Typography>
                    <div style={{ display: "flex" }}>
                      <DateTimePicker
                        {...field}
                        ampm={false}
                        inputVariant="outlined"
                        format="yyyy年MM月dd日 a hh點mm分"
                        views={["year", "month", "date"]}
                        onChange={(v) => {
                          field.onChange(
                            moment(v).tz("Asia/Taipei").seconds(0).toDate()
                          );
                        }}
                        fullWidth
                      />
                      {field.value && (
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            onPress={() => field.onChange(null)}
                            fullWidth
                          >
                            清除
                          </Button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="reason"
                rules={{
                  required: "請輸入原因",
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <>
                      <Typography style={{ display: "flex" }}>
                        原因
                        <Typography color="error">*</Typography>
                      </Typography>
                      <CustomTextField
                        {...field}
                        error={Boolean(error)}
                        helperText={error?.message}
                        fullWidth
                      />
                    </>
                  );
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1} justify="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onPress={handleSubmit(_hideMember)}
                disabled={hideMemberLoading}
              >
                {hideMemberLoading ? "處理中..." : "確定"}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onPress={_onClose}>
                取消
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  })
);
