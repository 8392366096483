import React, { useCallback } from "react";
import { useAtomValue } from "jotai";
import { selectAtom } from "jotai/utils";
import { deepEqual } from "fast-equals";
// NOTE 工具
import { competitionsStatusAtom } from "../../../jotai/Competitions";
// NOTE 組件
import VideoPlayer from "../VideoPlayer";

/** - 遊戲準備中 */
export default function GamePreparation() {
  const { battleAssets } = useAtomValue(
    selectAtom(
      competitionsStatusAtom,
      useCallback(
        (state) => ({
          battleAssets: state.battleAssets,
        }),
        []
      ),
      deepEqual
    )
  );
  return (
    <>
      <VideoPlayer url={battleAssets[0]?.url} loop />
    </>
  );
}
