import create from "zustand";
import { persist } from "zustand/middleware";
// SECTION 依賴型別
/**
 * @template T
 * @typedef {import("./").createType<T>} createType - zustand基本型別
 */
/**
 * @template T
 * @template V
 * @typedef {import("./").ChangeStateCallback<T, V>} ChangeStateCallback - 更改狀態函式
 */
// !SECTION
/**
 * @typedef InitialStateType
 * @property {string} gamePassword - gamePassword
 */
/**
 * @type {InitialStateType}
 */
const initialState = {
  gamePassword: undefined,
};
/**
 * @typedef ChangeStateType
 * @property {() => void} writeGamePassword - 寫入gamePassword
 * @property {() => void} clearGamePassword - 清除gamePassword
 */
/**
 * @type {ChangeStateCallback<InitialStateType, ChangeStateType>}
 */
const changeState = (set) => ({
  writeGamePassword: (gamePassword) => {
    return new Promise((resolve, reject) => {
      if (typeof gamePassword === "string") {
        set({ gamePassword });
        resolve(true);
      } else {
        reject("failed");
      }
    });
  },
  clearGamePassword: () => {
    return new Promise((resolve) => {
      set({ gamePassword: undefined });
      resolve(true);
    });
  },
});

/**
 * @type {createType<InitialStateType & ChangeStateType>}
 */
const useGameStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      ...changeState(set, get),
    }),
    {
      name: "@game",
    }
  )
);
export default useGameStore;
