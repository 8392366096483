import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  InputAdornment,
  Button,
  makeStyles,
} from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useSetAtom } from "jotai";
// NOTE 工具
import { competitionsStatusAtom } from "../../../jotai/Competitions";
// NOTE 組件
import { useAlert } from "../../../component/Alert";
import { CustomNumberInput, FieldCache } from "../../../component/Form";
// NOTE zustand
import useCompetitionsStore from "../../../zustand/useCompetitionsStore";

// SECTION apollo
// NOTE 確認對戰房會員
/** - 確認對戰房會員 */
const CHECK_BATTLE_ROOM_MEMBER = gql`
  mutation checkBattleRoomMember($mobile: String!, $password: String!) {
    checkBattleRoomMember(mobile: $mobile, password: $password) {
      # "成功"
      success
      # "訊息"
      message
      # "會員"
      member {
        # ID
        id
        # "大頭貼"
        profilePicture {
          # "位置"
          location
        }
        # "姓名"
        fullName
        # "手機號碼"
        mobile
      }
    }
  }
`;
// NOTE 對戰房
/** - 對戰房 */
const GET_BATTLE_ROOM = gql`
  query battleRoom($id: Int!) {
    battleRoom(id: $id) {
      # "ID"
      id
      # "參賽者A"
      participantA {
        # ID
        id
      }
      # "參賽者B"
      participantB {
        # ID
        id
      }
    }
  }
`;
// NOTE 清除對戰房
/** - 清除對戰房 */
const CLEAR_BATTLE_ROOM = gql`
  mutation clearBattleRoom($id: Int!) {
    clearBattleRoom(id: $id) {
      # "成功"
      success
      # "訊息"
      message
    }
  }
`;
// NOTE 加入對戰房
/** - 加入對戰房 */
const JOIN_BATTLE_ROOM = gql`
  mutation joinBattleRoom($id: Int!, $mobile: String!, $role: BattleRole!) {
    joinBattleRoom(id: $id, mobile: $mobile, role: $role) {
      # "成功"
      success
      # "訊息"
      message
      # "會員"
      member {
        # ID
        id
        # "大頭貼"
        profilePicture {
          # "位置"
          location
        }
        # "姓名"
        fullName
        # "手機號碼"
        mobile
      }
    }
  }
`;
// !SECTION

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "50%",
  },
  bottomBlockLayout: {
    [theme.breakpoints.up("xs")]: {
      gap: "32px",
    },
    [theme.breakpoints.up("md")]: {
      gap: "24px",
    },
  },
  mobileLayout: {
    gap: "8px",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "32px",
      paddingRight: "32px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "48px",
      paddingRight: "48px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "64px",
      paddingRight: "64px",
    },
  },
  searchButton: {
    height: "48px",
    width: "80px",
    backgroundColor: theme.palette.third.main,
  },
  battleButtonLayout: {
    [theme.breakpoints.up("xs")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "32px",
      paddingRight: "32px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "48px",
      paddingRight: "48px",
    },
  },
  battleButton: {
    height: "72px",
    borderColor: "#000",
    paddingLeft: "48px",
    borderRadius: "8px",
  },
  battleButtonText: {
    fontWeight: "bold",
    [theme.breakpoints.up("xs")]: {
      letterSpacing: "16px",
    },
    [theme.breakpoints.up("sm")]: {
      letterSpacing: "24px",
    },
  },
}));

/** - 參與階段 */
export default function ParticipationPhase() {
  const classes = useStyles();
  const { notice } = useAlert();
  const password = useCompetitionsStore(
    useCallback((state) => state.password, [])
  );
  const { handleSubmit, getValues, setValue } = useFormContext();
  const setCompetitionsStatus = useSetAtom(competitionsStatusAtom);
  const [checked, setChecked] = useState(false);
  const cacheRef = useRef({
    notice: () => {},
  });
  useEffect(() => {
    cacheRef.current.notice = notice;
  }, [notice]);

  const [checkBattleRoomMemberFn, { loading: checkBattleRoomMemberLoading }] =
    useMutation(CHECK_BATTLE_ROOM_MEMBER, {
      onCompleted({ checkBattleRoomMember: { success, message, member } }) {
        if (success) {
          setChecked(true);
          const role = getValues("role");
          switch (role) {
            case "A":
              setValue("participantA", member);
              break;
            case "B":
              setValue("participantB", member);
              break;
            default:
              break;
          }
        } else if (message) {
          setChecked(false);
          cacheRef.current.notice(message);
        }
      },
      onError(error) {
        cacheRef.current.notice(error.message.replace("GraphQL error: ", ""));
        return null;
      },
    });
  const _checkBattleRoomMember = useCallback(
    ({ participantA, participantB, role }) => {
      let mobile = "";
      switch (role) {
        case "A":
          mobile = participantA?.mobile;
          break;
        case "B":
          mobile = participantB?.mobile;
          break;
        default:
          break;
      }
      checkBattleRoomMemberFn({ variables: { mobile, password } });
    },
    [password, checkBattleRoomMemberFn]
  );

  const [battleRoomFn, { loading: battleRoomLoading }] = useLazyQuery(
    GET_BATTLE_ROOM,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );

  const [clearBattleRoomFn, { loading: clearBattleRoomLoading }] =
    useMutation(CLEAR_BATTLE_ROOM);

  const [joinBattleRoomFn, { loading: joinBattleRoomLoading }] = useMutation(
    JOIN_BATTLE_ROOM,
    {
      onCompleted({ joinBattleRoom: { success, message, member } }) {
        if (success) {
          const role = getValues("role");
          switch (role) {
            case "A":
              setValue("participantA", member);
              break;
            case "B":
              setValue("participantB", member);
              break;
            default:
              break;
          }
          setCompetitionsStatus((v) => {
            v.step = "START_BATTLE";
          });
        } else if (message) {
          cacheRef.current.notice(message);
        }
      },
      onError(error) {
        cacheRef.current.notice(error.message.replace("GraphQL error: ", ""));
        return null;
      },
    }
  );
  const _joinBattleRoom = useCallback(
    async ({ roomId, participantA, participantB, role }) => {
      let mobile = "";
      switch (role) {
        case "A":
          mobile = participantA?.mobile;
          break;
        case "B":
          mobile = participantB?.mobile;
          break;
        default:
          break;
      }
      try {
        const data = await battleRoomFn({ variables: { id: Number(roomId) } });
        if (data?.data?.battleRoom?.id) {
          const battleRoom = data?.data?.battleRoom;
          try {
            switch (role) {
              case "A":
                if (battleRoom.participantA?.id) {
                  await clearBattleRoomFn({
                    variables: { id: Number(roomId) },
                  });
                }
                break;
              case "B":
                if (battleRoom.participantB?.id) {
                  await clearBattleRoomFn({
                    variables: { id: Number(roomId) },
                  });
                }
                break;
              default:
                break;
            }
          } catch (error) {}
        }
      } catch (error) {}
      joinBattleRoomFn({
        variables: { id: Number(roomId), mobile, role },
      });
    },
    [battleRoomFn, clearBattleRoomFn, joinBattleRoomFn]
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flex={2}
        alignItems="center"
        justifyContent="center"
        sx={{ gap: 32 }}
      >
        <FieldCache
          name={["role", "participantA", "participantB"]}
          render={(watchedQuery) => {
            const [role, participantA, participantB] = watchedQuery;
            const profilePicture =
              role === "A"
                ? participantA?.profilePicture
                : role === "B"
                ? participantB?.profilePicture
                : undefined;
            const fullName =
              role === "A"
                ? participantA?.fullName
                : role === "B"
                ? participantB?.fullName
                : undefined;
            return (
              <>
                <Box
                  height="200px"
                  sx={{ aspectRatio: 1, borderRadius: "50%" }}
                >
                  <img
                    src={
                      profilePicture
                        ? profilePicture?.location
                        : "/img/Competitions/default_profilePicture.png"
                    }
                    className={classes.image}
                    alt="profilePicture"
                  />
                </Box>
                {fullName ? (
                  <Typography variant="h4">{fullName}</Typography>
                ) : (
                  <Box height="42px" />
                )}
              </>
            );
          }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flex={1}
        className={classes.bottomBlockLayout}
      >
        <Box
          display="flex"
          flexDirection="column"
          className={classes.mobileLayout}
        >
          <Typography variant="h4">手機號碼</Typography>
          <FieldCache
            name="role"
            render={(role) => {
              const controlName =
                role === "A"
                  ? "participantA.mobile"
                  : role === "B"
                  ? "participantB.mobile"
                  : "";
              return (
                <Controller
                  name={controlName}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <CustomNumberInput
                      {...field}
                      error={Boolean(error)}
                      placeholder="請輸入您的手機號碼"
                      inputProps={{
                        inputMode: "numeric", // 讓手機跳出數字鍵盤
                        pattern: "[0-9]*", // 限制只能輸入數字
                      }}
                      InputProps={{
                        style: {
                          padding: "0px",
                          paddingRight: "2px",
                          height: "52px",
                          fontSize: "24px",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              className={classes.searchButton}
                              variant="contained"
                              color="secondary"
                              onClick={handleSubmit(_checkBattleRoomMember)}
                              disabled={
                                checkBattleRoomMemberLoading ||
                                battleRoomLoading ||
                                clearBattleRoomLoading ||
                                joinBattleRoomLoading
                              }
                            >
                              <Typography variant="h5">搜尋</Typography>
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                      decimalScale={0}
                      allowLeadingZeros
                      disabled={
                        checkBattleRoomMemberLoading ||
                        battleRoomLoading ||
                        clearBattleRoomLoading ||
                        joinBattleRoomLoading
                      }
                    />
                  )}
                />
              );
            }}
          />
        </Box>
        <Box className={classes.battleButtonLayout}>
          <Button
            className={classes.battleButton}
            size="large"
            variant="outlined"
            onClick={handleSubmit(_joinBattleRoom)}
            disabled={
              !checked ||
              checkBattleRoomMemberLoading ||
              battleRoomLoading ||
              clearBattleRoomLoading ||
              joinBattleRoomLoading
            }
            fullWidth
          >
            <Typography className={classes.battleButtonText} variant="h4">
              進入對戰
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
}
