/** - 對戰素材類型列舉 */
export const battleAssetTypeEnum = {
  OPENING_ANIMATION: "開場動畫",
  CARDBACK: "卡牌背面",
  CARDFRONT: "卡牌正面",
  BACKGROUND: "背景",
  RESULT: "結果",
};
/** - 對戰素材類型列表 */
export const battleAssetTypeList = [
  { label: "開場動畫", value: "OPENING_ANIMATION" },
  { label: "卡牌背面", value: "CARDBACK" },
  { label: "卡牌正面", value: "CARDFRONT" },
  { label: "背景", value: "BACKGROUND" },
  { label: "結果", value: "RESULT" },
];
