import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { Dialog, DialogContent } from "@material-ui/core";
// NOTE 組件
import Qrcode from "./Qrcode";

export default forwardRef(function QRcodeDialog(_, ref) {
  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState();

  const _onClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      setCurrentValue(undefined);
    }, 300);
  }, []);
  useImperativeHandle(
    ref,
    () => ({
      open: (value) => {
        setCurrentValue(value);
        setOpen(true);
      },
      close: () => {
        _onClose();
      },
    }),
    [_onClose]
  );

  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={_onClose}>
      <DialogContent style={{ display: "flex", justifyContent: "center" }}>
        <Qrcode size={196} value={currentValue} />
      </DialogContent>
    </Dialog>
  );
});
